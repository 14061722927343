var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpRequestUrl } from './HttpRequestUrl';
export var HttpMethod;
(function (HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["POST"] = "POST";
})(HttpMethod || (HttpMethod = {}));
var HttpRequest = /** @class */ (function () {
    function HttpRequest(baseUrl, request, opts) {
        if (opts === void 0) { opts = {}; }
        this.baseUrl = baseUrl;
        this.url = HttpRequestUrl.buildRequestUrl(baseUrl, request.path);
        this.http_method = request.http_method;
        this.path = request.path;
        this.headers = new Headers();
        this.meta = request.meta;
        this.opts = this.normalizeOptions(opts);
        this.createQueryParams(request);
        this.createFetchBody(request);
    }
    HttpRequest.prototype.normalizeOptions = function (opts) {
        return opts.timeout ? opts : __assign(__assign({}, opts), { timeout: HttpRequest.defaultTimeout });
    };
    HttpRequest.prototype.createQueryParams = function (request) {
        if (request.query) {
            this.appendQueryParams(request.query);
        }
    };
    HttpRequest.prototype.createFetchBody = function (request) {
        if (request.http_method === HttpMethod.POST) {
            var multiPartForm = null;
            var body = void 0;
            if (request.files) {
                multiPartForm = new FormData();
                for (var _i = 0, _a = Object.entries(request.files); _i < _a.length; _i++) {
                    var _b = _a[_i], key = _b[0], file = _b[1];
                    multiPartForm.append(key, file);
                }
                body = multiPartForm;
            }
            if (request.body) {
                if (multiPartForm) {
                    for (var _c = 0, _d = Object.entries(request.body); _c < _d.length; _c++) {
                        var _e = _d[_c], key = _e[0], value = _e[1];
                        multiPartForm.append(key, value);
                    }
                    body = multiPartForm;
                }
                else {
                    body = JSON.stringify(request.body);
                    this.appendHeaders({
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    });
                }
            }
            this.body = body;
        }
    };
    HttpRequest.prototype.appendQueryParams = function (params) {
        var _this = this;
        if (params) {
            Object.entries(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (value !== undefined && value !== null) {
                    _this.url.searchParams.append(key, value);
                }
            });
        }
    };
    HttpRequest.prototype.appendHeaders = function (moreHeaders) {
        this.headers = new Headers(this.headers);
        for (var _i = 0, _a = Object.entries(moreHeaders); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            this.headers.set(key, value);
        }
    };
    HttpRequest.prototype.appendMeta = function (moreMeta) {
        var _a;
        this.meta = __assign(__assign({}, ((_a = this.meta) !== null && _a !== void 0 ? _a : {})), moreMeta);
    };
    HttpRequest.prototype.setUrl = function (newUrl) {
        this.url = newUrl;
    };
    HttpRequest.prototype.setPath = function (path) {
        this.path = path;
    };
    HttpRequest.prototype.setRequestOptions = function (newOpts) {
        this.opts = newOpts;
    };
    HttpRequest.defaultTimeout = 30000; // 30 seconds
    return HttpRequest;
}());
export { HttpRequest };
