import { HttpRequestUrl, } from '@bolteu/http-client';
var HttpInterceptorVirtualPaths = /** @class */ (function () {
    function HttpInterceptorVirtualPaths() {
    }
    HttpInterceptorVirtualPaths.prototype.intercept = function (req, next) {
        if (!HttpInterceptorVirtualPaths.isVirtualPathsEnabled(req)) {
            return next(req);
        }
        var newUrl = new URL(req.url.toString());
        if (req.serviceId) {
            if (!req.path.startsWith("/" + req.serviceId)) {
                throw new Error('Path should start with service ID');
            }
            req.setPath(req.path.substr(req.serviceId.length + 1));
            newUrl.pathname = HttpRequestUrl.buildRequestUrl(req.baseUrl, req.path).pathname;
        }
        var baseUrl = new URL(req.baseUrl);
        if (baseUrl.host === 'node.local.internal' || baseUrl.host === 'local.boltsvc.net') {
            newUrl.host = 'local.boltsvc.net';
            req.setPath("__virtual/" + req.subdomain + "/" + HttpRequestUrl.getNormalizedPath(req.path));
            newUrl.pathname = HttpRequestUrl.buildRequestUrl(req.baseUrl, req.path).pathname;
        }
        else {
            newUrl.host = req.subdomain + "." + newUrl.host;
        }
        req.setUrl(newUrl);
        return next(req);
    };
    HttpInterceptorVirtualPaths.isVirtualPathsEnabled = function (req) {
        var _a, _b;
        return ((_b = (_a = req.meta) === null || _a === void 0 ? void 0 : _a.virtualPaths) === null || _b === void 0 ? void 0 : _b.enabled) && req.subdomain;
    };
    return HttpInterceptorVirtualPaths;
}());
export { HttpInterceptorVirtualPaths };
