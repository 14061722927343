import { AutosaveStatus, IAppState } from '../types';

export const getAutosaveTimeout = (state: IAppState): NodeJS.Timeout | null =>
  state.autosave.timeout;

export const getAutosaveFieldName = (state: IAppState): string | null =>
  state.autosave.fieldName;

export const getAutosaveFieldValue = (state: IAppState): any =>
  state.autosave.fieldValue;

export const getAutosaveStatus = (state: IAppState): AutosaveStatus =>
  state.autosave.status;

export const isAutosavePending = (state: IAppState): boolean =>
  state.autosave.status === AutosaveStatus.PENDING;
