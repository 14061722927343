import React from 'react';

export const BackArrow: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 11H6.4L10.7 6.7C11.1 6.3 11.1 5.7 10.7 5.3C10.3 4.9 9.7 4.9 9.3 5.3L3.3 11.3C2.9 11.7 2.9 12.3 3.3 12.7L9.3 18.7C9.5 18.9 9.7 19 10 19C10.3 19 10.5 18.9 10.7 18.7C11.1 18.3 11.1 17.7 10.7 17.3L6.4 13H20C20.6 13 21 12.6 21 12C21 11.4 20.5 11 20 11Z"
      fill="white"
    />
  </svg>
);
