import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadExperiment } from '../redux/experiment/experimentThunk';
import { chatLoaded } from '../redux/chat/chatActions';
import { getChatLoaded } from '../redux/chat/chatSelector';
import { getMixpanel } from '../redux/mixpanel/mixpanelSelectors';
import { getExperimentParameters } from '../redux/experiment/experimentSelectors';
import { Mixpanel } from '../common/mixpanel';
import { MixpanelChatEventName } from '../common/events';

const CHAT_EXPERIMENT_EVENT_ID = 'driver_registration_live_chat';

declare global {
  function liveChat(
    callback: string,
    token: string,
    onSuccess?: () => void
  ): void;
  // onSuccess is not really documented anywehre, but that was easy to guess :)
}

const setupChatHooks = (mixpanel: Mixpanel) => {
  const chatBotState = {
    chatOpen: false,
    chatStarted: false,
  };

  const chatBotButton = document.getElementById('ib-button-messaging');
  const [chatBotCloseMobileButton] = document.getElementsByClassName(
    'ib-close-mobile-button'
  );

  for (const button of [chatBotButton, chatBotCloseMobileButton]) {
    button?.addEventListener('click', () => {
      chatBotState.chatOpen = !chatBotState.chatOpen;
      if (chatBotState.chatOpen) {
        mixpanel.track(MixpanelChatEventName.LEAD_CHAT_OPENED);
      } else {
        mixpanel.track(MixpanelChatEventName.LEAD_CHAT_CLOSED);
      }
    });
  }

  window.addEventListener('message', (e) => {
    if (e.data.analyticsEvent === 'conversation_opened') {
      if (chatBotState.chatStarted) return;
      chatBotState.chatStarted = true;
      mixpanel.track(MixpanelChatEventName.LEAD_CHAT_STARTED);
    }
  });
};

const loadChat = (
  enabled: boolean,
  token: string | undefined,
  mixpanel: Mixpanel
) => {
  if (enabled && token) {
    global.liveChat('init', token, () => {
      setupChatHooks(mixpanel);
    });
  }
};

export const Chat = ({ hash, language }: ChatProps): React.ReactElement => {
  const dispatch = useDispatch();

  const isChatLoaded = useSelector(getChatLoaded);
  const mixpanel = useSelector(getMixpanel);

  const experimentParameters = useSelector(
    getExperimentParameters(CHAT_EXPERIMENT_EVENT_ID)
  );

  useEffect(() => {
    dispatch(loadExperiment(CHAT_EXPERIMENT_EVENT_ID, hash, language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isChatLoaded) return;
    if (!mixpanel) return;

    if (experimentParameters === undefined) return;
    if (experimentParameters.variant === undefined) return;
    if (experimentParameters.enabled === undefined) return;

    loadChat(
      experimentParameters.enabled,
      experimentParameters.token,
      mixpanel
    );
    dispatch(chatLoaded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel, experimentParameters]);

  return <></>;
};

interface ChatProps {
  hash: string;
  language: string | undefined;
}
