import authApi from '../AuthApi';
import { store } from '../../redux/store';
import {
  getAccessToken,
  getRefreshToken,
} from '../../redux/auth/authSelectors';
import { setAuthTokens } from '../../redux/auth/authActions';

export const PartnerDriverAuthStrategy = {
  getAuthToken(): string {
    return getAccessToken(store.getState()) ?? '';
  },
  getExtraConfig(): ExtraConfig {
    return {
      updateAuthToken: async () => {
        const refreshToken = getRefreshToken(store.getState()) ?? '';
        const data = await authApi.getAccessToken({
          refresh_token: refreshToken,
        });
        store.dispatch(setAuthTokens(refreshToken, data.access_token));
        return data.access_token;
      },
      accessTokenBlockingTimeoutBeforeExpiryInSeconds: 10,
      accessTokenRefreshTimeoutBeforeExpiryInSeconds: 60,
    };
  },
};

interface ExtraConfig {
  updateAuthToken: () => Promise<string>;
  accessTokenBlockingTimeoutBeforeExpiryInSeconds: number;
  accessTokenRefreshTimeoutBeforeExpiryInSeconds: number;
}
