import * as React from 'react';
import ErrorHint from '../common/ErrorHint';
import { DatePickerProps } from '../types/DatePickerProps';

export const DatePickerMobile: React.FC<DatePickerProps> = ({
  title,
  value,
  error,
  updateHandler,
  blurHandler,
}: DatePickerProps) => {
  const borderColorClassName = `${
    !error ? 'focus:border-green-500' : 'border-red-500'
  }`;

  return (
    <div className="w-full text-left rtl:text-right">
      <label
        htmlFor={title}
        className="text-black font-bold text-sm text-left rtl:text-right"
      >
        {title}
      </label>
      <div className="h-12 rounded border-none w-full my-2">
        <input
          id={title}
          className={`outline-none h-12 bg-gray-200 rounded focus:bg-white focus:border ${borderColorClassName} px-3 w-full`}
          type="date"
          defaultValue={value === null ? '' : value}
          onInput={(e) => updateHandler((e.target as HTMLInputElement).value)}
          onBlur={(e) => blurHandler(e)}
        />
        <ErrorHint error={error} />
      </div>
    </div>
  );
};
