import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { CaptchaChallengeParameters } from '../types';

export enum ActionType {
  SET_VERIFICATION_DATA = 'VERIFICATION/SET_VERIFICATION_DATA',
  SET_VERIFICATION_CHANNEL = 'VERIFICATION/SET_VERIFICATION_CHANNEL',
  SET_VERIFICATION_LOADING = 'VERIFICATION/SET_VERIFICATION_LOADING',
  SET_VERIFICATION_LOADED = 'VERIFICATION/SET_VERIFICATION_LOADED',
  SET_CONFIRMATION_LOADING = 'VERIFICATION/SET_CONFIRMATION_LOADING',
  SET_CONFIRMATION_LOADED = 'VERIFICATION/SET_CONFIRMATION_LOADED',
  REQUIRE_MANUAL_CONFLICT_RESOLUTION = 'VERIFICATION/REQUIRE_MANUAL_CONFLICT_RESOLUTION',
  SET_CAPTCHA_NEEDED = 'VERIFICATION/SET_CAPTCHA_NEEDED',
  SET_CAPTCHA_NOT_NEEDED = 'VERIFICATION/SET_CAPTCHA_NOT_NEEDED',
  SET_COUNTRY_IS_KNOWN = 'VERIFICATION/SET_COUNTRY_IS_KNOWN',
  SET_CREATE_ACCOUNT_RESULT = 'VERIFICATION/SET_CREATE_ACCOUNT_RESULT',
  SET_CURRENT_OTP_VALUE = 'VERIFICATION/SET_CURRENT_OTP_VALUE',
}

export interface SetVerificationData {
  type: ActionType.SET_VERIFICATION_DATA;
  verification_data: DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationStartVerificationResponse;
}

export interface SetVerificationChannel {
  type: ActionType.SET_VERIFICATION_CHANNEL;
  verification_code_channel: DriverRegistrationPortalNoAuthService.VerificationCodeChannel;
}

export interface SetVerificationLoading {
  type: ActionType.SET_VERIFICATION_LOADING;
}

export interface SetVerificationLoaded {
  type: ActionType.SET_VERIFICATION_LOADED;
}

export interface SetCountryIsKnown {
  type: ActionType.SET_COUNTRY_IS_KNOWN;
}

export interface SetConfirmationLoading {
  type: ActionType.SET_CONFIRMATION_LOADING;
}

export interface SetConfirmationLoaded {
  type: ActionType.SET_CONFIRMATION_LOADED;
}

export interface RequireManualConflictResolution {
  type: ActionType.REQUIRE_MANUAL_CONFLICT_RESOLUTION;
}

export interface SetCaptchaNeeded {
  type: ActionType.SET_CAPTCHA_NEEDED;
  captcha_parameters: CaptchaChallengeParameters;
}

export interface SetCaptchaNotNeeded {
  type: ActionType.SET_CAPTCHA_NOT_NEEDED;
}

export interface SetCreateAccountResult {
  type: ActionType.SET_CREATE_ACCOUNT_RESULT;
  value: DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationCreateAccountResponse | null;
}

export interface SetCurrentOtpValue {
  type: ActionType.SET_CURRENT_OTP_VALUE;
  value: string;
}

export const setVerificationData = (
  verification_data: DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationStartVerificationResponse
): SetVerificationData => ({
  type: ActionType.SET_VERIFICATION_DATA,
  verification_data,
});

export const setVerificationChannel = (
  verification_code_channel: DriverRegistrationPortalNoAuthService.VerificationCodeChannel
): SetVerificationChannel => ({
  type: ActionType.SET_VERIFICATION_CHANNEL,
  verification_code_channel,
});

export const setVerificationLoading = (): SetVerificationLoading => ({
  type: ActionType.SET_VERIFICATION_LOADING,
});

export const setVerificationLoaded = (): SetVerificationLoaded => ({
  type: ActionType.SET_VERIFICATION_LOADED,
});

export const setCountryIsKnown = (): SetCountryIsKnown => ({
  type: ActionType.SET_COUNTRY_IS_KNOWN,
});

export const setConfirmationLoading = (): SetConfirmationLoading => ({
  type: ActionType.SET_CONFIRMATION_LOADING,
});

export const setConfirmationLoaded = (): SetConfirmationLoaded => ({
  type: ActionType.SET_CONFIRMATION_LOADED,
});

export const requireManualConflictResolution =
  (): RequireManualConflictResolution => ({
    type: ActionType.REQUIRE_MANUAL_CONFLICT_RESOLUTION,
  });

export const setCaptchaNeeded = (
  captcha_parameters: CaptchaChallengeParameters
): SetCaptchaNeeded => ({
  type: ActionType.SET_CAPTCHA_NEEDED,
  captcha_parameters,
});

export const setCaptchaNotNeeded = (): SetCaptchaNotNeeded => ({
  type: ActionType.SET_CAPTCHA_NOT_NEEDED,
});

export const setCreateAccountResult = (
  value: DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationCreateAccountResponse | null
): SetCreateAccountResult => ({
  type: ActionType.SET_CREATE_ACCOUNT_RESULT,
  value,
});

export const setCurrentOtpValue = (value: string): SetCurrentOtpValue => ({
  type: ActionType.SET_CURRENT_OTP_VALUE,
  value,
});

export type VerificationAction =
  | ActionType
  | SetVerificationData
  | SetVerificationChannel
  | SetVerificationLoading
  | SetVerificationLoaded
  | SetConfirmationLoading
  | SetConfirmationLoaded
  | RequireManualConflictResolution
  | SetCaptchaNeeded
  | SetCaptchaNotNeeded
  | SetCountryIsKnown
  | SetCreateAccountResult;
