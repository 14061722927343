export enum SignupEventSource {
  DRIVER_PORTAL = 'Driver Portal',
}

export enum SignupEventCategory {
  DRIVER_SIGNUP_FRONTEND = 'Driver Signup Frontend',
}

export enum SignupEvents {
  DRIVER_SIGNED_UP = 'Driver Signed Up',
  DRIVER_COMPLETED_STEP_2 = 'Driver Completed Step 2',
  DRIVER_COMPLETED_STEP_3 = 'Driver Completed Step 3',
  DRIVER_COMPLETED_STEP_4 = 'Driver Completed Step 4',
  DRIVER_COMPLETED_STEP_5 = 'Driver Completed Step 5',
  DRIVER_FINISHED_SIGN_UP = 'Driver Finished Sign Up',
}

export const SIGNUP_EVENTS_MAP = {
  step_1: [],
  step_2: [SignupEvents.DRIVER_COMPLETED_STEP_2],
  step_3: [SignupEvents.DRIVER_COMPLETED_STEP_3],
  step_4: [SignupEvents.DRIVER_COMPLETED_STEP_4],
  step_5: [
    SignupEvents.DRIVER_COMPLETED_STEP_5,
    SignupEvents.DRIVER_FINISHED_SIGN_UP,
  ],
  step_6: [],
};

export const SIGNUP_EVENTS_INDEX = {
  step_1: 1,
  step_2: 2,
  step_3: 3,
  step_4: 4,
  step_5: 5,
  step_6: 6,
};

export enum UiPage {
  DRP_STEP_2 = 'DRP_STEP_2',
}

export enum UiEvent {
  PAGE_RENDERED = 'PAGE_RENDERED',
}

export enum MixpanelUiEvents {
  CONTINUE_IN_APP_DISPLAYED = 'ContinueInApp Displayed',
  CONTINUE_IN_APP_CLICKED = 'ContinueInApp Clicked',
  CONTINUE_IN_BROWSER_CLICKED = 'ContinueInBrowser Clicked',
  IN_APP_UPDATE_APP_DISPLAYED = 'InApp UpdateApp Displayed',
  LINK_CLICKED = 'Driver Visitor Link Clicked',
}

export enum MixpanelStepEventName {
  LEAD_STEP_2_OPENED = 'Lead Step 2 Opened',
  LEAD_STEP_3_OPENED = 'Lead Step 3 Opened',
  LEAD_STEP_4_OPENED = 'Lead Step 4 Opened',
  LEAD_STEP_5_OPENED = 'Lead Step 5 Opened',
}

export enum MixpanelChatEventName {
  LEAD_CHAT_OPENED = 'Lead Chat Opened',
  LEAD_CHAT_CLOSED = 'Lead Chat Closed',
  LEAD_CHAT_STARTED = 'Lead Chat Started',
}

export type MixpanelEventName =
  | MixpanelStepEventName
  | MixpanelChatEventName
  | MixpanelUiEvents;
export type MixpanelEventData = { [key: string]: string };

export const getEventNameByStepId = (step: string): MixpanelStepEventName => {
  const mapping: { [key: string]: MixpanelStepEventName } = {
    step_2: MixpanelStepEventName.LEAD_STEP_2_OPENED,
    step_3: MixpanelStepEventName.LEAD_STEP_3_OPENED,
    step_4: MixpanelStepEventName.LEAD_STEP_4_OPENED,
    step_5: MixpanelStepEventName.LEAD_STEP_5_OPENED,
  };
  return mapping[step];
};
