import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { getQueryParam } from '../../common/helpers';
import { setCountryIsKnown } from '../../redux/verification/verificationActions';
import { getTranslations } from '../../redux/form/formSelectors';

export const PickOtpChannel: React.FC = () => {
  let phone = getQueryParam('phone') ?? '';
  if (phone && phone.length > 0 && !phone.startsWith('+')) {
    phone = `+${phone}`;
  }

  const dispatch = useDispatch();

  const { token } = useParams<{ token: string }>();
  const commonTranslations = useSelector(getTranslations);

  useEffect(() => {
    dispatch(setCountryIsKnown());
  }, [dispatch]);

  const sendViaWhatsapp = () => {
    const queryString = window.location.search || '?';
    const url = `/driver/verification/${token}/${queryString}&channel=${DriverRegistrationPortalNoAuthService.VerificationCodeChannel.WHATSAPP}`;
    dispatch(push(url));
  };

  const sendViaSms = () => {
    const url = `/driver/verification/${token}/${window.location.search}`;
    dispatch(push(url));
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center px-6 gap-y-4 relative">
        <div className="flex flex-col items-center">
          <span className="font-semibold text-gray-900 text-2xl mb-6">
            {commonTranslations.verification.verify_phone_number}
          </span>
          <span className="text-gray-700 text-center">
            {commonTranslations.verification.verification_code_will_be_sent_to}
          </span>
          <span className="font-semibold text-gray-900">{phone}</span>
        </div>
        <div className="flex inset-0 justify-center items-center mb-8">
          <div className="relative">
            <div className="flex flex-col w-full">
              <button type="button" onClick={sendViaSms}>
                <div className="relative">
                  <span
                    id="send-sms-button"
                    className="w-full flex flex-row items-center py-4 gap-6 border-b m-0 mt-6 px-8 py-3 border-none rounded-full text-center font-bold text-white bg-purple-500 cursor-pointer"
                  >
                    <svg
                      className="object-contain"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#FFFFFF"
                    >
                      <path d="M5.5 12C5.5 11.1716 6.17157 10.5 7 10.5C7.82843 10.5 8.5 11.1716 8.5 12C8.5 12.8284 7.82843 13.5 7 13.5C6.17157 13.5 5.5 12.8284 5.5 12Z" />
                      <path d="M10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12Z" />
                      <path d="M17 10.5C16.1716 10.5 15.5 11.1716 15.5 12C15.5 12.8284 16.1716 13.5 17 13.5C17.8284 13.5 18.5 12.8284 18.5 12C18.5 11.1716 17.8284 10.5 17 10.5Z" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12C23 18.1 18.1 23 12 23C10 23 8.1 22.5 6.6 21.6L2.8 22.6H2.5C1.8 22.6 1.3 21.9 1.5 21.3L2.5 17.5C1.5 15.9 1 14 1 12ZM7.5 19.8C8.8 20.6 10.4 21 12 21C17 21 21 17 21 12C21 7 17 3 12 3C7 3 3 7 3 12C3 13.6 3.4 15.2 4.3 16.6L4.7 17.3L4.5 18.1L3.9 20.2L6 19.6L6.8 19.4L7.5 19.8Z"
                      />
                    </svg>
                    {commonTranslations.verification.get_code_via_sms}
                  </span>
                </div>
              </button>
            </div>
            <div className="flex flex-col w-full">
              <button type="button" onClick={sendViaWhatsapp}>
                <div className="relative">
                  <span
                    id="send-whatsapp-button"
                    className="w-full flex flex-row items-center py-4 gap-6 border-b m-0 mt-4 px-8 py-3 border-none rounded-full text-center font-bold text-white bg-purple-500 cursor-pointer"
                  >
                    <svg
                      className="object-contain"
                      fill="#FFFFFF"
                      width="24"
                      height="24"
                      viewBox="0 0 30.667 30.667"
                    >
                      <g>
                        <path
                          d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017
		c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382
		c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076
		c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427
		c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437
		c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536
		c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609
		c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611
		c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787
		c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739
		C23.307,19.268,23.307,18.533,23.214,18.38z"
                        />
                      </g>
                    </svg>
                    {commonTranslations.verification.get_code_via_whatsapp}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
