import React from 'react';
import { useDispatch } from 'react-redux';
import { performStatusPolling } from '../redux/form/formThunk';
import { getFirstCommonError } from '../redux/notification/notificationsSelectors';
import { useSelector } from '../redux/store';
import { hasPartnerLoginDeeplinkSupport } from './helpers';

export function useStatusPolling(): void {
  const dispatch = useDispatch();
  const [isPollingStopped, setIsPollingStopped] = React.useState(false);
  const [lastCheckTime, setLastCheckTime] = React.useState(0);
  const error = useSelector(getFirstCommonError);
  const minTimeBetweenRequestsMs = 3000;

  React.useEffect(() => {
    if (
      error === 'DRIVER_REGISTRATION_LOG_HAS_NO_LINKED_PARTNER' ||
      error === 'DRIVER_REGISTRATION_LOG_NOT_FOUND'
    ) {
      setIsPollingStopped(true);
    }
  }, [error]);

  React.useEffect(() => {
    function onVisibilityChange() {
      if (isPollingStopped) {
        return;
      }
      // Protect from self DDoS:
      if (lastCheckTime) {
        if (Date.now() - lastCheckTime < minTimeBetweenRequestsMs) {
          return;
        }
      }
      setLastCheckTime(Date.now());
      dispatch(performStatusPolling());
    }
    if (hasPartnerLoginDeeplinkSupport()) {
      document.addEventListener('visibilitychange', onVisibilityChange, false);
      window.addEventListener('focus', onVisibilityChange, false);
    }
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('focus', onVisibilityChange);
    };
  }, [dispatch, isPollingStopped, lastCheckTime]);
}
