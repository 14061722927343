import React from 'react';
import MarkdownWrapper from '../common/MarkdownWrapper';
import {
  getDeclineReasons,
  getTranslations,
} from '../../redux/form/formSelectors';
import { useSelector } from '../../redux/store';
import { LoginAndNewSignupLinks } from '../common/LoginAndNewSignupLinks';

export const DeclinedFinalScreen: React.FC = () => {
  const commonTranslations = useSelector(getTranslations);
  const declineReasons = useSelector(getDeclineReasons);
  const declineReasonsMarkdownList = declineReasons
    .map((item) => `- ${item}`)
    .join('\n');

  return (
    <div>
      <div className="pb-8 px-2">
        <div className="text-center p-2 mx-auto">
          <h1 className="text-2xl font-bold">
            <MarkdownWrapper>
              {commonTranslations.declined_screen.title}
            </MarkdownWrapper>
          </h1>
          <div className="mt-5">
            <MarkdownWrapper>{declineReasonsMarkdownList}</MarkdownWrapper>
          </div>
        </div>
        <LoginAndNewSignupLinks showLogin={false} boldFont={false} />
      </div>
    </div>
  );
};
