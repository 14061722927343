import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Label } from '../input/Label';
import { LinkButton } from '../input/LinkButton';
import { EmbeddedResource } from '../input/EmbeddedResource';

interface FinalScreenFieldProps {
  fieldDefinition: DriverRegistrationPortalNoAuthService.Field;
}

export const FinalScreenField: React.FC<FinalScreenFieldProps> = (
  props
): React.ReactElement | null => {
  switch (props.fieldDefinition.type) {
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType.TEXT:
      return <Label title={props.fieldDefinition.translations.label} />;
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .LINK_BUTTON:
      return props.fieldDefinition.translations.placeholder ? (
        <LinkButton
          placeholder={props.fieldDefinition.translations.placeholder}
          title={props.fieldDefinition.translations.label}
        />
      ) : null;
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .EMBEDDED_RESOURCE:
      return (
        <EmbeddedResource
          placeholder={props.fieldDefinition.translations.placeholder}
          title={props.fieldDefinition.translations.label}
        />
      );
    default:
      return null;
  }
};
