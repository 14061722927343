import {
  DriverRegistrationEventLogNoAuthService,
  HttpClientLibrary,
} from '@bolteu/bolt-server-api-event-log';
import { getServerHost } from '../common/environment';
import { PORTAL_VERSION } from '../env';

const documentsHttpClient =
  new HttpClientLibrary.HttpClientBoltServer<DriverRegistrationEventLogNoAuthService.HttpClientConstraints>(
    {
      baseUrl: getServerHost(),
    }
  );

documentsHttpClient.init({
  standardRequestStrategy: {
    getStandardRequest: () => {
      return {
        version: PORTAL_VERSION,
      };
    },
  },
});

const eventLogApiClient = new DriverRegistrationEventLogNoAuthService.ApiClient(
  {
    httpClient: documentsHttpClient,
  }
);

export default eventLogApiClient;
