import { getDomainForCookies } from './environment';

// copy-paste from Homepage project
export interface CookieOptions {
  expires?: Date | string;
  'max-age': number | null;
  domain?: string;
  path?: string;
}

export const getCookie = (name: string): string | undefined => {
  /* eslint-disable */
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  /* eslint-enable */

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions
): void => {
  const formattedOptions = {
    path: '/',
    domain: getDomainForCookies(),
    ...options,
  };

  if (formattedOptions.expires instanceof Date) {
    formattedOptions.expires = formattedOptions.expires.toUTCString();
  }

  // ensure that the string does not contain any commas, semicolons, or whitespace (which are disallowed in cookie values)
  let updatedCookie = `${name}=${encodeURIComponent(value)}`;

  updatedCookie = `${updatedCookie};secure`;

  for (const optionKey in formattedOptions) {
    if (
      Object.prototype.hasOwnProperty.call(formattedOptions, optionKey) &&
      formattedOptions[optionKey as keyof CookieOptions] !== null
    ) {
      updatedCookie = `${updatedCookie};${optionKey}`;

      const optionValue = formattedOptions[optionKey as keyof CookieOptions];
      updatedCookie = `${updatedCookie}=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string): void => {
  setCookie(name, '', {
    expires: 'Thu, 01 Jan 1970 00:00:01 GMT',
    'max-age': null,
  });
};
