import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsFormUploading,
  getRiderUserSignupData,
} from '../../redux/rider_user_signup/riderUserSignupSelectors';
import { startRegistration } from '../../redux/rider_user_signup/riderUserThunk';
import { Button } from '../Button';

export const SignupButton: React.FC = () => {
  const dispatch = useDispatch();
  const { registerButtonLabel } = useSelector(getRiderUserSignupData);
  const uploading = useSelector(getIsFormUploading);

  return (
    <Button
      onClick={() => dispatch(startRegistration())}
      color={uploading ? 'bg-purple-200' : 'bg-purple-500'}
      loadingTextColor="text-purple-500"
      label={uploading ? '' : registerButtonLabel}
      disabled={uploading}
      loading={uploading}
      id="sign_up_button"
    />
  );
};
