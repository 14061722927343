import React from 'react';
import checkMarkImage from '../../../assets/check-mark.svg';
import checkMarkImage2 from '../../../assets/check-mark-2.svg';

interface InfoCheckpointProps {
  title: string;
  text: string;
  textColor: string;
  version: 'green' | 'transparent';
}

export const InfoCheckpoint: React.FC<InfoCheckpointProps> = ({
  title,
  text,
  textColor,
  version,
}: InfoCheckpointProps) => {
  return (
    <div className="flex flex-row">
      <div className="inline-block mr-2 rtl:ml-2">
        {version === 'transparent' ? (
          <img
            width={24}
            height={24}
            className="object-contain max-w-none mt-1"
            src={checkMarkImage2}
            alt="check mark"
          />
        ) : (
          <img
            width={24}
            height={24}
            className="object-contain max-w-none mt-1"
            src={checkMarkImage}
            alt="check mark"
            color="#34D186"
          />
        )}
      </div>
      <div>
        <span className={`block font-bold text-md ${textColor}`}>{title}</span>
        <span className={`block text-sm ${textColor}`}>{text}</span>
      </div>
    </div>
  );
};
