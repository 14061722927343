import * as React from 'react';
import { BaseProps } from './BaseInput';
import MarkdownWrapper from '../common/MarkdownWrapper';

interface EmbeddedResourceProps extends BaseProps {
  placeholder: string;
}

export const EmbeddedResource: React.FC<EmbeddedResourceProps> = (props) => {
  return (
    <div className="w-full mb-6">
      <MarkdownWrapper>{props.title}</MarkdownWrapper>
      <iframe
        className="w-full aspect-video"
        title={props.title}
        src={props.placeholder}
        allowFullScreen
      />
    </div>
  );
};
