import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getHasPartnerAccount,
  getLoadingStatus,
} from '../redux/auth/authSelectors';
import { useSelector } from '../redux/store';
import { getDriverLoginLink } from '../common/helpers';
import { continueInBrowser } from '../redux/verification/verificationThunk';
import { LoadingStatus } from '../redux/types';
import { initSignupFlow } from '../redux/auth/authThunk';
import { readSupportCaseIdFromQueryParamAndSetCookie } from '../common/supportCaseIdHelpers';

export const RedirectIfNoPartnerDriverToken: React.FC = () => {
  const dispatch = useDispatch();

  const loadingStatus = useSelector(getLoadingStatus);
  const hasRefreshToken = useSelector(getHasPartnerAccount);

  useEffect(() => {
    readSupportCaseIdFromQueryParamAndSetCookie();
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (loadingStatus === LoadingStatus.LOADING) {
        dispatch(initSignupFlow());
        return;
      }

      if (!hasRefreshToken) {
        // The Lead has not been logged in in this context, or it was so long ago that the token
        // is no longer available due to cleared cache etc.
        // Redirect the Lead
        window.location.replace(getDriverLoginLink());
        return;
      }

      // We are presumably authenticated at this point.
      // Redirect the lead to regular registration flow.
      dispatch(continueInBrowser());
    };

    checkAuthentication().catch(() => {
      // Something went wrong somewhere, but we shouldn't leave the Lead in an unrecoverable state.
      // Let's send them to the login portal so they're not left on a blank screen at least.
      window.location.replace(getDriverLoginLink());
    });
  }, [dispatch, hasRefreshToken, loadingStatus]);

  return <div />;
};
