import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { setVerificationLoaded } from '../../../redux/verification/verificationActions';
import { ErrorBubble } from '../ErrorBubble';
import { getTranslations } from '../../../redux/form/formSelectors';

export type ReCaptchaV2Props = {
  siteKey: string;
  onCaptchaSubmit: (captchaToken?: string | null) => void;
  language: string;
};

let captchaV2Timeout: number | undefined;

export const ReCaptchaV2: React.FC<ReCaptchaV2Props> = ({
  siteKey,
  onCaptchaSubmit,
  language,
}: ReCaptchaV2Props) => {
  const dispatch = useDispatch();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isError, setError] = useState(false);

  const commonTranslations = useSelector(getTranslations);

  useEffect(() => {
    return () => clearTimeout(captchaV2Timeout);
  }, []);

  const handleReCaptchaSolved = useCallback(
    (captchaToken: string | null) => {
      // Timeout for the regular captcha checkmark to be rendered fully
      captchaV2Timeout = window.setTimeout(() => {
        onCaptchaSubmit(captchaToken);
      }, 1000);
    },
    [onCaptchaSubmit]
  );

  const onLoad = useCallback(() => {
    dispatch(setVerificationLoaded());
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center">
      <div className="font-semibold text-gray-900 text-2xl mb-2">
        {commonTranslations.captcha_security_check.title}
      </div>
      <div className="text-gray-700 text-center mb-6 w-80">
        {commonTranslations.captcha_security_check.text}
      </div>

      <div className="flex h-20">
        {!isError && (
          <ReCAPTCHA
            key={language}
            sitekey={siteKey}
            ref={captchaRef}
            onChange={handleReCaptchaSolved}
            onErrored={() => setError(true)}
            asyncScriptOnLoad={onLoad}
            type="image"
            hl={language}
          />
        )}
        {isError && (
          <ErrorBubble error={commonTranslations.common_unknown_error} />
        )}
      </div>
    </div>
  );
};
