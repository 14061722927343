import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import {
  SignupEventCategory,
  SignupEvents,
  SignupEventSource,
  SIGNUP_EVENTS_INDEX,
  SIGNUP_EVENTS_MAP,
} from './events';
import { AwinDiscountCode, getItem } from './LocalStorageService';

export type DataLayerPayload = Record<string, unknown>;

declare global {
  interface Window {
    dataLayer?: {
      push: (obj: DataLayerPayload) => void;
    };
  }
}

export const GoogleAnalytics = {
  onStepSubmit(
    step: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep,
    flowId: number,
    hash: string,
    messageId?: string
  ): void {
    const eventsForStep = SIGNUP_EVENTS_MAP[step];
    const stepIndex = SIGNUP_EVENTS_INDEX[step];
    const awinCode = getItem(AwinDiscountCode);
    eventsForStep.forEach((eventName) => {
      const data = {
        eventCategory: SignupEventCategory.DRIVER_SIGNUP_FRONTEND,
        eventName,
        driverRegistrationSource: SignupEventSource.DRIVER_PORTAL,
        driverRegistrationFlowId: flowId.toString(),
        userId: `lead-driver-${hash}`,
        awin: {
          referralCode: awinCode,
        },
        messageId,
      };
      GoogleAnalytics.triggerEvent('driver-registration-event', data);
    });

    GoogleAnalytics.triggerEvent(`driver-custom-event-step-${stepIndex}`);
  },

  onNewSignup(hash: string, referralCode: string, messageId?: string): void {
    const data = {
      eventCategory: SignupEventCategory.DRIVER_SIGNUP_FRONTEND,
      eventName: SignupEvents.DRIVER_SIGNED_UP,
      driverRegistrationSource: SignupEventSource.DRIVER_PORTAL,
      driverRegistrationFlowId: '',
      userId: `lead-driver-${hash}`,
      messageId,
      awin: {
        referralCode,
      },
    };
    GoogleAnalytics.triggerEvent('driver-registration-event', data);
    GoogleAnalytics.triggerEvent(`driver-custom-event-step-1`);
  },

  triggerEvent(
    eventTriggerName: string,
    eventProperties: DataLayerPayload = {}
  ): void {
    GoogleAnalytics.pushToDataLayer({
      event: eventTriggerName,
      ...eventProperties,
    });
  },

  identify(hash: string): void {
    if (hash) {
      const userId = `lead-driver-${hash}`;
      GoogleAnalytics.pushToDataLayer({ userId });
    }
  },

  pushToDataLayer(obj: DataLayerPayload): void {
    const dataLayerMock: DataLayerPayload[] = [];
    const dataLayer = window.dataLayer || dataLayerMock;
    dataLayer.push(obj);
  },
};
