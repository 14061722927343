export const DRIVER_REGISTRATION = 'DRIVER_REGISTRATION';

export enum PAGES {
  DP_LANDING = 'DP_LANDING',
  DP_STEP_2 = 'DP_STEP_2',
  DP_VERIFIED = 'DP_VERIFIED',
  DRP_VERIFICATION = 'DRP_VERIFICATION',
}

export enum EVENTS {
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  PAGE_RENDERED = 'PAGE_RENDERED',
  SHARE_PARTNER_TOKEN_DEEPLINK = 'SHARE_PARTNER_TOKEN_DEEPLINK',
  SHARE_PARTNER_TOKEN_DRP = 'SHARE_PARTNER_TOKEN_DRP',
  IN_APP_SIGNUP_BEFORE_TARGETING = 'IN_APP_SIGNUP_BEFORE_TARGETING',
  IN_APP_SIGNUP_TREATMENT = 'IN_APP_SIGNUP_TREATMENT',
  IN_APP_SIGNUP_CLASSIC = 'IN_APP_SIGNUP_CLASSIC',
}
