import { Action } from 'redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';

export enum ActionType {
  FORM_LOADED = 'FORM/LOADED',
  FORM_UPLOADED = 'FORM/UPLOADED',
  FORM_UPLOADING = 'FORM/UPLOADING',
  FORM_DATA_LOADED = 'DICTS/FORM_DATA_LOADED',
  CAR_MODELS_LOADED = 'DICTS/CAR_MODELS_LOADED',
  SET_STEP = 'FORM/SET_STEP',
  SET_FIELD_VALUE = 'FORM/SET_FIELD_VALUE',
  SET_HASH = 'FORM/SET_HASH',
  SUBMIT = 'FORM/SUBMIT',
  SET_REGISTRATION_STATUS = 'FORM/SET_REGISTRATION_STATUS',
  SET_DOCUMENT_STATUSES = 'FORM/SET_DOCUMENT_STATUSES',
  START_DOCUMENT_STATUSES_POLLING = 'FORM/START_DOCUMENT_STATUSES_POLLING',
  STOP_DOCUMENT_STATUSES_POLLING = 'FORM/STOP_DOCUMENT_STATUSES_POLLING',
  CAPTURE_MODE_LOADED = 'CAPTURE_MODE/LOADED',
}

export interface FormLoading {
  type: ActionType.FORM_UPLOADING;
}

export const formUploading = (): FormLoading => ({
  type: ActionType.FORM_UPLOADING,
});

export const formUploaded = (): Action => ({
  type: ActionType.FORM_UPLOADED,
});

export interface FormLoaded {
  type: ActionType.FORM_LOADED;
  form: DriverRegistrationPortalNoAuthService.GetFieldsResponse;
}

export const formLoaded = (
  form: DriverRegistrationPortalNoAuthService.GetFieldsResponse
): FormLoaded => ({
  type: ActionType.FORM_LOADED,
  form,
});

export interface SetFormStep extends Action {
  type: ActionType.SET_STEP;
  step: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
}

export interface SetHash extends Action {
  type: ActionType.SET_HASH;
  hash: string;
}

export const setFormStep = (
  step: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep
): SetFormStep => ({
  type: ActionType.SET_STEP,
  step,
});

export interface SetFormFieldValue extends Action {
  type: ActionType.SET_FIELD_VALUE;
  step: DriverRegistrationPortalNoAuthService.Step;
  field: DriverRegistrationPortalNoAuthService.Field;
  value: any;
}

export const setFormFieldValue = (
  step: DriverRegistrationPortalNoAuthService.Step,
  field: DriverRegistrationPortalNoAuthService.Field,
  value: any
): SetFormFieldValue => ({
  type: ActionType.SET_FIELD_VALUE,
  step,
  field,
  value,
});

export const setHash = (hash: string): SetHash => ({
  type: ActionType.SET_HASH,
  hash,
});

export interface SubmitForm {
  type: ActionType.SUBMIT;
}

export const submitForm = (): SubmitForm => ({
  type: ActionType.SUBMIT,
});

export interface SetRegistrationStatus extends Action {
  type: ActionType.SET_REGISTRATION_STATUS;
  status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus;
  decline_reasons?: string[];
}

export const setRegistrationStatus = (
  status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus,
  decline_reasons?: string[]
): SetRegistrationStatus => ({
  type: ActionType.SET_REGISTRATION_STATUS,
  status,
  decline_reasons,
});

export interface SetDocumentStatuses extends Action {
  type: ActionType.SET_DOCUMENT_STATUSES;
  documents: DriverRegistrationPortalNoAuthService.DocumentResultResponse[];
}

export const setDocumentStatuses = (
  documents: DriverRegistrationPortalNoAuthService.DocumentResultResponse[]
): SetDocumentStatuses => ({
  type: ActionType.SET_DOCUMENT_STATUSES,
  documents,
});

export interface StartDocumentStatusesPolling extends Action {
  type: ActionType.START_DOCUMENT_STATUSES_POLLING;
}

export interface StopDocumentStatusesPolling extends Action {
  type: ActionType.STOP_DOCUMENT_STATUSES_POLLING;
}

export const startDocumentStatusesPolling =
  (): StartDocumentStatusesPolling => ({
    type: ActionType.START_DOCUMENT_STATUSES_POLLING,
  });

export const stopDocumentStatusesPolling = (): StopDocumentStatusesPolling => ({
  type: ActionType.STOP_DOCUMENT_STATUSES_POLLING,
});

export interface SetCaptureModeLoaded {
  type: ActionType.CAPTURE_MODE_LOADED;
  captureMode: DriverRegistrationPortalNoAuthService.GetDocumentCaptureModeResponse;
}

export const setCaptureModeLoaded = (
  captureMode: DriverRegistrationPortalNoAuthService.GetDocumentCaptureModeResponse
): SetCaptureModeLoaded => ({
  type: ActionType.CAPTURE_MODE_LOADED,
  captureMode,
});

export type FormAction =
  | Action
  | FormLoading
  | FormLoaded
  | SetFormStep
  | SubmitForm
  | SetFormFieldValue
  | SetRegistrationStatus
  | SetDocumentStatuses
  | StartDocumentStatusesPolling
  | StopDocumentStatusesPolling;
