import 'core-js/es/object';
import 'core-js/es/array';
import 'core-js/actual/url';
import 'core-js/actual/url-search-params';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import '../build/index.css';

import { ConnectedRouter } from 'connected-react-router';
import { store as generalStore, history } from './redux/store';
import { App } from './App';

const AppWithStore: React.FC = () => (
  <Provider store={generalStore}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

render(<AppWithStore />, document.getElementById('app'));
