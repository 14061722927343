import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';

const DOCUMENT_KEYS_HIDDEN_FOR_ENHANCED_CAPTURE = ['gh_drivers_license_back'];

export const includeFieldWhenUsingEnhancedCapture = (
  documentTypeKey?: string
): boolean => {
  return !(
    documentTypeKey &&
    DOCUMENT_KEYS_HIDDEN_FOR_ENHANCED_CAPTURE.includes(documentTypeKey)
  );
};

export const adjustFieldForEnhancedCapture = (
  field: DriverRegistrationPortalNoAuthService.Field
): DriverRegistrationPortalNoAuthService.Field => {
  if (!field.document_type_key) {
    return field;
  }
  if (field.document_type_key === 'gh_drivers_license') {
    const newField = { ...field };
    newField.translations.label = "Driver's License";
    return newField;
  }
  return field;
};
