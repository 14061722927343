import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState, ThunkMiddlware } from '../types';
import { loadRegistrationFlow } from '../common/commonHelpers';
import {
  generateTokenAndRedirectToMarketplace,
  getAuthMagicToken,
  handleAuthentication,
} from './authHelpers';

export const initSignupFlow: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  await handleAuthentication(dispatch, getState);
  await loadRegistrationFlow(dispatch, getState);
};

export const redirectToMarketplace: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action>
> = () => async (_: Dispatch<any>, getState: () => IAppState) => {
  await generateTokenAndRedirectToMarketplace(getState);
};

export const initAppPromotion: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  await handleAuthentication(dispatch, getState);
  await getAuthMagicToken(dispatch);
};
