//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
//Generated by: TsAppClientApiPrinter
//Contact #dev-ui-platform-support in case of issues
import { GeneratedApiClient } from "../ApiClient";
import { GeneratedHttpClientConstraints, RequestOptionsConstraint, HttpMethod, AuthStrategy, ShardingStrategy } from "../HttpClient";
import { StandardRequest } from "../StandardRequest";
/* tslint:disable:no-namespace */
export namespace DriverRegistrationPortalNoAuthService {
    export interface HttpClientConstraints {
        Auth: AuthStrategy.NONE;
        Sharding: ShardingStrategy.NONE;
        StandardRequest: StandardRequest;
    }
    class ApiClientClass<THttpClientConstraints extends GeneratedHttpClientConstraints = DriverRegistrationPortalNoAuthService.HttpClientConstraints, TRequestOptions extends RequestOptionsConstraint = never> extends GeneratedApiClient<THttpClientConstraints, TRequestOptions> {
        /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
        public getFields = async (query: GetFieldsRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetFieldsResponse>({
                path: "/driverRegistration/getFields",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
        public getTaxIdentificationNumberFormats = async (opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<TaxIdentificationNumberFormatsResponse>({
                path: "/driverRegistration/getTaxIdentificationNumberFormats",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                }
            }, opts);
        };
        /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
        public getSupportedLanguages = async (query: GetSupportedLanguagesRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetSupportedLanguagesResponse>({
                path: "/driverRegistration/getSupportedLanguages",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
        public getCommonTranslations = async (query: GetCommonTranslationsRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetCommonTranslationsResponse>({
                path: "/driverRegistration/getCommonTranslations",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public getDriverRegistration = async (query: GetDriverRegistrationLogRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetDriverRegistrationResponse>({
                path: "/driverRegistration/getDriverRegistration",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public signup = async (query: SignupQuery, body: SignupRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<void>({
                path: "/driverRegistration/signup",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query,
                body: body
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public autosave = async (query: SignupQuery, body: AutosaveRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<void>({
                path: "/driverRegistration/autosave",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query,
                body: body
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public validateDriverRegistrationDocumentsForResubmission = async (query: SignupQuery, body: DriverRegistrationValidateDriverRegistrationDocumentsForResubmissionBody, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<void>({
                path: "/driverRegistration/validateDriverRegistrationDocumentsForResubmission",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query,
                body: body
            }, opts);
        };
        /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
        public startRegistration = async (body: DriverProvisionalRegistrationStartRegistrationRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<DriverProvisionalRegistrationStartRegistrationResponse>({
                path: "/driverRegistration/startRegistration",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                body: body
            }, opts);
        };
        /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
        public startVerification = async (body: DriverProvisionalRegistrationStartVerificationRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<DriverProvisionalRegistrationStartVerificationResponse>({
                path: "/driverRegistration/startVerification",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                body: body
            }, opts);
        };
        /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
        public confirmVerification = async (body: ConfirmVerificationRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<void>({
                path: "/driverRegistration/confirmVerification",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                body: body
            }, opts);
        };
        /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
        public createAccount = async (query: CreateAccountQuery, body: DriverProvisionalRegistrationCreateAccountRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<DriverProvisionalRegistrationCreateAccountResponse>({
                path: "/driverRegistration/createAccount",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query,
                body: body
            }, opts);
        };
        /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
        public getDocumentsResults = async (query: GetDocumentsResultsRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetDocumentsResultsResponse>({
                path: "/driverRegistration/getDocumentsResults",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
        public getDocumentCaptureMode = async (query: GetDocumentCaptureModeRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetDocumentCaptureModeResponse>({
                path: "/driverRegistration/getDocumentCaptureMode",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
        public getExperimentParameters = async (query: GetExperimentParametersRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<ExperimentParameters>({
                path: "/driverRegistration/getExperimentParameters",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
        public getDriverVisitorExperiments = async (query: DriverVisitorExperimentParametersRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<DriverVisitorExperimentParametersResponse>({
                path: "/driverRegistration/getDriverVisitorExperiments",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
        public getExperimentParametersBeforeSignup = async (query: GetExperimentParametersBeforeSignupRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<ExperimentParameters>({
                path: "/driverRegistration/getExperimentParametersBeforeSignup",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public getDriverRegistrationStatus = async (query: GetDriverRegistrationStatusRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetDriverRegistrationStatusResponse>({
                path: "/driverRegistration/getDriverRegistrationStatus",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public getAllCountries = async (query: GetAllCountriesRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetAllCountriesResponse>({
                path: "/driverRegistration/getAllCountries",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
        public getFormDataForRiderUser = async (query: GetFormDataForRiderUserRequest, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<GetFormDataForRiderUserResponse>({
                path: "/driverRegistration/getFormDataForRiderUser",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.GET,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query
            }, opts);
        };
        /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
        public startRegistrationForRiderUser = async (query: StartRegistrationForRiderUserRequest, body: StartRegistrationForRiderUserBody, opts?: TRequestOptions) => {
            return this.httpClient.sendRequest<StartRegistrationForRiderUserResponse>({
                path: "/driverRegistration/startRegistrationForRiderUser",
                service_id: "driver-registration-portal",
                subdomain: "driverregistration",
                http_method: HttpMethod.POST,
                meta: {
                    auth: { enabled: false },
                    sharding: { enabled: false },
                    virtualPaths: { enabled: this.apiClientOptions?.virtualPathsEnabled ?? true }
                },
                query: query,
                body: body
            }, opts);
        };
    }
    // Split class actual prototype and type
    export const ApiClient = ApiClientClass;
    /**
     * When you use ApiClient class as a type it would include private / protected properties which leads
     * to a situation where 2 sources of the ApiClient are not equal.
     *
     * This situation can happen for example when a library depends on the whole ApiClient and consumer wants to pass
     * in its own ApiClient. That would lead to an error
     *
     * TS2345: Argument of type 'ApiClient<HttpClientConstraints, HttpRequestOpts>' is not assignable to parameter of type 'ApiClient<HttpClientConstraints, never>'.
     *  Property 'apiClientOptions' is protected but type 'GeneratedApiClient<THttpClientConstraints, TRequestOptions>' is not a class derived from 'GeneratedApiClient<THttpClientConstraints, TRequestOptions>'.
     */
    export type ApiClient<THttpClientConstraints extends GeneratedHttpClientConstraints = DriverRegistrationPortalNoAuthService.HttpClientConstraints, TRequestOptions extends RequestOptionsConstraint = never> = {
        [Key in keyof ApiClientClass<THttpClientConstraints, TRequestOptions>]: ApiClientClass<THttpClientConstraints, TRequestOptions>[Key];
    };
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/common/constants/Types.ts)} */
    export interface AppMarketingData {
        appsflyer_id?: string;
        advertising_id?: string;
        device_os_version?: string;
        app_version?: string;
        firebase_instance_id?: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface AppPromotion {
        your_account_created: string;
        continue_registration_in_app: string;
        get_driver_app: string;
        driver_app_is_outdated: string;
        update_driver_app: string;
        please_download_app_text: string;
        you_can_login: string;
        download_on_google_play: string;
        download_on_app_store: string;
        scan_qr_code: string;
        or_use_links: string;
        continue_in_browser: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export enum AppPromotionMode {
        NONE = "none",
        OPTIONAL = "optional",
        REQUIRED = "required"
    }
    /** Generated from: {@link [FeaturesService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FeaturesService.ts)} */
    export interface AutosaveParameters {
        enabled: boolean;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface AutosaveRequest {
        signup_data: DriverSignupData;
        field_key: string;
        generic_field_key?: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface AutosaveTranslations {
        saved: string;
        saving: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export type Banner = ColoredIconBanner | IconBanner;
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export enum BannerActionType {
        NONE = "none",
        REFRESH_PAGE = "refresh_page"
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface BannerItem {
        step: DriverRegistrationLogLastStep;
        list: Banner[];
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export enum BannerType {
        ICON_BANNER = "icon_banner",
        COLORED_ICON_BANNER = "colored_icon_banner"
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface Banners {
        items: BannerItem[];
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface BaseBanner {
        type: BannerType;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface BaseCondition {
        type: ConditionType;
    }
    /** Generated from: {@link [BrandHelper.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/util/brand/BrandHelper.ts)} */
    export enum BrandEnum {
        BOLT = "bolt",
        HOPP = "hopp"
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export interface CaptchaChallengeToken {
        type: CaptchaChallengeType;
        token: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export enum CaptchaChallengeType {
        RECAPTCHA_NATIVE = "recaptcha_native",
        WEBVIEW = "webview"
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface CaptchaSecurityCheckTranslations {
        title: string;
        text: string;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface CheckboxCondition extends BaseCondition {
        type: ConditionType.CHECKBOX;
        equals: boolean;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface ColoredIconBanner extends BaseBanner {
        type: BannerType.COLORED_ICON_BANNER;
        icon_url: string;
        background_color: string;
        title_html: string | null;
        description_html: string | null;
        action: BannerActionType;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface CommonActionTranslations {
        add_new_item: string;
        delete: string;
        save: string;
        refresh: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface CommonLabelsTranslation {
        login: string;
        start_new_registration: string;
        page_not_found: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface CommonTranslations {
        document_upload: DocumentUploadTranslations;
        document_resubmission: DocumentResubmissionTranslations;
        common_actions: CommonActionTranslations;
        common_labels: CommonLabelsTranslation;
        app_promotion: AppPromotion;
        verification: VerificationTranslations;
        tax_identification_numbers: TaxIdentificationNumberTranslations;
        final_screen: FinalScreenTranslations;
        declined_screen: DeclinedScreenTranslations;
        common_unknown_error: string;
        captcha_security_check: CaptchaSecurityCheckTranslations;
        autosave: AutosaveTranslations;
        vehicle_marketplace_screen: VehicleMarketplaceTranslations;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export enum ConditionType {
        NONE = "none",
        DEFAULT = "default",
        CHECKBOX = "checkbox",
        SELECT = "select",
        DOCUMENT_UPLOAD = "document_upload",
        NUMERIC = "numeric"
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface ConfirmVerificationRequest extends WebRequestParams {
        code: string;
        token: string;
        language: string;
        app_version?: string;
    }
    /** Generated from: {@link [RegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/RegistrationService.ts)} */
    export interface Country {
        value: string;
        title: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface CreateAccountQuery extends WebRequestParams {
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DeclinedScreenTranslations {
        title: string;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface DefaultCondition extends BaseCondition {
        type: ConditionType.DEFAULT;
        empty: boolean;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DocumentResubmissionTranslations {
        document_resubmission_title: string;
        document_resubmission_subtitle: string;
    }
    /** Generated from: {@link [DocumentCaptureTable.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/table/DocumentCaptureTable.ts)} */
    export interface DocumentResultCommon {
        document_type: string;
        status: DocumentResultStatus;
    }
    /** Generated from: {@link [DocumentCaptureService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DocumentCaptureService.ts)} */
    export interface DocumentResultResponse extends DocumentResultCommon {
        error?: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/common/constants/Types.ts)} */
    export enum DocumentResultStatus {
        NOT_UPLOADED = "not_uploaded",
        IN_REVIEW = "in_review",
        CONFIRMED = "confirmed",
        DECLINED = "declined",
        MANUALLY_UPLOADED = "manually_uploaded"
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface DocumentUploadCondition extends BaseCondition {
        type: ConditionType.DOCUMENT_UPLOAD;
        upload_status: UploadStatus;
        document_type_key: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DocumentUploadPreviewTranslations {
        choose_photo_button_label: string;
        next_button_label: string;
        readability_title: string;
        readability_description: string;
        point_1: {
            title: string;
            description: string;
        };
        point_2: {
            title: string;
            description: string;
        };
        point_3: {
            title: string;
            description: string;
        };
        point_4: {
            title: string;
            description: string;
        };
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DocumentUploadStatusLabels {
        declined: string;
        uploaded: string;
        in_review: string;
        optional: string;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DocumentUploadTranslations {
        upload_button_label: string;
        upload_document_button_label: string;
        upload_failed_try_again: string;
        required_label: string;
        expire_label: string;
        check_upload_modal: string;
        back_button_label: string;
        next_button_label: string;
        preview: DocumentUploadPreviewTranslations;
        verification: DocumentUploadVerificationTranslations;
        document_upload_example: string;
        your_document: string;
        document_upload_uploaded_label: string;
        document_upload_requirements: string;
        use_camera_button: string;
        select_file_button: string;
        unknown_error: string;
        status_label: DocumentUploadStatusLabels;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface DocumentUploadVerificationTranslations {
        verification_title: string;
        verification_description: string;
        confirm_button_label: string;
        security_message: string;
        document_details_title: string;
        document_details_description: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface DriverProvisionalRegistrationCreateAccountRequest {
        token: string;
        app_marketing_data?: AppMarketingData;
        web_marketing_data?: WebMarketingData;
        visitor_tracking_data?: VisitorTrackingData;
        app_version?: string;
        preview_flow_id?: number;
        resolve_conflict_consent?: boolean;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export interface DriverProvisionalRegistrationCreateAccountResponse {
        refresh_token?: string;
        registration_hash: string;
        is_new_registration_created: boolean;
        app_promotion?: AppPromotionMode;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface DriverProvisionalRegistrationStartRegistrationRequest {
        city_id: number;
        email: string;
        phone: string;
        first_name?: string;
        last_name?: string;
        has_car?: 0 | 1 | null;
        terms_consent_accepted?: 0 | 1 | null;
        app_version?: string;
        whatsapp_opt_in?: 0 | 1;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface DriverProvisionalRegistrationStartRegistrationResponse {
        token: string;
        channels?: VerificationCodeChannel[];
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export interface DriverProvisionalRegistrationStartVerificationRequest extends WebRequestParams {
        token: string;
        verification_code_channel?: VerificationCodeChannel;
        app_version?: string;
        challenge?: CaptchaChallengeToken;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export interface DriverProvisionalRegistrationStartVerificationResponse {
        verification_code_channel: VerificationCodeChannel;
        verification_code_target: string;
        verification_code_length: number;
        resend_wait_time_seconds: number;
        available_verification_code_channels: VerificationCodeChannel[];
        registration_identifiers?: RegistrationUniqueIdentifiers;
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export enum DriverRegistrationFormOfWork {
        LICENSE_OWNER = "license_owner",
        EMPLOYEE_OF_A_FLEET = "employee_of_a_fleet"
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export enum DriverRegistrationLogLastStep {
        STEP1 = "step_1",
        STEP2 = "step_2",
        STEP3 = "step_3",
        STEP4 = "step_4",
        STEP5 = "step_5",
        STEP6 = "step_6"
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export enum DriverRegistrationLogLicenseTypeSelection {
        PRIVATE_DRIVER = "private_driver",
        TAXI_DRIVER = "taxi_driver",
        UNKNOWN = "unknown"
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/driver/constants/Types.ts)} */
    export enum DriverRegistrationLogStatus {
        IN_PROGRESS = "in_progress",
        SUBMITTED = "submitted",
        ONBOARDING = "onboarding",
        DECLINED = "declined",
        DECLINED_SILENTLY = "declined_silently",
        ACCEPTED = "accepted",
        DOCUMENTS_NEED_RESUBMISSION = "documents_need_resubmission",
        DOCUMENTS_RESUBMITTED = "documents_resubmitted",
        DELETED = "deleted",
        PENDING_COMPANY_APPROVAL = "pending_company_approval"
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export enum DriverRegistrationLogType {
        COMPANY = "company",
        PERSON = "person",
        OTHER = "other"
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface DriverRegistrationValidateDriverRegistrationDocumentsForResubmissionBody {
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface DriverSignupData extends DriverSignupFields {
        last_step: DriverRegistrationLogLastStep;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface DriverSignupFields {
        account_branch_code?: string | null;
        birthday?: string | null;
        referral_code?: string;
        car_color?: string;
        car_license_plate?: string;
        car_model_id?: number | null;
        car_transport_license_number?: string;
        car_vin?: string;
        driver_license?: string;
        first_name?: string;
        gender?: Gender | null;
        has_car?: boolean;
        has_taxi_license?: boolean;
        taxi_license?: string;
        language?: string;
        last_name?: string;
        personal_code?: string;
        car_year?: number;
        billing_type?: DriverRegistrationLogType | null;
        company_name?: string;
        address?: string;
        reg_code?: string;
        company_is_liable_to_vat?: boolean;
        vat_code?: string;
        beneficiary_name?: string;
        iban?: string;
        swift?: string;
        allow_fleet_matching?: boolean;
        province_id?: number;
        license_type_selection?: DriverRegistrationLogLicenseTypeSelection | null;
        generic_fields?: GenericField[];
        country_of_birth?: string;
        tax_identification_numbers?: TaxIdentificationNumber[];
        transport_operator_name?: string;
        transport_operator_number?: string;
        form_of_work?: DriverRegistrationFormOfWork | null;
    }
    /** Generated from: {@link [ExperimentsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/ExperimentsService.ts)} */
    export interface DriverVisitorExperimentParameters {
        [key: string]: any;
    }
    /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
    export interface DriverVisitorExperimentParametersRequest {
        event_key: string;
        language?: string;
        visitor_id: string;
        country_code?: string;
        city_id?: number;
    }
    /** Generated from: {@link [ExperimentsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/ExperimentsService.ts)} */
    export interface DriverVisitorExperimentParametersResponse {
        experiments: DriverVisitorExperimentParameters;
    }
    /** Generated from: {@link [ExperimentsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/ExperimentsService.ts)} */
    export interface ExperimentParameters {
        variant?: string;
        experiment_name?: string;
        [key: string]: any;
    }
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FieldDefinitionBase.ts)} */
    export interface Field {
        name: string;
        type: FrontendFieldDefinitionType;
        current_value: any;
        is_required: boolean;
        translations: FieldTranslations;
        show_if?: ShowConditions;
        is_expiry_required?: boolean;
        document_type_key?: string;
        document_type_example_url?: string | null;
        default_value?: any;
        select_values?: SelectValueItem[];
        is_searchable?: boolean;
        min_length?: number;
        max_length?: number;
        min_value?: number;
        max_value?: number;
        generic_field_key?: string;
    }
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FieldDefinitionBase.ts)} */
    export interface FieldTranslations {
        label: string;
        description: string;
        placeholder: string;
    }
    /** Generated from: {@link [FlowDataService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FlowDataService.ts)} */
    export interface FinalScreen {
        translations: StepTranslations;
        fields?: Field[];
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface FinalScreenTranslations {
        driver_account_approved_title: string;
        driver_account_approved_description: string;
        driver_account_approved_login_as_driver: string;
        install_bolt_app: string;
    }
    /** Generated from: {@link [DynamicLanguageConfig.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/config/DynamicLanguageConfig.ts)} */
    export interface FlowLanguage {
        key: string;
        name: string;
        direction: ScriptDirection;
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export interface FormCityMinimal {
        id: number;
        name: string;
        language?: string;
        local_name?: string;
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export interface FormCountryMinimal {
        name: string;
        code: string;
        language: string;
        cities: FormCityMinimal[];
    }
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FieldDefinitionBase.ts)} */
    export enum FrontendFieldDefinitionType {
        INPUT = "input",
        SELECT = "select",
        CHECKBOX = "checkbox",
        DATE = "date",
        DOCUMENT_UPLOAD = "document_upload",
        TEXT = "text",
        GENERIC_INPUT = "generic_input",
        GENERIC_DROPDOWN = "generic_dropdown",
        TAX_IDENTIFICATION_NUMBERS = "tax_identification_numbers",
        HIDDEN = "hidden",
        EMBEDDED_RESOURCE = "embedded_resource",
        LINK_BUTTON = "link_button"
    }
    /** Generated from: {@link [FleetRegistrationInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/asyncApi/fleet/FleetRegistrationInternalAsyncApi.ts)} */
    export enum Gender {
        UNSPECIFIED = "unspecified",
        MALE = "male",
        FEMALE = "female",
        OTHER = "other"
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface GenericField {
        key: string;
        value: string;
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface GetAllCountriesRequest {
        language?: string;
    }
    /** Generated from: {@link [RegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/RegistrationService.ts)} */
    export interface GetAllCountriesResponse {
        list: Country[];
    }
    /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
    export interface GetCommonTranslationsRequest {
        language?: string;
        country?: string;
    }
    /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
    export interface GetCommonTranslationsResponse {
        language: string;
        common_translations: CommonTranslations;
    }
    /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
    export interface GetDocumentCaptureModeRequest {
        hash: string;
        app_version?: string;
    }
    /** Generated from: {@link [DocumentCaptureService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DocumentCaptureService.ts)} */
    export interface GetDocumentCaptureModeResponse {
        use_enhanced_capture: boolean;
        document_type_keys?: string[];
    }
    /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
    export interface GetDocumentsResultsRequest {
        hash: string;
        language?: string;
    }
    /** Generated from: {@link [DocumentCaptureService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DocumentCaptureService.ts)} */
    export interface GetDocumentsResultsResponse {
        documents: DocumentResultResponse[];
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface GetDriverRegistrationLogRequest {
        hash: string;
    }
    /** Generated from: {@link [RegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/RegistrationService.ts)} */
    export interface GetDriverRegistrationResponse {
        hash: string;
        last_step: DriverRegistrationLogLastStep;
        account_branch_code: string | null;
        address: string | null;
        beneficiary_name: string | null;
        birthday: string | null;
        car_color: string | null;
        car_model_id: number | null;
        car_transport_licence_number: string | null;
        city: string | null;
        company_is_liable_to_vat: 0 | 1 | null;
        company_name: string | null;
        country: string | null;
        display_name: string | null;
        driver_license: string | null;
        email: string | null;
        first_name: string | null;
        flow_id: number | null;
        has_car: 0 | 1 | null;
        has_taxi_license: 0 | 1 | null;
        iban: string | null;
        language: string | null;
        last_name: string | null;
        license_plate: string | null;
        model: string | null;
        personal_code: string | null;
        phone: string | null;
        reg_code: string | null;
        seats: number | null;
        swift: string | null;
        taxi_license: string | null;
        type: DriverRegistrationLogType | null;
        vat_code: string | null;
        vin: string | null;
        year: string | null;
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface GetDriverRegistrationStatusRequest {
        hash: string;
        language?: string;
    }
    /** Generated from: {@link [RegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/RegistrationService.ts)} */
    export interface GetDriverRegistrationStatusResponse {
        status: DriverRegistrationLogStatus;
        decline_reasons?: string[];
    }
    /** Generated from: {@link [ExperimentsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/ExperimentsService.ts)} */
    export interface GetExperimentParametersBeforeSignupRequest {
        event_key: string;
        identifier: string;
        city_id: number;
        country_code: string;
        language?: string;
        enroll_to_mixpanel?: boolean;
        brand?: BrandEnum;
    }
    /** Generated from: {@link [ExperimentsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/ExperimentsController.ts)} */
    export interface GetExperimentParametersRequest {
        hash: string;
        event_key: string;
        language?: string;
    }
    /** Generated from: {@link [FieldsController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/FieldsController.ts)} */
    export interface GetFieldsRequest {
        hash: string;
        language?: string;
    }
    /** Generated from: {@link [FieldsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FieldsService.ts)} */
    export interface GetFieldsResponse {
        steps: Step[];
        final_screen?: FinalScreen;
        current_step: DriverRegistrationLogLastStep;
        common_translations: CommonTranslations;
        language: string;
        languages: FlowLanguage[];
        driver_registration_log_status: DriverRegistrationLogStatus;
        flow_id: number;
        city_id?: number;
        city?: string;
        country?: string;
        features: GetSignupProcessFeaturesResponse;
        registration_identifiers?: RegistrationUniqueIdentifiers;
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface GetFormDataForRiderUserRequest {
        rider_user_uuid: string;
        language?: string;
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface GetFormDataForRiderUserResponse {
        cities_by_countries: FormCountryMinimal[];
        city_id_by_ip?: number;
        title: string;
        sub_title: string;
        country_label: string;
        city_label: string;
        terms_and_conditions_text: string;
        register_button_label: string;
    }
    /** Generated from: {@link [FeaturesService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FeaturesService.ts)} */
    export interface GetSignupProcessFeaturesResponse {
        autosave: AutosaveParameters;
        banners: Banners;
        vehicle_marketplace: VehicleMarketplaceParameters;
    }
    /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
    export interface GetSupportedLanguagesRequest {
        country?: string;
    }
    /** Generated from: {@link [LanguageController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/LanguageController.ts)} */
    export interface GetSupportedLanguagesResponse {
        languages: FlowLanguage[];
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface IconBanner extends BaseBanner {
        type: BannerType.ICON_BANNER;
        icon_url: string;
        title_html: string | null;
        description_html: string | null;
        action: BannerActionType;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface NumericCondition extends BaseCondition {
        type: ConditionType.NUMERIC;
        operation: NumericConditionOperationType;
        value: number;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export enum NumericConditionOperationType {
        EQUAL = "equal",
        NOT_EQUAL = "not_equal",
        LESS = "less",
        MORE = "more",
        LESS_OR_EQUAL = "less_or_equal",
        MORE_OR_EQUAL = "more_or_equal"
    }
    /** Generated from: {@link [IncogniaDataProviderService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/IncogniaDataProviderService.ts)} */
    export interface RegistrationUniqueIdentifiers {
        verification_attempt_id: string;
        phone_number_id: string;
        account_id?: string;
    }
    /** Generated from: {@link [DynamicLanguageConfig.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/config/DynamicLanguageConfig.ts)} */
    export enum ScriptDirection {
        LTR = "ltr",
        RTL = "rtl"
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface SelectCondition extends BaseCondition {
        type: ConditionType.SELECT;
        equals: string | number;
    }
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FieldDefinitionBase.ts)} */
    export interface SelectValueItem {
        title: string;
        value: string | number;
        subitems?: SelectValueSubItem[];
    }
    /** Generated from: {@link [FieldDefinitionBase.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FieldDefinitionBase.ts)} */
    export interface SelectValueSubItem {
        title: string;
        value: number | string;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export interface ShowConditions {
        [fieldKey: string]: CheckboxCondition | SelectCondition | DocumentUploadCondition | DefaultCondition | NumericCondition;
    }
    /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
    export interface SignupQuery {
        hash: string;
        language?: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface SignupRequest {
        signup_data: DriverSignupData;
        web_marketing_data?: WebMarketingData;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface StartRegistrationForRiderUserBody {
        city_id?: number;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface StartRegistrationForRiderUserRequest extends WebRequestParams {
        rider_user_uuid: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface StartRegistrationForRiderUserResponse {
        is_new_registration_created: boolean;
        refresh_token?: string;
        registration_hash: string;
        app_promotion?: AppPromotionMode;
        registration_identifiers?: RegistrationUniqueIdentifiers;
    }
    /** Generated from: {@link [FlowDataService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FlowDataService.ts)} */
    export interface Step {
        id: DriverRegistrationLogLastStep;
        translations: StepTranslations;
        fields: Field[];
    }
    /** Generated from: {@link [FlowDataService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FlowDataService.ts)} */
    export interface StepTranslations {
        label: string;
        title: string;
        subtitle: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/common/Types.ts)} */
    export interface TaxIdentificationNumber {
        country?: string;
        tin?: string;
    }
    /** Generated from: {@link [FieldsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FieldsService.ts)} */
    export interface TaxIdentificationNumberFormat {
        localized_name: string;
        format_expressions: string[];
        placeholder: string;
    }
    /** Generated from: {@link [FieldsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FieldsService.ts)} */
    export interface TaxIdentificationNumberFormatsResponse {
        list: {
            [key: string]: TaxIdentificationNumberFormat;
        };
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface TaxIdentificationNumberTranslations {
        add_number: string;
        country_placeholder: string;
        country_required: string;
        number_placeholder: string;
        number_required: string;
        number_format_invalid: string;
    }
    /** Generated from: {@link [FlowData.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/common/flow_form_builder/FlowData.ts)} */
    export enum UploadStatus {
        NOT_UPLOADED = "not_uploaded",
        IN_MODAL_DIALOG = "in_modal_dialog",
        UPLOADED = "uploaded"
    }
    /** Generated from: {@link [FeaturesService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/FeaturesService.ts)} */
    export interface VehicleMarketplaceParameters {
        enabled: boolean;
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface VehicleMarketplaceTranslations {
        title: string;
        text: string;
        select_vehicle_button: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/DriverProvisionalRegistrationService.ts)} */
    export enum VerificationCodeChannel {
        SMS = "sms",
        CALL = "call",
        WHATSAPP = "whatsapp"
    }
    /** Generated from: {@link [CommonTranslationsService.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/service/CommonTranslationsService.ts)} */
    export interface VerificationTranslations {
        enter_code: string;
        code_sent_message: string;
        resend_code_in: string;
        code_missing: string;
        code_resent: string;
        code_resent_by_call: string;
        code_label: string;
        code_placeholder: string;
        code_too_young: string;
        resending_code: string;
        resend_code_sms: string;
        resend_code_call: string;
        resend_code_whatsapp: string;
        incorrect_code: string;
        send_limit_reached: string;
        code_not_send: string;
        code_expired: string;
        attempts_limit_reached: string;
        logging_in: string;
        sms_code_not_found: string;
        did_not_get_the_code: string;
        resend_to_try_again: string;
        try_to_resend_code_or: string;
        enter_code_with_length: string;
        login_with_username_and_password: string;
        continue_new_registration: string;
        login_to_existing_registration: string;
        verify_phone_number: string;
        verification_code_will_be_sent_to: string;
        get_code_via_whatsapp: string;
        get_code_via_sms: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/common/constants/Types.ts)} */
    export interface VisitorTrackingData {
        visitor_id?: string;
    }
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/common/constants/Types.ts)} */
    export interface WebMarketingData {
        message_id?: string;
        fbp?: string;
        url?: string;
        user_agent?: string;
        google_client_id?: string;
    }
    /** Generated from: {@link [DriverProvisionalRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/src/rides/driver/driver_registration/main/portal/controller/DriverProvisionalRegistrationController.ts)} */
    export interface WebRequestParams {
        version: string;
        deviceId: string;
        device_name: string;
        device_os_version: string;
        language: string;
        device_type?: string;
        visitor_id?: string;
    }
    /**
     * Usage: type tUserId = tEntityId<"userId">;
     */
    /** Generated from: {@link [Types.d.ts](https://github.com/bolteu/bolt-server/blob/0369fb6f12b059f72eec14cad31127fa27332630/node_modules/@taxify/nodejs-common/lib/types/Types.d.ts)} */
    export type tEntityId<Name> = number & {
        __is_entity_id: never;
        __name: Name;
    };
}
