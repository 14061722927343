import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useCallback, useEffect, useMemo, useState } from 'react';
import driverRegistrationApiClient from '../../../api/DriverRegistrationApi';

export interface TinPair {
  index: number;
  country?: string;
  tin?: string;
  tinError?: string;
  countryError?: string;
}

interface TinFormatters {
  [
    key: string
  ]: DriverRegistrationPortalNoAuthService.TaxIdentificationNumberFormat;
}

export interface UseTinPairsHandlers {
  setCountry: (index: number, country: string | undefined) => void;
  setTin: (index: number, tin: string | undefined) => void;
  saveIfEnabledAfterValidation: (index: number) => void;
  addNewTinRow: () => void;
  deleteTinRowAndSaveIfEnabled: (index: number) => void;
}

interface UseTinPairsHookResult {
  tinPairs: TinPair[];
  tinFormatters: TinFormatters;
  handlers: UseTinPairsHandlers;
}

export const useTinPairs = (
  initialTins: TinPair[],
  updateCallback: (newValue: Omit<TinPair, 'index'>[] | null) => void,
  blurCallback: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations,
  defaultCountry: string | undefined
): UseTinPairsHookResult => {
  const [tinFormatters, setTinFormatters] = useState<TinFormatters>({});

  useEffect(() => {
    const fetchData = async () => {
      driverRegistrationApiClient
        .getTaxIdentificationNumberFormats({})
        .then((result) => {
          setTinFormatters(result.list);
        });
    };

    fetchData().catch(); // Silently ignore, use empty tinFormatter list.
  }, []);

  const updateHandler = useCallback(
    (newValue: TinPair[] | null) => {
      if (!newValue) {
        updateCallback(null);
        return;
      }
      updateCallback(
        newValue.map((tinPair) => ({
          country: tinPair.country,
          tin: tinPair.tin,
        }))
      );
    },
    [updateCallback]
  );

  const tinsForState = (initialTins ?? []).map((pair, index) => ({
    ...pair,
    index,
  }));
  const [tinPairs, setTinPairs] = useState(
    tinsForState.length
      ? tinsForState
      : ([
          {
            tin: undefined,
            country: defaultCountry,
            index: 0,
          },
        ] as TinPair[])
  );

  const updateTinPair = useCallback(
    (index: number, tinPair: TinPair) => {
      const newTins = [
        ...tinPairs.slice(0, index),
        tinPair,
        ...tinPairs.slice(index + 1),
      ];
      setTinPairs(newTins);
      updateHandler(newTins);
    },
    [tinPairs, updateHandler]
  );

  const resetErrors = useCallback(() => {
    setTinPairs(
      tinPairs.map((pair) => ({
        country: pair.country,
        tin: pair.tin,
        index: pair.index,
      }))
    );
  }, [tinPairs]);

  const handlers = useMemo(
    () => ({
      setCountry: (index: number, country: string | undefined) => {
        resetErrors();
        updateTinPair(index, {
          country,
          tin: tinPairs[index].tin,
          index: tinPairs[index].index,
        });
      },
      setTin: (index: number, tin: string | undefined) => {
        resetErrors();
        updateTinPair(index, {
          country: tinPairs[index].country,
          tin,
          index: tinPairs[index].index,
        });
      },
      saveIfEnabledAfterValidation: (index: number) => {
        const pair = tinPairs[index];
        if (!pair) {
          return;
        }
        if (pair.country && tinFormatters[pair.country]) {
          if (!pair.tin || pair.tin.length < 1) {
            updateTinPair(index, {
              ...pair,
              tinError:
                commonTranslations.tax_identification_numbers.number_required,
            });
          } else {
            const tinFormatter = tinFormatters[pair.country];
            const formatError = !tinFormatter.format_expressions.some(
              (format) => {
                const match = pair.tin?.match(format);
                return !!(match && pair.tin === match[0]);
              }
            );
            if (formatError) {
              updateTinPair(index, {
                ...pair,
                tinError:
                  commonTranslations.tax_identification_numbers.number_format_invalid.replace(
                    '{examples}',
                    tinFormatter.placeholder
                  ),
              });
            } else {
              blurCallback({} as React.FocusEvent<HTMLInputElement, Element>);
            }
          }
        } else {
          const errors: { countryError?: string; tinError?: string } = {
            countryError: undefined,
            tinError: undefined,
          };
          if (!pair.country) {
            errors.countryError =
              commonTranslations.tax_identification_numbers.country_required;
          }
          if (!pair.tin) {
            errors.tinError =
              commonTranslations.tax_identification_numbers.number_required;
          }
          updateTinPair(index, {
            ...pair,
            countryError: errors.countryError,
            tinError: errors.tinError,
          });
          if (!errors.countryError && !errors.tinError) {
            blurCallback({} as React.FocusEvent<HTMLInputElement, Element>);
          }
        }
      },
      addNewTinRow: () => {
        const indexes = tinPairs.map((pair) => pair.index);
        const newIndex = Math.max(...indexes) + 1;
        const newTins = [
          ...tinPairs,
          {
            country: defaultCountry,
            tin: undefined,
            index: newIndex,
          },
        ];
        setTinPairs(newTins);
        updateHandler(newTins);
      },
      deleteTinRowAndSaveIfEnabled: (index: number) => {
        const newTins = [
          ...tinPairs.slice(0, index),
          ...tinPairs.slice(index + 1),
        ];
        setTinPairs(newTins);
        updateHandler(newTins.length ? newTins : null);
        blurCallback({} as React.FocusEvent<HTMLInputElement, Element>);
      },
    }),
    [
      commonTranslations.tax_identification_numbers.country_required,
      commonTranslations.tax_identification_numbers.number_format_invalid,
      commonTranslations.tax_identification_numbers.number_required,
      defaultCountry,
      resetErrors,
      tinFormatters,
      tinPairs,
      updateHandler,
      updateTinPair,
      blurCallback,
    ]
  );

  return { tinPairs, tinFormatters, handlers };
};
