import React from 'react';
import MarkdownWrapper from '../common/MarkdownWrapper';
import accepted from '../../assets/accepted.svg';
import { LoadingIcon } from '../common/LoadingIcon';
import icon from '../../assets/check-mark.svg';
import { hasPartnerLoginDeeplinkSupport } from '../../common/helpers';
import { getTranslations } from '../../redux/form/formSelectors';
import { useSelector } from '../../redux/store';
import { getDeeplinkScheme } from '../../common/environment';

export const AcceptedFinalScreen: React.FC = () => {
  const commonTranslations = useSelector(getTranslations);
  const [showLoginButton, setShowLoginButton] = React.useState(
    hasPartnerLoginDeeplinkSupport()
  );
  const [showLoginHints, setShowLoginHints] = React.useState(
    !hasPartnerLoginDeeplinkSupport()
  );
  const [isRedirectInProgress, setIsRedirectInProgress] = React.useState(false);
  const onLoginButtonClick = React.useCallback(async () => {
    if (isRedirectInProgress) {
      return;
    }
    setIsRedirectInProgress(true);
    window.location.replace(`${getDeeplinkScheme()}partner-login/`);
    const timeoutMs = hasPartnerLoginDeeplinkSupport() ? 10000 : 3000;
    // In case deeplink did not work - display login hints (fallback)
    setTimeout(() => {
      setShowLoginButton(false);
      setShowLoginHints(true);
    }, timeoutMs);
  }, [isRedirectInProgress]);
  return (
    <div>
      <div className="pb-8 px-2">
        <div className="text-center p-2 mx-auto">
          <img
            src={accepted}
            alt={commonTranslations.final_screen.driver_account_approved_title}
            className="mx-auto mb-5"
          />
          <h1 className="text-2xl font-bold">
            <MarkdownWrapper>
              {commonTranslations.final_screen.driver_account_approved_title}
            </MarkdownWrapper>
          </h1>
          <div className="text-gray-700 text-sm mt-1">
            <MarkdownWrapper>
              {
                commonTranslations.final_screen
                  .driver_account_approved_description
              }
            </MarkdownWrapper>
          </div>
        </div>
        <div className="flex flex-col content-center justify-center desktop:w-1/2 p-2 pt-6 mx-auto">
          {showLoginButton && (
            <button
              type="button"
              className="w-full tablet:w-auto m-0 mt-6 px-8 py-3 border-none rounded-full text-center font-bold bg-purple-500 text-white"
              onClick={onLoginButtonClick}
              disabled={isRedirectInProgress}
            >
              {isRedirectInProgress ? (
                <LoadingIcon className="text-white animate-spin mx-auto" />
              ) : (
                commonTranslations.final_screen
                  .driver_account_approved_login_as_driver
              )}
            </button>
          )}
          {showLoginHints && (
            <div className="flex flex-row">
              <div className="shrink pr-2 pt-0.5">
                <img
                  src={icon}
                  alt={commonTranslations.final_screen.install_bolt_app}
                  width={24}
                  height={24}
                  color="#2F313F"
                />
              </div>
              <div className="w-full">
                <div className="text-gray-700 text-sm">
                  <p className="mt-2">
                    {commonTranslations.final_screen.install_bolt_app}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
