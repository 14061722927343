interface Handler<T> {
  [actionType: string]: (state: T, action: any) => T;
}

export function createReducer<T>(initialState: T, handlers: Handler<T>) {
  return (state = initialState, action: any): T => {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
