import React, { ReactElement } from 'react';
import { getAppBrand } from '../../common/environment';

const BOLT_LOGO: ReactElement = (
  <svg
    width="152"
    height="32"
    viewBox="0 0 152 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.393 24.29h7.775c6.426 0 11.632-5.108 11.632-11.406 0-6.297-5.206-11.406-11.632-11.406h-7.775v22.813Zm5.109-4.69V6.17h2.666c3.856 0 6.459 3.02 6.459 6.714 0 3.695-2.603 6.716-6.459 6.716h-2.666Zm16.548 4.69h4.852v-7.614c0-2.314 1.156-3.92 3.245-3.92.77 0 1.413.128 2.152.385V8.225a5.83 5.83 0 0 0-1.574-.192c-1.8 0-3.31 1.188-3.823 2.602v-2.41H85.05v16.066ZM99.776 6.04c1.703 0 3.052-1.349 3.052-3.02 0-1.67-1.349-3.02-3.052-3.02-1.639 0-3.02 1.35-3.02 3.02 0 1.671 1.381 3.02 3.02 3.02Zm-2.41 18.25h4.852V8.226h-4.852v16.066Zm13.218 0h4.499l7.036-16.065h-5.398l-3.92 9.768-3.888-9.768h-5.365l7.036 16.066Zm19.876.354c3.374 0 6.041-1.381 7.551-3.534l-3.599-2.7c-.61.933-1.992 1.832-3.888 1.832-1.831 0-3.63-.9-3.92-2.827h11.664c.096-.771.096-1.285.096-1.703 0-5.205-3.759-7.84-8-7.84-4.884 0-8.451 3.63-8.451 8.386 0 5.173 3.824 8.386 8.547 8.386Zm-3.727-10.41c.418-1.767 2.088-2.41 3.502-2.41 1.414 0 2.892.707 3.31 2.41h-6.812Zm14.046 10.056h4.851v-7.614c0-2.314 1.157-3.92 3.246-3.92.771 0 1.413.128 2.152.385V8.225a5.824 5.824 0 0 0-1.574-.192c-1.799 0-3.31 1.188-3.824 2.602v-2.41h-4.851v16.066Z"
      fill="#2A313C"
    />
    <path
      d="M15.763 11.787A7.266 7.266 0 0 0 9.586.695H.972v23.537h9.691a7.268 7.268 0 0 0 5.1-12.445ZM6.51 6.233h3.076c.954 0 1.73.777 1.73 1.73 0 .955-.776 1.731-1.73 1.731H6.51v-3.46Zm4.153 12.46H6.51v-3.46h4.153c.954 0 1.73.776 1.73 1.73s-.776 1.73-1.73 1.73ZM44.698.696v23.537H39.16V1.872L44.698.695ZM28.546 7.38a8.546 8.546 0 1 0 8.545 8.546c-.007-4.715-3.83-8.546-8.545-8.546Zm0 11.315a2.767 2.767 0 0 1-2.769-2.77 2.767 2.767 0 0 1 2.769-2.768 2.767 2.767 0 0 1 2.769 2.769 2.767 2.767 0 0 1-2.77 2.769Zm2.769 10.537a2.769 2.769 0 1 1-5.538 0 2.769 2.769 0 0 1 5.538 0ZM55.313 7.695v5.53H52.55v4.353c0 1.316.423 2.285 1.53 2.285.424.007.84-.046 1.24-.162v4.077a5.618 5.618 0 0 1-2.7.692h-.07c-.035 0-.07-.002-.105-.004-.033-.002-.065-.004-.095-.004h-.053l-.1-.007c-3.093-.162-5.192-2.108-5.192-5.484V4.642l5.538-1.178v4.23h2.769Z"
      fill="#5B68F6"
    />
  </svg>
);

const HOPP_LOGO: ReactElement = (
  <svg
    version="1.0"
    height="32"
    viewBox="188.46 1015.618 1009.753 426.057"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      transform="matrix(1.333333, 0, 0, -1.333333, -268.820831, 1669.577393)"
      id="g10"
    >
      <g clipPath="url(#clipPath18)" id="g14">
        <g transform="translate(665.7297,338.6431)" id="g20">
          <path
            id="path22"
            fill="#000000"
            d="M 0,0 C 0,16.5 -12.873,29.364 -29.362,29.364 -45.853,29.364 -58.727,16.49 -58.727,0 c 0,-16.49 12.864,-29.364 29.365,-29.364 C -12.862,-29.364 0,-16.49 0,0 m 57.416,0 c 0,-48.839 -37.614,-86.127 -86.79,-86.127 -49.173,0 -86.788,37.288 -86.788,86.127 0,48.838 37.615,86.127 86.788,86.127 49.176,0 86.79,-37.289 86.79,-86.127 m 317.443,0 c 0,16.5 -12.873,29.364 -29.362,29.364 -16.492,0 -29.365,-12.874 -29.365,-29.364 0,-16.49 12.865,-29.364 29.365,-29.364 16.5,0 29.362,12.874 29.362,29.364 m 59.403,0 c 0,-49.827 -32.339,-86.127 -73.591,-86.127 -15.906,0 -30.066,5.622 -40.912,16.846 v -70.633 l -57.416,-11.878 V 82.491 h 56.101 V 67.858 c 11.016,12.172 25.679,18.259 42.239,18.259 42.899,0 73.587,-37.95 73.587,-86.127 M 186.443,0 c 0,16.5 -12.873,29.364 -29.374,29.364 -16.5,0 -29.365,-12.874 -29.365,-29.364 0,-16.49 12.874,-29.364 29.365,-29.364 16.489,0 29.374,12.874 29.374,29.364 m 59.391,0 c 0,-49.827 -32.338,-86.127 -73.59,-86.127 -15.907,0 -30.066,5.622 -40.913,16.846 v -70.633 L 73.916,-151.792 V 82.491 h 56.101 V 67.858 c 11.016,12.172 25.679,18.259 42.238,18.259 42.9,0 73.588,-37.95 73.588,-86.127 m -438.558,66 h -69.962 v 85.801 H -322.73 V -82.501 h 60.053 V 8.25 h 69.953 v -90.751 h 60.062 v 234.292 h -60.062 V 65.99 Z M 1.651,-136.615 c 0,-17.49 -13.526,-31.025 -31.013,-31.025 -17.489,0 -31.016,13.527 -31.016,31.025 0,17.498 13.527,31.014 31.016,31.014 17.487,0 31.013,-13.526 31.013,-31.014"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Logo: React.FC = (): ReactElement => {
  const isHopp = getAppBrand() === 'hopp';
  const logo = isHopp ? HOPP_LOGO : BOLT_LOGO;
  return (
    <div className="clearfix ml-4">
      <div className="flex flex-row justify-center mt-5 pb-3">{logo}</div>
    </div>
  );
};
