import { Mixpanel } from '../../common/mixpanel';

export enum ActionType {
  MIXPANEL_LOADED = 'MIXPANEL/LOADED',
}

export interface MixpanelLoaded {
  type: ActionType.MIXPANEL_LOADED;
  instance: Mixpanel;
}

export const mixpanelLoaded = (instance: Mixpanel): MixpanelLoaded => ({
  type: ActionType.MIXPANEL_LOADED,
  instance,
});
