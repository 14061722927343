import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';

export const IN_PROGRESS_STATUSES = [
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus.IN_PROGRESS,
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
    .DOCUMENTS_NEED_RESUBMISSION,
];

export const SUBMITTED_STATUSES = [
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus.SUBMITTED,
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
    .DOCUMENTS_RESUBMITTED,
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus.ONBOARDING,
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
    .PENDING_COMPANY_APPROVAL,
];

export const ACCEPTED_STATUSES = [
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus.ACCEPTED,
];

export const DECLINED_STATUSES = [
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus.DECLINED,
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
    .DECLINED_SILENTLY,
];

export const FINAL_STATUSES = [
  ...SUBMITTED_STATUSES,
  ...ACCEPTED_STATUSES,
  ...DECLINED_STATUSES,
];

export const ALLOWED_STATUSES = [...IN_PROGRESS_STATUSES, ...FINAL_STATUSES];
