import Modal from 'react-modal';
import React, { PropsWithChildren } from 'react';

interface DocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DocumentModal: React.FC<PropsWithChildren<DocumentModalProps>> = ({
  isOpen,
  onClose,
  children,
}: PropsWithChildren<DocumentModalProps>) => {
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      appElement={document.getElementById('appWithStore') as HTMLElement}
      className="fixed h-full w-full tablet:h-auto tablet:w-auto tablet:min-h-[10rem] tablet:min-w-[20rem] tablet:max-w-5xl p-0 top-1/2 left-1/2 right-auto bottom-auto border-0 bg-white overflow-auto rounded outline-none shadow"
      overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg-gray-700 bg-opacity-75"
      style={{
        content: {
          transform: 'translate(-50%,-50%)',
        },
      }}
    >
      {children}
    </Modal>
  );
};
