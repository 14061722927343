var HttpInterceptorStandardRequest = /** @class */ (function () {
    function HttpInterceptorStandardRequest(config) {
        this.config = config;
    }
    HttpInterceptorStandardRequest.prototype.intercept = function (req, next) {
        req.appendQueryParams(this.config.getStandardRequest());
        return next(req);
    };
    return HttpInterceptorStandardRequest;
}());
export { HttpInterceptorStandardRequest };
