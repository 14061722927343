var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import jwtDecode from 'jwt-decode';
import { HttpResponseUtilsBoltServer } from '@bolteu/http-normalizer-bolt-server';
import { AuthStrategy } from '@bolteu/http-request-bolt-server';
import { waitForNewAccessToken, willAccessTokenExpireSoon } from './JwtAuthUtils';
var HttpInterceptorJwtAuth = /** @class */ (function () {
    function HttpInterceptorJwtAuth(authStrategy) {
        this.authStrategy = authStrategy;
    }
    HttpInterceptorJwtAuth.prototype.intercept = function (req, next) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(((_a = req.meta) === null || _a === void 0 ? void 0 : _a.auth.enabled) &&
                            (req.meta.auth.strategy === AuthStrategy.JWT || req.meta.auth.strategy === AuthStrategy.MULTI))) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.appendAccessToken(req, { forceUpdate: false })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, next(req)];
                    case 2:
                        result = _b.sent();
                        if (!(HttpResponseUtilsBoltServer.isBoltServerResponse(result) &&
                            HttpResponseUtilsBoltServer.isJwtTokenExpiredError(result))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.appendAccessToken(req, { forceUpdate: true })];
                    case 3:
                        _b.sent();
                        return [2 /*return*/, next(req)];
                    case 4: return [2 /*return*/, result];
                    case 5: return [2 /*return*/, next(req)];
                }
            });
        });
    };
    HttpInterceptorJwtAuth.prototype.appendAccessToken = function (req, options) {
        return __awaiter(this, void 0, void 0, function () {
            var accessToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = this.authStrategy.getAuthToken();
                        if (!accessToken) {
                            return [2 /*return*/];
                        }
                        if (!this.authStrategy.getExtraConfig) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateAuthToken(accessToken, this.getAuthUpdateConfigWithDefaults(this.authStrategy.getExtraConfig()), options)];
                    case 1:
                        accessToken = _a.sent();
                        _a.label = 2;
                    case 2:
                        req.appendHeaders({ Authorization: "Bearer " + accessToken });
                        return [2 /*return*/];
                }
            });
        });
    };
    HttpInterceptorJwtAuth.prototype.shouldStartAsyncRefresh = function (parsedAccessToken, accessTokenRefreshTimeoutBeforeExpiryInSeconds) {
        return willAccessTokenExpireSoon(parsedAccessToken, accessTokenRefreshTimeoutBeforeExpiryInSeconds);
    };
    HttpInterceptorJwtAuth.prototype.shouldStartBlockingRefresh = function (parsedAccessToken, accessTokenBlockingTimeoutBeforeExpiryInSeconds, options) {
        if (options === void 0) { options = { forceUpdate: false }; }
        if (options === null || options === void 0 ? void 0 : options.forceUpdate) {
            return true;
        }
        return willAccessTokenExpireSoon(parsedAccessToken, accessTokenBlockingTimeoutBeforeExpiryInSeconds);
    };
    HttpInterceptorJwtAuth.prototype.updateAuthToken = function (accessToken, config, options) {
        return __awaiter(this, void 0, void 0, function () {
            var parsedAccessToken;
            return __generator(this, function (_a) {
                // If provided something other
                try {
                    parsedAccessToken = jwtDecode(accessToken);
                }
                catch (_e) {
                    // It is up to application to decide what to do in case if parsing JWT token fails
                    config.authTokenParseDidThrow(accessToken);
                }
                // we need to check - did token expire before sending a request, if it did - we need to refresh it
                if (parsedAccessToken) {
                    // in case when access token not valid -> all requests will wait till we load it
                    // (it could happen after app return from background)
                    if (this.shouldStartBlockingRefresh(parsedAccessToken, config.accessTokenBlockingTimeoutBeforeExpiryInSeconds, options)) {
                        return [2 /*return*/, waitForNewAccessToken(config.updateAuthToken, config.accessTokenBlockingTimeoutBeforeExpiryInSeconds)];
                    }
                    // if access token still valid, but in `accessTokenBlockingTimeoutBeforeExpiryInSeconds`
                    // it will be invalid -> we will start to fetch token without forcing other requests to wait
                    if (this.shouldStartAsyncRefresh(parsedAccessToken, config.accessTokenRefreshTimeoutBeforeExpiryInSeconds)) {
                        waitForNewAccessToken(config.updateAuthToken, config.accessTokenBlockingTimeoutBeforeExpiryInSeconds);
                    }
                }
                return [2 /*return*/, accessToken];
            });
        });
    };
    HttpInterceptorJwtAuth.prototype.getAuthUpdateConfigWithDefaults = function (config) {
        var _a, _b, _c;
        return {
            updateAuthToken: config.updateAuthToken,
            authTokenParseDidThrow: (_a = config.authTokenParseDidThrow) !== null && _a !== void 0 ? _a : (function () { }),
            accessTokenBlockingTimeoutBeforeExpiryInSeconds: (_b = config.accessTokenBlockingTimeoutBeforeExpiryInSeconds) !== null && _b !== void 0 ? _b : 10,
            accessTokenRefreshTimeoutBeforeExpiryInSeconds: (_c = config.accessTokenRefreshTimeoutBeforeExpiryInSeconds) !== null && _c !== void 0 ? _c : 300,
        };
    };
    return HttpInterceptorJwtAuth;
}());
export { HttpInterceptorJwtAuth };
