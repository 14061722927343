import React from 'react';
import { useDispatch } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { DocumentModal } from './DocumentModal';
import { PicturePreview } from './PicturePreview';
import closeImage from '../../../assets/close.svg';
import blackBackImage from '../../../assets/back-black.svg';
import { BaseField } from '../../BaseField';
import { setFormFieldValue } from '../../../redux/form/formActions';
import { DatePicker } from '../DatePicker';
import { fieldErrorsAdded } from '../../../redux/notification/notificationActions';
import { Button } from '../../Button';
import { NotificationsArea } from '../../NotificationsArea';

interface DocumentUploadVerificationProps {
  isOpen: boolean;
  submitting: boolean;
  fileUrl: string;
  step: DriverRegistrationPortalNoAuthService.Step;
  fields: DriverRegistrationPortalNoAuthService.Field[];
  field: DriverRegistrationPortalNoAuthService.Field;
  isExpiryRequired: boolean;
  expiryDate: string | null;
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  confirmHandler: (expiryDate?: string) => void;
  backHandler: () => void;
  closeHandler: () => void;
  expiryChangeHandler: (expiryDate: string | null) => void;
}

export const DocumentUploadVerification: React.FC<
  DocumentUploadVerificationProps
> = ({
  isOpen,
  fileUrl,
  step,
  fields,
  field,
  isExpiryRequired,
  expiryDate,
  commonTranslations,
  submitting,
  confirmHandler,
  backHandler,
  closeHandler,
  expiryChangeHandler,
}: DocumentUploadVerificationProps) => {
  const dispatch = useDispatch();

  const expireDateFieldName = `${field.name}_${field.document_type_key}_expire`;

  const submit = () => {
    if (isExpiryRequired) {
      const dateRegexp = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
      if (expiryDate && expiryDate.match(dateRegexp)) {
        confirmHandler(expiryDate);
      } else {
        dispatch(
          fieldErrorsAdded([
            {
              property: expireDateFieldName,
              error: commonTranslations.document_upload.required_label,
            },
          ])
        );
      }
    } else {
      confirmHandler();
    }
  };

  return (
    <DocumentModal isOpen={isOpen} onClose={closeHandler}>
      <div data-test="upload_verification" className="flex flex-col tablet:p-6">
        <button
          className="hidden tablet:inline tablet:self-end"
          type="button"
          onClick={closeHandler}
        >
          <img
            src={closeImage}
            data-test="close_button_image"
            alt="close dialog"
            className="object-contain"
          />
        </button>
        <div className="tablet:hidden">
          <button onClick={backHandler} type="button">
            <img
              src={blackBackImage}
              data-test="back_button_image"
              alt="close dialog"
              className="object-contain p-3"
            />
          </button>
        </div>
        <div className="block tablet:flex tablet:flex-row tablet:justify-between">
          <div className="hidden tablet:block tablet:w-[312px] tablet:h-[425px] mr-3 rtl:ml-3">
            <PicturePreview fileUrl={fileUrl} fullHeight />
          </div>
          <div className="px-4 tablet:px-0 tablet:flex tablet:flex-col tablet:w-[312px] tablet:h-[425px]">
            <h3
              data-test="title"
              className="text-xl font-bold text-center tablet:text-left tablet:rtl:text-right mb-2"
            >
              {
                commonTranslations.document_upload.verification
                  .document_details_title
              }
            </h3>
            <div
              data-test="description"
              className="text-gray-800 mb-4 text-center tablet:text-left tablet:rtl:text-right"
            >
              {
                commonTranslations.document_upload.verification
                  .document_details_description
              }
            </div>
            {fields &&
              fields.map((b) => {
                return (
                  <div className="mb-6" data-test={b.name} key={b.name}>
                    <BaseField
                      key={b.name}
                      fieldDefinition={b}
                      updateHandler={(v) => {
                        dispatch(setFormFieldValue(step, b, v));
                      }}
                      blurHandler={() => {
                        // Do nothing at the moment
                      }}
                    />
                  </div>
                );
              })}
            {isExpiryRequired && (
              <DatePicker
                title={commonTranslations.document_upload.expire_label}
                value={expiryDate}
                fieldName={expireDateFieldName}
                options={{
                  fromYearOffset: 0,
                  toYearOffset: 100,
                }}
                updateHandler={(v) => expiryChangeHandler(v || null)}
                blurHandler={() => {
                  // Do nothing at the moment
                }}
              />
            )}
          </div>
        </div>
        <div className="pt-5">
          <NotificationsArea />
        </div>
        <div className="flex justify-center">
          <Button
            onClick={submit}
            color={submitting ? 'bg-purple-200' : 'bg-purple-500'}
            loadingTextColor="text-purple-500"
            label={
              submitting
                ? ''
                : commonTranslations.document_upload.verification
                    .confirm_button_label
            }
            disabled={submitting}
            loading={submitting}
            id="document_upload"
          />
        </div>
      </div>
    </DocumentModal>
  );
};
