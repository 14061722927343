import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { getLoadingStatus } from '../redux/auth/authSelectors';
import { getRegistrationLoadingError } from '../redux/common/commonSelectors';
import { LoadingStatus } from '../redux/types';
import { initSignupFlow } from '../redux/auth/authThunk';
import { Loader } from './common/Loader';
import { LoginAndNewSignupLinks } from './common/LoginAndNewSignupLinks';
import { Signup } from './Signup';
import { FinalScreen } from './final_screen/FinalScreen';
import { setInitialLanguage } from '../redux/localization/localizationThunk';

export const SignupFormLayout: React.FC = () => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getLoadingStatus);
  const loadingErrorMessage = useSelector(getRegistrationLoadingError);

  const { path } = useRouteMatch();

  React.useEffect(() => {
    dispatch(setInitialLanguage());
    dispatch(initSignupFlow());
  }, [dispatch]);

  return (
    <>
      {loadingStatus === LoadingStatus.READY && (
        <Switch>
          <Route exact path={path}>
            <Signup />
          </Route>
          <Route path={`${path}/final`}>
            <FinalScreen />
          </Route>
        </Switch>
      )}
      {loadingStatus === LoadingStatus.LOADING && (
        <div className="clearfix">
          <div className="flex flex-row justify-center my-8 h-24">
            <Loader
              data-test="app_form_loader"
              textColor="text-purple-500"
              id="document_upload_example_loader"
            />
          </div>
        </div>
      )}
      {loadingStatus === LoadingStatus.FAILED && (
        <div>
          <div className="p-4 text-center">{loadingErrorMessage}</div>
          <LoginAndNewSignupLinks />
        </div>
      )}
    </>
  );
};
