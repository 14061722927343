export const GOOGLE_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
];

export const AWIN_PARAMS = ['sn', 'awc'];
