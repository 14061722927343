class GeoApiError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, GeoApiError.prototype);
    this.name = 'GeoApiError';
  }
}

export default GeoApiError;
