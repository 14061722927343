import React from 'react';

interface PicturePreviewProps {
  fileUrl: string | undefined;
  fullHeight: boolean;
}

export const PicturePreview: React.FC<PicturePreviewProps> = ({
  fileUrl,
  fullHeight,
}: PicturePreviewProps) => {
  const isPDF =
    fileUrl?.endsWith('.pdf') || fileUrl?.startsWith('data:application/pdf');
  const [pdfDocument, setPdfDocument] =
    React.useState<React.ReactElement | null>(null);

  if (isPDF) {
    if (pdfDocument) {
      return pdfDocument;
    }

    import('./PdfDocument').then(({ PdfDocument }) =>
      setPdfDocument(<PdfDocument fileUrl={fileUrl} fullHeight={fullHeight} />)
    );

    return null;
  }

  return (
    <img
      data-test="picture_preview"
      className={`object-contain w-full ${
        fullHeight ? 'h-full' : ''
      } tablet:border tablet:border-gray-300`}
      src={fileUrl}
      alt="document preview"
    />
  );
};
