import React from 'react';
import MarkdownWrapper from './MarkdownWrapper';

export default ({
  description: text,
}: {
  description: string | undefined;
}): React.ReactElement => (
  <div className="text-gray-700 text-left rtl:text-right text-sm mt-1">
    <MarkdownWrapper>{text ?? ''}</MarkdownWrapper>
  </div>
);
