import React, { ReactElement } from 'react';
import warningImage from '../../assets/warning.svg';

export interface ErrorBubbleProps {
  error: string;
}

export const ErrorBubble: React.FC<ErrorBubbleProps> = ({
  error,
}): ReactElement => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-yellow-100 rounded-lg w-80">
        <div className="flex flex-row items-center gap-3 p-4">
          <img
            src={warningImage}
            alt=""
            className="object-contain w-6"
            width={24}
            height={24}
          />
          <div className="text-sm color-gray-900">{error}</div>
        </div>
      </div>
    </div>
  );
};
