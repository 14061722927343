import { useEffect } from 'react';

export const useUnsavedChangesConfirmation = (condition = true): void => {
  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      if (condition) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign -- there's no other way than reassigning a parameter
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [condition]);
};
