import { HttpRequestAbortError } from './errors/HttpRequestAbortError';
import { HttpRequestError } from './errors/HttpRequestError';
import { HttpRequestNetworkError } from './errors/HttpRequestNetworkError';
import { HttpResponseBodyParseError } from './errors/HttpResponseBodyParseError';
import { HttpResponseError } from './errors/HttpResponseError';
export var HttpClientError = {
    RequestError: HttpRequestError,
    ResponseError: HttpResponseError,
    RequestAbortError: HttpRequestAbortError,
    RequestNetworkError: HttpRequestNetworkError,
    ResponseBodyParseError: HttpResponseBodyParseError,
};
