import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState, ThunkMiddlware } from '../types';
import { experimentParametersLoaded } from './experimentActions';

export const loadExperiment: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> =
  (eventKey: string, hash: string, language: string) =>
  async (dispatch: Dispatch<any>, _: () => IAppState, { registrationApi }) => {
    const parameters = await registrationApi.getExperimentParameters({
      event_key: eventKey,
      hash,
      language,
    });
    dispatch(experimentParametersLoaded(eventKey, parameters));
  };
