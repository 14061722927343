import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { getAutosaveStatus } from '../redux/autosave/autosaveSelectors';
import { isAutosaveEnabled } from '../redux/form/formSelectors';
import { AutosaveStatus } from '../redux/types';
import { useUnsavedChangesConfirmation } from '../hooks/useUnsavedChangesConfirmation';

const SHOW_SAVED_STATUS_AFTER_SUCCESS_TIME = 5 * 1000;

export const AutosaveFieldStatus: React.FC<AutosaveFieldStatusProps> = ({
  commonTranslations,
}): React.ReactElement | null => {
  const [showSavedStatusAfterSuccess, setShowSavedStatusAfterSuccess] =
    useState(false);
  const status = useSelector(getAutosaveStatus);
  const autosaveEnabled = useSelector(isAutosaveEnabled);
  const isSaving = status === AutosaveStatus.SAVING;
  const shouldHideStatus =
    !autosaveEnabled ||
    status === AutosaveStatus.FAILED ||
    status === AutosaveStatus.PENDING ||
    (status === AutosaveStatus.SUCCESSFUL && !showSavedStatusAfterSuccess);

  useUnsavedChangesConfirmation(isSaving || status === AutosaveStatus.PENDING);

  useEffect(() => {
    if (status === AutosaveStatus.SUCCESSFUL) {
      const timeoutId = setTimeout(() => {
        setShowSavedStatusAfterSuccess(false);
      }, SHOW_SAVED_STATUS_AFTER_SUCCESS_TIME);

      return () => clearTimeout(timeoutId);
    }
    // resets flag when we got other that SUCCESSFUL status to show saved status next time
    return setShowSavedStatusAfterSuccess(true);
  }, [status]);

  return shouldHideStatus ? null : (
    <div className="fixed top-2 right-2 z-10">
      <button
        disabled
        type="button"
        className="inline-flex items-center gap-1 py-2 px-3 rounded-full bg-gray-200 border-none shadow"
      >
        {isSaving ? (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 3.09167C4.43333 2.275 5.71667 1.75 7 1.75C9.91667 1.75 12.25 4.08333 12.25 7C12.25 7.35 12.0167 7.58333 11.6667 7.58333C11.3167 7.58333 11.0833 7.35 11.0833 7C11.0833 4.725 9.275 2.91667 7 2.91667C5.89167 2.91667 4.9 3.325 4.14167 4.08333H5.25C5.6 4.08333 5.83333 4.31667 5.83333 4.66667C5.83333 5.01667 5.6 5.25 5.25 5.25H2.91667C2.56667 5.25 2.33333 5.01667 2.33333 4.66667V2.33333C2.33333 1.98333 2.56667 1.75 2.91667 1.75C3.26667 1.75 3.5 1.98333 3.5 2.33333V3.09167Z"
              fill="#747682"
            />
            <path
              d="M8.75 8.75H11.0833C11.4333 8.75 11.6667 8.98333 11.6667 9.33333V11.6667C11.6667 12.0167 11.4333 12.25 11.0833 12.25C10.7333 12.25 10.5 12.0167 10.5 11.6667V10.9083C9.56667 11.725 8.28333 12.25 7 12.25C4.08333 12.25 1.75 9.91667 1.75 7C1.75 6.65 1.98333 6.41667 2.33333 6.41667C2.68333 6.41667 2.91667 6.65 2.91667 7C2.91667 9.275 4.725 11.0833 7 11.0833C8.10833 11.0833 9.1 10.675 9.85833 9.91667H8.75C8.4 9.91667 8.16667 9.68333 8.16667 9.33333C8.16667 8.98333 8.4 8.75 8.75 8.75Z"
              fill="#747682"
            />
          </svg>
        ) : (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1.16669C3.79167 1.16669 1.16667 3.79169 1.16667 7.00002C1.16667 10.2084 3.79167 12.8334 7 12.8334C10.2083 12.8334 12.8333 10.2084 12.8333 7.00002C12.8333 3.79169 10.2083 1.16669 7 1.16669ZM9.45001 6.00835L6.65 8.80835C6.41667 9.04169 6.06667 9.04169 5.83334 8.80835L4.55001 7.52502C4.31667 7.29169 4.31667 6.94169 4.55001 6.70835C4.78334 6.47502 5.13334 6.47502 5.36667 6.70835L6.24167 7.58335L8.63334 5.19169C8.86667 4.95835 9.21667 4.95835 9.45001 5.19169C9.68334 5.42502 9.68334 5.77502 9.45001 6.00835Z"
              fill="#747682"
            />
          </svg>
        )}
        <span className="text-gray-700 text-xs">
          {isSaving
            ? commonTranslations.autosave.saving
            : commonTranslations.autosave.saved}
        </span>
      </button>
    </div>
  );
};

interface AutosaveFieldStatusProps {
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
}
