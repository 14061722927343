import {
  HttpClientLibrary,
  DriverAuthService,
} from '@bolteu/bolt-server-api-partner-driver-web';
import { BrandEnum } from '@bolteu/bolt-server-api-partner-driver-web/types/latest/src/StandardRequest';
import { getAppBrand, getServerHost } from '../common/environment';
import { PORTAL_VERSION } from '../env';
import { store } from '../redux/store';
import { getLanguage } from '../redux/localization/localizationSelectors';
import { DeviceData } from '../common/deviceData';
import { PartnerDriverAuthStrategy } from './auth_strategies/PartnerDriverAuthStrategy';

const partnerDriverAuthClient =
  new HttpClientLibrary.HttpClientBoltServer<DriverAuthService.HttpClientConstraints>(
    {
      baseUrl: getServerHost(),
    }
  );

partnerDriverAuthClient.init({
  standardRequestStrategy: {
    getStandardRequest: () => {
      return {
        version: PORTAL_VERSION,
        brand: getAppBrand() as unknown as BrandEnum,
        device_os_version: DeviceData.getDeviceOsVersion(),
        device_name: DeviceData.getDeviceName(),
        deviceId: DeviceData.getDeviceId(),
        language: getLanguage(store.getState()),
      };
    },
  },
  authStrategy: {
    jwt: PartnerDriverAuthStrategy,
  },
});

const partnerDriverAuthApi = new DriverAuthService.ApiClient({
  httpClient: partnerDriverAuthClient,
});

export default partnerDriverAuthApi;
