const prefix = 'Bolt_driver_portal_';

export const refreshTokenKey = 'refresh_token';
export const DeviceIdKey = 'device_id';
export const LanguageKey = 'lang';
export const CountryKey = 'country';
export const AwinDiscountCode = 'awin_voucher_code';
export const ContinueInAppDisplayed = 'continue_in_app_displayed';
export const VisitorIdKey = 'visitor_id';

export const getItem = (key: string): string | null =>
  localStorage.getItem(`${prefix}${key}`);

export const setItem = (key: string, value: string): void =>
  localStorage.setItem(`${prefix}${key}`, value);

export const deleteItem = (key: string): void =>
  localStorage.removeItem(`${prefix}${key}`);
