import { MixpanelState } from '../types';
import { ActionType, MixpanelLoaded } from './mixpanelActions';
import { createReducer } from '../helper';

const initialState: MixpanelState = {
  instance: undefined,
};

const mixpanelLoaded = (
  _: MixpanelState,
  action: MixpanelLoaded
): MixpanelState => ({
  instance: action.instance,
});

const handlers = {
  [ActionType.MIXPANEL_LOADED]: mixpanelLoaded,
};

export const mixpanelReducer = createReducer<MixpanelState>(
  initialState,
  handlers
);
