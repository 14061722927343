import React, { useCallback } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useDispatch, useSelector } from 'react-redux';
import { commonErrorAdded } from '../../../redux/notification/notificationActions';
import { getTranslations } from '../../../redux/form/formSelectors';
import { ReCaptchaV2 } from './ReCaptchaV2';
import { ReCaptchaV3 } from './ReCaptchaV3';
import { getLanguage } from '../../../redux/localization/localizationSelectors';

export type RecaptchaWrapperProps = {
  siteKey: string;
  captchaType: DriverRegistrationPortalNoAuthService.CaptchaChallengeType;
  onCaptchaSubmit: (captchaToken: string) => void;
};

export const RecaptchaWrapper: React.FC<RecaptchaWrapperProps> = ({
  siteKey,
  captchaType,
  onCaptchaSubmit,
}: RecaptchaWrapperProps) => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);

  const unknownErrorTranslation =
    useSelector(getTranslations).common_unknown_error;

  const onSubmitInternal = useCallback(
    (captchaToken?: string | null) => {
      if (!captchaToken) {
        dispatch(
          commonErrorAdded({
            error: unknownErrorTranslation ?? 'Unknown error',
          })
        );
      } else {
        onCaptchaSubmit(captchaToken);
      }
    },
    [dispatch, onCaptchaSubmit, unknownErrorTranslation]
  );

  switch (captchaType) {
    case DriverRegistrationPortalNoAuthService.CaptchaChallengeType.WEBVIEW:
      return (
        <ReCaptchaV2
          siteKey={siteKey}
          onCaptchaSubmit={onSubmitInternal}
          language={language}
        />
      );
    case DriverRegistrationPortalNoAuthService.CaptchaChallengeType
      .RECAPTCHA_NATIVE: {
      return <ReCaptchaV3 onCaptchaSubmit={onSubmitInternal} />;
    }
    default: {
      dispatch(
        commonErrorAdded({ error: unknownErrorTranslation ?? 'Unknown error' })
      );
    }
  }

  return <></>;
};

export const MemoizedRecaptchaWrapper = React.memo(RecaptchaWrapper);
