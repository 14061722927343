import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import {
  ActionType,
  FormLoaded,
  SetCaptureModeLoaded,
  SetDocumentStatuses,
  SetFormFieldValue,
  SetFormStep,
  SetHash,
  SetRegistrationStatus,
} from './formActions';
import { createReducer } from '../helper';
import { FormState } from '../types';
import { getDefaultValueFromCookies } from '../../common/helpers';
import {
  adjustFieldForEnhancedCapture,
  includeFieldWhenUsingEnhancedCapture,
} from '../../common/enhancedCaptureHelper';
import DocumentResultStatus = DriverRegistrationPortalNoAuthService.DocumentResultStatus;
import {
  calculateOrdinalForDocument,
  isDocumentUploadField,
} from './formHelpers';

const initialState: FormState = {
  steps: [],
  current_step:
    DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep.STEP1,
  common_translations: {} as any,
  uploading: false,
  loading: true,
  language: 'en',
  languages: [],
  driver_registration_log_status:
    DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
      .IN_PROGRESS,
  decline_reasons: [],
  hash: '',
  flow_id: 0,
  country: 'gb',
  use_enhanced_capture: false,
  enhanced_capture_document_keys: [],
  is_document_statuses_polling_enabled: false,
  features: {
    autosave: {
      enabled: false,
    },
    banners: {
      items: [],
    },
    vehicle_marketplace: {
      enabled: false,
    },
  },
};

const formUploading = (state: FormState) => ({
  ...state,
  uploading: true,
});

const formUploaded = (state: FormState) => ({
  ...state,
  uploading: false,
});

function createVirtualFlowWithOneStepForDocumentResubmissionPage(
  steps: DriverRegistrationPortalNoAuthService.Step[]
) {
  return [
    {
      id: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep
        .STEP1,
      fields: ([] as DriverRegistrationPortalNoAuthService.Field[]).concat(
        ...steps.map((x) => x.fields)
      ),
      translations: steps[0].translations,
    },
  ];
}

const formLoaded = (state: FormState, action: FormLoaded) => {
  const usingEnhancedCaptureMode = state.use_enhanced_capture;
  const oldSteps = state.steps;
  let newSteps = action.form.steps;
  if (
    action.form.driver_registration_log_status ===
    DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
      .DOCUMENTS_NEED_RESUBMISSION
  ) {
    newSteps =
      createVirtualFlowWithOneStepForDocumentResubmissionPage(newSteps);
  } else {
    newSteps = newSteps.sort((x, y) => (x.id > y.id ? 1 : -1));
  }

  for (const step of newSteps) {
    const existingStep = oldSteps.find((v) => v.id === step.id);
    if (usingEnhancedCaptureMode) {
      step.fields = step.fields.filter((field) =>
        includeFieldWhenUsingEnhancedCapture(field.document_type_key)
      );
      step.fields = step.fields.map((field) => {
        return adjustFieldForEnhancedCapture(field);
      });
    }
    for (const field of step.fields) {
      if (field.current_value === null) {
        if (field.default_value !== null && field.default_value !== undefined) {
          field.current_value = field.default_value;
        }

        const defaultValueFromCookies = getDefaultValueFromCookies(field.name);
        if (defaultValueFromCookies !== null) {
          field.current_value = defaultValueFromCookies;
        }

        if (existingStep) {
          const oldField = existingStep.fields.find(
            (v) => v.name === field.name
          );
          if (oldField?.current_value != null) {
            field.current_value = oldField.current_value;
          }
        }
      }
    }
  }

  return {
    ...state,
    ...action.form,
    steps: newSteps,
    uploading: false,
    loading: false,
    decline_reasons: undefined,
  };
};

const setStep = (state: FormState, action: SetFormStep) => ({
  ...state,
  current_step: action.step,
});

const setFieldValue = (state: FormState, action: SetFormFieldValue) => {
  const fieldSelector = (
    field: DriverRegistrationPortalNoAuthService.Field
  ): boolean => {
    if (action.field.document_type_key) {
      return action.field.document_type_key === field.document_type_key;
    }
    if (action.field.generic_field_key) {
      return action.field.generic_field_key === field.generic_field_key;
    }
    return action.field.name === field.name;
  };

  return {
    ...state,
    steps: state.steps.map((s) =>
      s.id === action.step.id
        ? {
            ...s,
            fields: s.fields.map((f) =>
              fieldSelector(f) ? { ...f, current_value: action.value } : f
            ),
          }
        : s
    ),
  };
};

const setHash = (state: FormState, action: SetHash) => ({
  ...state,
  hash: action.hash,
});

const setRegistrationStatus = (
  state: FormState,
  action: SetRegistrationStatus
) => ({
  ...state,
  driver_registration_log_status: action.status,
  decline_reasons: action.decline_reasons,
});

const setDocumentStatuses = (state: FormState, action: SetDocumentStatuses) => {
  return {
    ...state,
    steps: state.steps.map((s) => {
      return {
        ...s,
        fields: s.fields
          .map((f) => {
            if (isDocumentUploadField(f)) {
              const document = action.documents.find(
                (st) => st.document_type === f.document_type_key
              );
              if (document) {
                if (
                  document.status === DocumentResultStatus.NOT_UPLOADED &&
                  f.current_value.document_id
                ) {
                  // The document has been captured through the regular capture mode rather than the enhanced capture mode, though a flow_run exists
                  return {
                    ...f,
                    document_status: DocumentResultStatus.IN_REVIEW,
                  };
                }
                return {
                  ...f,
                  document_status: document.status,
                  document_error: document.error,
                };
              }
              if (f.current_value.document_id) {
                // The document has been captured through the regular capture mode rather than the enhanced capture mode, no flow_run exists
                return {
                  ...f,
                  document_status: DocumentResultStatus.IN_REVIEW,
                };
              }
            }
            return f;
          })
          .sort((f1, f2) => {
            return (
              calculateOrdinalForDocument(f1) - calculateOrdinalForDocument(f2)
            );
          }),
      };
    }),
  };
};

const startDocumentStatusesPolling = (state: FormState) => ({
  ...state,
  is_document_statuses_polling_enabled: true,
});

const stopDocumentStatusesPolling = (state: FormState) => ({
  ...state,
  is_document_statuses_polling_enabled: false,
});

const captureModeLoaded = (state: FormState, action: SetCaptureModeLoaded) => ({
  ...state,
  use_enhanced_capture: action.captureMode.use_enhanced_capture,
  enhanced_capture_document_keys: action.captureMode.document_type_keys,
});

const handlers = {
  [ActionType.SET_STEP]: setStep,
  [ActionType.SET_FIELD_VALUE]: setFieldValue,
  [ActionType.FORM_LOADED]: formLoaded,
  [ActionType.FORM_UPLOADING]: formUploading,
  [ActionType.FORM_UPLOADED]: formUploaded,
  [ActionType.SET_HASH]: setHash,
  [ActionType.SET_REGISTRATION_STATUS]: setRegistrationStatus,
  [ActionType.SET_DOCUMENT_STATUSES]: setDocumentStatuses,
  [ActionType.START_DOCUMENT_STATUSES_POLLING]: startDocumentStatusesPolling,
  [ActionType.STOP_DOCUMENT_STATUSES_POLLING]: stopDocumentStatusesPolling,
  [ActionType.CAPTURE_MODE_LOADED]: captureModeLoaded,
};

export const formReducer = createReducer<FormState>(initialState, handlers);
