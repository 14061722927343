//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
export type StandardRequestConstraint = Record<string, any>;
export type RequestOptionsConstraint = Record<string, any>;
export type GeneratedHttpClientConstraint<TConstraint> = [
    TConstraint
] extends [
    never
] ? void : TConstraint;
export interface GeneratedHttpClientConstraintsSecrets<THttpClientConstraints extends GeneratedHttpClientConstraints> {
    readonly __secretStandardRequestConstraint: GeneratedHttpClientConstraint<THttpClientConstraints["StandardRequest"]>;
    readonly __secretAuthStrategyConstraint: GeneratedHttpClientConstraint<THttpClientConstraints["Auth"]>;
    readonly __secretShardingStrategyConstraint: GeneratedHttpClientConstraint<THttpClientConstraints["Sharding"]>;
}
export interface GeneratedHttpClientConstraints {
    Auth?: AuthStrategy;
    Sharding?: ShardingStrategy;
    StandardRequest?: StandardRequestConstraint;
}
export interface GeneratedHttpClient<THttpClientConstraints extends GeneratedHttpClientConstraints = never, TRequestOptions extends RequestOptionsConstraint = never> extends GeneratedHttpClientConstraintsSecrets<THttpClientConstraints> {
    sendRequest<TResponseData>(request: GeneratedHttpClientRequest, opts?: TRequestOptions): Promise<TResponseData>;
    sendRequestRaw<TResponseData>(request: GeneratedHttpClientRequest, opts?: TRequestOptions): Promise<GeneratedHttpClientResponse<TResponseData>>;
}
export interface GeneratedHttpClientRequest {
    path: string;
    subdomain?: string;
    service_id?: string;
    http_method: HttpMethod;
    meta?: HttpRequestMeta;
    query?: Record<string, any>;
    body?: Record<string, any>;
    files?: Record<string, any>;
}
export interface GeneratedHttpClientResponse<T> {
    status: number;
    headers: Headers;
    body: T;
}
export interface HttpRequestMeta {
    auth: {
        enabled: boolean;
        strategy?: AuthStrategy;
    };
    sharding: {
        enabled: boolean;
        strategy?: ShardingStrategy;
    };
    virtualPaths: {
        enabled: boolean;
    };
}
export enum HttpMethod {
    GET = "GET",
    POST = "POST"
}
export enum AuthStrategy {
    ANY = "ANY",
    JWT = "JWT",
    BASIC = "BASIC",
    NONE = "NONE",
    MULTI = "MULTI",
    UNKNOWN = "UNKNOWN",
    CUSTOM = "CUSTOM"
}
export enum ShardingStrategy {
    ANY = "ANY",
    URL = "URL",
    HEADER = "HEADER",
    NONE = "NONE"
}
