import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DriverRegistrationEventLogNoAuthService } from '@bolteu/bolt-server-api-event-log';
import { getHash } from '../form/formSelectors';
import { IAppState, ThunkMiddlware } from '../types';
import { UiEvent, UiPage } from '../../common/events';

export const trackEvent: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> =
  (page: UiPage, event: UiEvent) =>
  async (_: Dispatch<any>, getState: () => IAppState, { eventLogApi }) => {
    const state = getState();
    try {
      await eventLogApi.track(
        {},
        {
          user_id: getHash(state),
          user_type:
            DriverRegistrationEventLogNoAuthService.EventUserType
              .DRIVER_REGISTRATION,
          page,
          event,
          event_timestamp_ms: new Date().getTime(),
        }
      );
    } catch (ingnored) {
      // ignored
    }
  };
