import { getGeoServerHost } from '../common/environment';
import GeoApiError from './errors/GeoApiError';

interface GeoApiResponse {
  code: number;
  data: {
    city: string;
    country: string;
    country_iso_code: string;
    location: {
      lat: number;
      lng: number;
    };
  };
  message: string;
}

const RESPONSE_OK = 0;

export class GeoApi {
  protected apiFetch = async (
    endpoint: string,
    method: string
  ): Promise<GeoApiResponse> => {
    const url = new URL(getGeoServerHost() + endpoint);

    const response = await fetch(url.toString(), {
      method,
      credentials: 'same-origin',
    });

    if (!response.ok) {
      throw new GeoApiError();
    }

    const json: GeoApiResponse = await response.json();

    if (!GeoApi.isResponseSuccess(json)) {
      throw new GeoApiError();
    }

    return json;
  };

  public async getIpLocation(): Promise<GeoApiResponse> {
    return this.apiFetch('/ipLocation', 'GET');
  }

  private static isResponseSuccess(json: GeoApiResponse): boolean {
    return json.code === RESPONSE_OK;
  }
}

const geoApiClient = new GeoApi();

export default geoApiClient;
