import { ActionType, ExperimentParametersLoaded } from './experimentActions';
import { createReducer } from '../helper';
import { ExperimentsState } from '../types';

const initialState: ExperimentsState = {};

const experimentLoaded = (
  state: ExperimentsState,
  action: ExperimentParametersLoaded
) => ({
  ...state,
  [action.eventKey]: action.parameters,
});

const handlers = {
  [ActionType.EXPERIMENT_PARAMETERS_LOADED]: experimentLoaded,
};

export const experimentReducer = createReducer<ExperimentsState>(
  initialState,
  handlers
);
