import {
  HttpClientLibrary,
  DriverRegistrationPortalNoAuthService,
} from '@bolteu/bolt-server-api-driver-registration';
import { BrandEnum } from '@bolteu/bolt-server-api-driver-registration/types/latest/src/StandardRequest';
import { getAppBrand, getServerHost } from '../common/environment';

const driverRegistrationHttpClient =
  new HttpClientLibrary.HttpClientBoltServer<DriverRegistrationPortalNoAuthService.HttpClientConstraints>(
    {
      baseUrl: getServerHost(),
    }
  );

driverRegistrationHttpClient.init({
  standardRequestStrategy: {
    getStandardRequest: () => {
      return {
        brand: getAppBrand() as unknown as BrandEnum,
      };
    },
  },
});

const driverRegistrationApiClient =
  new DriverRegistrationPortalNoAuthService.ApiClient({
    httpClient: driverRegistrationHttpClient,
  });

export default driverRegistrationApiClient;
