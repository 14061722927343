import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors } from '../../redux/notification/notificationActions';
import { getTranslations } from '../../redux/form/formSelectors';

export type OtpInputProps = {
  value: string;
  length: number;
  errorMessage?: string | null;
  forceResend: boolean;
  onChange: (value: string) => void;
};

export const DIGIT_REGEXP = new RegExp(/^\d+$/);

export const OtpInput: React.FC<OtpInputProps> = ({
  value,
  length,
  errorMessage,
  forceResend,
  onChange,
}: OtpInputProps) => {
  const dispatch = useDispatch();
  const isError = useMemo(() => !!errorMessage, [errorMessage]);
  const commonTranslations = useSelector(getTranslations);

  const inputRef: React.RefObject<HTMLInputElement> =
    React.createRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  useEffect(() => {
    if ('OTPCredential' in window && 'AbortController' in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            const receivedCode = otp.code;
            if (errorMessage || receivedCode.length === length) {
              dispatch(clearErrors());
            }
            onChange(receivedCode);
          }
        })
        .catch(() => {
          // ignore
        });
      return () => {
        ac.abort();
      };
    }
    return () => {
      // empty
    };
  }, [dispatch, length, errorMessage, onChange]);

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const targetValue = target.value.trim();
    const isTargetValueDigit = DIGIT_REGEXP.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    if (errorMessage || value.length === length) {
      dispatch(clearErrors());
    }

    onChange(targetValue);
  };

  const borderAndBgStyle = isError
    ? 'border-red-500 bg-white focus:bg-white focus:border-red-500'
    : 'border-gray-200 bg-gray-200 focus:bg-white focus:border-purple-500';

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-start p-0">
        <input
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          maxLength={length}
          value={value}
          onChange={(e) => inputOnChange(e)}
          ref={inputRef}
          className={`outline-none py-3 border-2 rounded-lg otp-input h-14 text-2xl text-center font-semibold ${borderAndBgStyle} tracking-widest disabled:bg-gray-200 disabled:cursor-not-allowed w-40`}
          style={{
            paddingLeft: value.length ? '1.25rem' : 0,
          }}
          disabled={forceResend}
        />
      </div>
      {isError && (
        <div className="text-red-700 text-sm text-center mt-1">
          {`${errorMessage}. ${
            forceResend
              ? commonTranslations.verification.resend_to_try_again
              : ''
          }`}
        </div>
      )}
    </div>
  );
};

export const MemoizedOtpInput = React.memo(OtpInput);
