import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { IAppState, LanguageLoadingStatus } from '../types';

export const getLanguage = (state: IAppState): string =>
  state.localization.language;

export const getScriptDirection = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.ScriptDirection =>
  state.localization.script_direction;

export const getLanguages = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.FlowLanguage[] =>
  state.localization.languages;

export const getCountry = (state: IAppState): string | undefined =>
  state.localization.country;

export const getIsCountryFinal = (state: IAppState): boolean =>
  state.localization.is_country_final;

export const getLanguageLoadingStatus = (
  state: IAppState
): LanguageLoadingStatus => state.localization.loading_status;

export const getCodeMissingTranslation = (state: IAppState): string =>
  state.localization.common_translations.verification.code_missing;
