import { AuthState, LoadingStatus } from '../types';
import {
  ActionType,
  SetActualRegistrationInfo,
  SetAuthMagicToken,
  SetAuthTokens,
  SetIsRedirecting,
  SetLoadingStatus,
} from './authActions';
import { createReducer } from '../helper';

const initialState: AuthState = {
  loadingStatus: LoadingStatus.LOADING,
  refreshToken: null,
  accessToken: null,
  actualRegistrationInfo: null,
  isRedirecting: false,
  magicToken: null,
};

const setAuthTokens = (state: AuthState, action: SetAuthTokens): AuthState => ({
  ...state,
  refreshToken: action.refreshToken,
  accessToken: action.accessToken,
});

const setAuthMagicToken = (
  state: AuthState,
  action: SetAuthMagicToken
): AuthState => ({
  ...state,
  magicToken: action.value,
});

const setLoadingStatus = (
  state: AuthState,
  action: SetLoadingStatus
): AuthState => ({
  ...state,
  loadingStatus: action.value,
});

const setActualRegistrationInfo = (
  state: AuthState,
  action: SetActualRegistrationInfo
): AuthState => ({
  ...state,
  actualRegistrationInfo: {
    hash: action.hash,
    status: action.status,
    statusErrorMessage: 'Failed to load registration data.',
  },
});

const setIsRedirecting = (
  state: AuthState,
  action: SetIsRedirecting
): AuthState => ({
  ...state,
  isRedirecting: action.value,
});

const handlers = {
  [ActionType.SET_AUTH_TOKENS]: setAuthTokens,
  [ActionType.SET_LOADING_STATUS]: setLoadingStatus,
  [ActionType.SET_IS_REDIRECTING]: setIsRedirecting,
  [ActionType.SET_ACTUAL_REGISTRATION_INFO]: setActualRegistrationInfo,
  [ActionType.SET_AUTH_MAGIC_TOKEN]: setAuthMagicToken,
};

export const authReducer = createReducer<AuthState>(initialState, handlers);
