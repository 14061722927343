class NoResponseError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, NoResponseError.prototype);
    this.name = 'NoResponseError';
  }
}

export default NoResponseError;
