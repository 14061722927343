import * as React from 'react';
import { BaseProps } from './BaseInput';
import MarkdownWrapper from '../common/MarkdownWrapper';

export const Label: React.FC<BaseProps> = ({ title }: BaseProps) => {
  return (
    <div className="w-full">
      <MarkdownWrapper>{title}</MarkdownWrapper>
    </div>
  );
};
