import * as React from 'react';
import { useDispatch } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { isFormUploading } from '../redux/form/formSelectors';
import { useSelector } from '../redux/store';
import { postForm } from '../redux/form/formThunk';
import { Button } from './Button';

interface NextButtonProps {
  step: DriverRegistrationPortalNoAuthService.Step;
  text: string;
}

export const NextButton: React.FC<NextButtonProps> = ({
  step,
  text,
}: NextButtonProps) => {
  const inputId = 'next';

  const dispatch = useDispatch();
  const loading = useSelector(isFormUploading);
  return (
    <Button
      onClick={() => dispatch(postForm(step))}
      color={loading ? 'bg-purple-200' : 'bg-purple-500'}
      loadingTextColor="text-purple-500"
      label={loading ? '' : text}
      disabled={loading}
      loading={loading}
      id={inputId}
    />
  );
};
