import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState, ThunkMiddlware } from '../types';
import { clearErrors } from '../notification/notificationActions';
import {
  getLanguageFromUrl,
  getQueryParam,
  getSignupLightweightLandingLink,
  getWebRequestParams,
  saveHashToCookies,
  getVisitorTrackingData,
  getAppVersion,
} from '../../common/helpers';
import { getLanguage, getCountry } from '../localization/localizationSelectors';
import {
  formLoaded,
  formLoading,
  formUploading,
  formUploaded,
} from './riderUserSignupActions';
import driverRegistrationApiClient from '../../api/DriverRegistrationApi';
import eventLogApiClient from '../../api/EventLogApi';
import { getCurrentCity } from './riderUserSignupSelectors';
import {
  handleFieldValidationErrors,
  handleErrorResponse,
} from '../../api/ApiErrorsHandler';
import { getFirstCommonError } from '../notification/notificationsSelectors';
import { setCreateAccountResult } from '../verification/verificationActions';
import { redirectAfterAccountCreated } from '../verification/verificationHelpers';

export const loadSignupForm: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  dispatch(clearErrors());
  dispatch(formLoading());

  const state = getState();
  const languageCode = getLanguage(state);
  const countryCode = getCountry(state);
  const signupLightweightLandingLink = getSignupLightweightLandingLink(
    languageCode,
    countryCode
  );
  const language = getLanguageFromUrl() ?? languageCode;
  const riderUserId = getQueryParam('rider_user_id');

  if (!riderUserId) {
    window.location.replace(signupLightweightLandingLink);
    return;
  }

  try {
    const response = await driverRegistrationApiClient.getFormDataForRiderUser({
      ...getWebRequestParams(language),
      rider_user_uuid: riderUserId,
    });
    dispatch(formLoaded(response));
  } catch (e) {
    // rider not found or api issues
    handleErrorResponse(e, dispatch);
    window.location.replace(signupLightweightLandingLink);
    return;
  }

  const visitorTrackingData = getVisitorTrackingData();

  if (visitorTrackingData.visitor_id) {
    await eventLogApiClient
      .visitorTrack(
        {
          version: getAppVersion() ?? undefined,
        },
        {
          visitor_id: visitorTrackingData.visitor_id,
          url: window.location.href,
          event_timestamp_ms: new Date().getTime(),
          user_agent: navigator.userAgent,
          country_code: getCountry(state),
          language,
        }
      )
      .catch(() => {
        // ignore
      });
  }
};

export const startRegistration: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  dispatch(clearErrors());
  dispatch(formUploading());

  const state = getState();
  const languageCode = getLanguage(state);
  const countryCode = getCountry(state);
  const signupLightweightLandingLink = getSignupLightweightLandingLink(
    languageCode,
    countryCode
  );
  const language = getLanguageFromUrl() ?? languageCode;
  const city = getCurrentCity(state);
  const riderUserId = getQueryParam('rider_user_id');

  if (!riderUserId) {
    window.location.replace(signupLightweightLandingLink);
    return;
  }

  dispatch(setCreateAccountResult(null));

  try {
    const response =
      await driverRegistrationApiClient.startRegistrationForRiderUser(
        {
          ...getWebRequestParams(language),
          rider_user_uuid: riderUserId,
        },
        {
          city_id: city || undefined,
        }
      );
    saveHashToCookies(response.registration_hash);
    dispatch(setCreateAccountResult(response));
    redirectAfterAccountCreated(dispatch, getState);
  } catch (e) {
    dispatch(formUploaded());
    handleFieldValidationErrors(e, dispatch);
    // don't handle resolving of any errors (rider use not found, existing registrations conflicts)
    if (getFirstCommonError(getState())) {
      window.location.replace(signupLightweightLandingLink);
    }
  }
};
