import mixpanelBrowser from 'mixpanel-browser';
import {
  Environment,
  StringPerEnvironment,
  getCurrentEnvironment,
} from './environment';
import {
  MixpanelEventData,
  MixpanelEventName,
  MixpanelUiEvents,
} from './events';

const MIXPANEL_TOKEN: StringPerEnvironment = {
  [Environment.LOCAL]: 'SECRET',
  [Environment.PRELIVE]: '1c698ab74af84b56dcd0de5d36187d5b',
  [Environment.PRELIVEHOPP]: '1c698ab74af84b56dcd0de5d36187d5b',
  [Environment.LIVEHOPP]: '16b2ba60f4d49b1f69fcf78aea1521a3',
  [Environment.LIVE]: '16b2ba60f4d49b1f69fcf78aea1521a3',
};

const mockFunction = () => {
  // nothing
};

const mixpanelMock = {
  init: mockFunction,
  reset: mockFunction,
  identify: mockFunction,
  track: mockFunction,
};

export enum MixpanelMode {
  NORMAL,
  DEBUG,
  MOCK,
}

export interface MixPanelInstance {
  init(token: string, options: { debug: boolean }): void;
  reset(): void;
  identify(id: string): void;
  track(
    eventName: MixpanelEventName,
    eventData?: MixpanelEventData,
    callback?: () => void
  ): void;
}

export class Mixpanel {
  static getToken(): string {
    return MIXPANEL_TOKEN[getCurrentEnvironment()];
  }

  static getUserId(hash: string): string {
    return `lead-driver-${hash}`;
  }

  constructor(
    private readonly hash: string,
    private readonly country: string | undefined,
    private readonly city: string | undefined,
    private readonly mode: MixpanelMode = MixpanelMode.NORMAL,
    private currentPageName?: string
  ) {
    this.initMixpanelWithToken(Mixpanel.getToken());
  }

  private getMixpanel(): MixPanelInstance {
    return this.mode === MixpanelMode.MOCK ? mixpanelMock : mixpanelBrowser;
  }

  private initMixpanelWithToken(token: string): void {
    this.getMixpanel().init(token, { debug: this.mode === MixpanelMode.DEBUG });
  }

  public reset(): void {
    this.getMixpanel().reset();
  }

  public identify(): void {
    this.getMixpanel().identify(Mixpanel.getUserId(this.hash));
  }

  public track(
    eventName: MixpanelEventName,
    eventData?: MixpanelEventData,
    callback?: () => void
  ): void {
    this.getMixpanel().track(
      eventName,
      {
        country: this.country ?? '',
        city: this.city ?? '',
        pageName: this.currentPageName ?? '',
        ...eventData,
      },
      callback
    );
  }

  public trackPageLoaded(
    pageName: MixpanelEventName,
    eventData?: MixpanelEventData
  ): void {
    this.currentPageName = pageName;
    this.track(pageName, eventData);
  }

  public trackLinkClicked(
    url: string,
    callback: () => void = mockFunction
  ): void {
    this.track(
      MixpanelUiEvents.LINK_CLICKED,
      {
        targetUrl: url,
      },
      callback
    );
  }
}
