import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { LanguageLoadingStatus, LocalizationState } from '../types';
import { createReducer } from '../helper';
import {
  ActionType,
  SetCommonTranslations,
  SetCountry,
  SetIsCountryFinal,
  SetLanguage,
  SetLanguages,
  SetLoadingStatus,
} from './localizationActions';
import { getMockCommonTranslations } from '../../api/Mocks';

const initialState: LocalizationState = {
  country: 'gb',
  is_country_final: false,
  language: 'en',
  languages: [],
  script_direction: DriverRegistrationPortalNoAuthService.ScriptDirection.LTR,
  common_translations: getMockCommonTranslations(),
  loading_status: LanguageLoadingStatus.NOT_STARTED,
};

const setLanguage = (state: LocalizationState, action: SetLanguage) => {
  const lang = state.languages.find((v) => v.key === action.language);
  return {
    ...state,
    language: action.language,
    script_direction: lang
      ? lang.direction
      : DriverRegistrationPortalNoAuthService.ScriptDirection.LTR,
  };
};

const setLanguages = (state: LocalizationState, action: SetLanguages) => {
  return {
    ...state,
    languages: action.languages,
  };
};

const setCommonTranslations = (
  state: LocalizationState,
  action: SetCommonTranslations
) => {
  return {
    ...state,
    common_translations: action.common_translations,
  };
};

const setCountry = (state: LocalizationState, action: SetCountry) => ({
  ...state,
  country: action.country,
});

const setIsCountryFinal = (
  state: LocalizationState,
  action: SetIsCountryFinal
) => ({
  ...state,
  is_country_final: action.is_country_final,
});

const setLoadingStatus = (
  state: LocalizationState,
  action: SetLoadingStatus
) => ({
  ...state,
  loading_status: action.loading_status,
});

const handlers = {
  [ActionType.SET_COUNTRY]: setCountry,
  [ActionType.SET_IS_COUNTRY_FINAL]: setIsCountryFinal,
  [ActionType.SET_LANGUAGE]: setLanguage,
  [ActionType.SET_LANGUAGES]: setLanguages,
  [ActionType.SET_COMMON_TRANSLATIONS]: setCommonTranslations,
  [ActionType.SET_LOADING_STATUS]: setLoadingStatus,
};

export const localizationReducer = createReducer<LocalizationState>(
  initialState,
  handlers
);
