import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { BaseField } from './BaseField';
import { DocumentUploadWrapper } from './input/DocumentUploadWrapper';

export interface FieldProps {
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  fieldDefinition: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (data: {
    changed: DriverRegistrationPortalNoAuthService.Field['current_value'];
    autosaveEnabled: boolean;
  }) => void;
  blurHandler: (
    event: React.FocusEvent<HTMLInputElement, Element>,
    autosave: { enabled: boolean; always?: boolean }
  ) => void;
  hash: string;
  step: DriverRegistrationPortalNoAuthService.Step;
}

export const Field: React.FC<FieldProps> = ({
  commonTranslations,
  fieldDefinition,
  updateHandler,
  blurHandler,
  hash,
  step,
}: FieldProps): React.ReactElement | null => {
  switch (fieldDefinition.type) {
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .DOCUMENT_UPLOAD:
      return (
        <DocumentUploadWrapper
          commonTranslations={commonTranslations}
          fieldDefinition={fieldDefinition}
          step={step}
          updateHandler={(v) =>
            updateHandler({ changed: v, autosaveEnabled: false })
          }
          hash={hash}
        />
      );
    default:
      return (
        <div className="mb-6">
          <BaseField
            fieldDefinition={fieldDefinition}
            updateHandler={updateHandler}
            blurHandler={blurHandler}
          />
        </div>
      );
  }
};
