var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var CLASS_NAME = 'HttpRequestError';
var HttpRequestError = /** @class */ (function (_super) {
    __extends(HttpRequestError, _super);
    function HttpRequestError(msg, url, rawResponse) {
        var _this = _super.call(this, msg + ": " + url + (rawResponse ? " Response: " + rawResponse : '')) || this;
        _this.url = url;
        _this.rawResponse = rawResponse;
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, HttpRequestError.prototype);
        _this.name = CLASS_NAME;
        return _this;
    }
    HttpRequestError.prototype.getClassName = function () {
        return CLASS_NAME;
    };
    return HttpRequestError;
}(Error));
export { HttpRequestError };
