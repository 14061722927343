export var HttpResponseCodeBoltServer;
(function (HttpResponseCodeBoltServer) {
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["OK"] = 0] = "OK";
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["INVALID_REQUEST"] = 702] = "INVALID_REQUEST";
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["NOT_AUTHORIZED"] = 503] = "NOT_AUTHORIZED";
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["REFRESH_TOKEN_INVALID"] = 210] = "REFRESH_TOKEN_INVALID";
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["TOO_MANY_REQUESTS"] = 1005] = "TOO_MANY_REQUESTS";
    HttpResponseCodeBoltServer[HttpResponseCodeBoltServer["NOT_ENOUGH_PERMISSIONS"] = 1006] = "NOT_ENOUGH_PERMISSIONS";
})(HttpResponseCodeBoltServer || (HttpResponseCodeBoltServer = {}));
var HttpResponseUtilsBoltServer = /** @class */ (function () {
    function HttpResponseUtilsBoltServer() {
    }
    HttpResponseUtilsBoltServer.isNotOk = function (response) {
        return response.body.code !== HttpResponseCodeBoltServer.OK;
    };
    HttpResponseUtilsBoltServer.isOk = function (response) {
        return response.body.code === HttpResponseCodeBoltServer.OK;
    };
    HttpResponseUtilsBoltServer.isJwtTokenExpiredError = function (response) {
        return (response.body.code === HttpResponseCodeBoltServer.NOT_AUTHORIZED &&
            'error_data' in response.body &&
            !!response.body.error_data &&
            'expiredAt' in response.body.error_data);
    };
    HttpResponseUtilsBoltServer.isValidationError = function (response) {
        return (response.body.code === HttpResponseCodeBoltServer.INVALID_REQUEST &&
            'validation_errors' in response.body);
    };
    HttpResponseUtilsBoltServer.isMissingPermissionsError = function (response) {
        return (response.body.code === HttpResponseCodeBoltServer.NOT_ENOUGH_PERMISSIONS &&
            'error_data' in response.body &&
            !!response.body.error_data &&
            'permissions' in response.body.error_data &&
            'type' in response.body.error_data);
    };
    HttpResponseUtilsBoltServer.isLegacyMissingPermissionsError = function (response) {
        return (response.body.code === HttpResponseCodeBoltServer.NOT_AUTHORIZED &&
            'error_data' in response.body &&
            !!response.body.error_data &&
            ('requiredPermission' in response.body.error_data ||
                'requiredPermissions' in response.body.error_data ||
                'requiredOneOfPermissions' in response.body.error_data));
    };
    HttpResponseUtilsBoltServer.isMissingEntityError = function (response) {
        return response.body.code === 400 && response.body.message === 'NOT_EXISTS';
    };
    HttpResponseUtilsBoltServer.isBoltServerResponse = function (response) {
        return typeof response.body === 'object' && response.body !== null && 'code' in response.body;
    };
    return HttpResponseUtilsBoltServer;
}());
export { HttpResponseUtilsBoltServer };
