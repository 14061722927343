import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Action } from 'redux';

export enum ActionType {
  DATA_LOADED = 'RIDER_USER_SIGNUP/DATA_LOADED',
  DATA_LOADING = 'RIDER_USER_SIGNUP/DATA_LOADING',
  SET_FORM_UPLOADING = 'RIDER_USER_SIGNUP/SET_FORM_UPLOADING',
  SET_FORM_UPLOADED = 'RIDER_USER_SIGNUP/SET_FORM_UPLOADED',
  SET_CITY = 'RIDER_USER_SIGNUP/SET_CITY',
}

export interface SetRiderUserSignupData extends Action {
  type: ActionType.DATA_LOADED;
  value: DriverRegistrationPortalNoAuthService.GetFormDataForRiderUserResponse;
}

export interface SetCityData extends Action {
  type: ActionType.SET_CITY;
  city: number | null;
}

export const formUploading = () => ({
  type: ActionType.SET_FORM_UPLOADING,
});

export const formLoading = () => ({
  type: ActionType.DATA_LOADING,
});

export const formUploaded = () => ({
  type: ActionType.SET_FORM_UPLOADED,
});

export const formLoaded = (
  value: DriverRegistrationPortalNoAuthService.GetFormDataForRiderUserResponse
): SetRiderUserSignupData => ({
  type: ActionType.DATA_LOADED,
  value,
});

export const setCity = (city: number | null): SetCityData => ({
  type: ActionType.SET_CITY,
  city,
});
