import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import registrationApi from '../api/DriverRegistrationApi';
import documentsApi from '../api/DocumentsApi';
import eventLogApi from '../api/EventLogApi';
import { formReducer } from './form/formReducers';
import { notificationsReducer } from './notification/notificationsReducer';
import { experimentReducer } from './experiment/experimentReducer';
import { IAppState, ThunkMiddlware } from './types';
import { chatReducer } from './chat/chatReducer';
import { mixpanelReducer } from './mixpanel/mixpanelReducer';
import { authReducer } from './auth/authReducer';
import { autosaveReducer } from './autosave/autosaveReducer';
import { localizationReducer } from './localization/localizationReducer';
import { verificationReducer } from './verification/verificationReducer';
import { riderUserSignupReducer } from './rider_user_signup/riderUserSignupReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  form: formReducer,
  notifications: notificationsReducer,
  experiments: experimentReducer,
  chat: chatReducer,
  mixpanel: mixpanelReducer,
  auth: authReducer,
  autosave: autosaveReducer,
  router: connectRouter(history),
  localization: localizationReducer,
  verification: verificationReducer,
  riderUserSignup: riderUserSignupReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewareDeps: ThunkMiddlware = {
  documentsApi,
  registrationApi,
  eventLogApi,
};
const thunkMiddleware = thunk.withExtraArgument(middlewareDeps);
const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware, routerMiddleware(history))
);
export const useSelector: TypedUseSelectorHook<IAppState> = useReduxSelector;

export const store = createStore(rootReducer, enhancer) as Store<
  IAppState,
  any
>;
