import React from 'react';
import { useSelector } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';
import { DocumentUpload } from './DocumentUpload';
import { EnhancedDocumentUpload } from './EnhancedDocumentUpload';
import {
  enhancedCaptureDocumentKeys,
  isDocumentResubmission,
  useEnhancedDocumentCapture,
} from '../../redux/form/formSelectors';

interface DocumentUploadWrapperProps {
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  fieldDefinition: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (
    changed: FleetRegistrationNoAuthService.FleetUploadedDocument
  ) => void;
  hash: string;
  step: DriverRegistrationPortalNoAuthService.Step;
}

export const DocumentUploadWrapper = ({
  commonTranslations,
  fieldDefinition,
  updateHandler,
  hash,
  step,
}: DocumentUploadWrapperProps): React.ReactElement | null => {
  const enrolledToEnhancedCapture = useSelector(useEnhancedDocumentCapture);
  const isResubmittingDocuments = useSelector(isDocumentResubmission);
  const useEnhancedCapture =
    !isResubmittingDocuments && enrolledToEnhancedCapture;
  const supportedDocuments = useSelector(enhancedCaptureDocumentKeys);
  const useDeeplink = !!(
    useEnhancedCapture &&
    fieldDefinition.document_type_key &&
    supportedDocuments.includes(fieldDefinition.document_type_key)
  );

  if (!fieldDefinition.document_type_key) {
    return null;
  }

  return useEnhancedCapture ? (
    <EnhancedDocumentUpload
      commonTranslations={commonTranslations}
      field={fieldDefinition}
      step={step}
      updateHandler={updateHandler}
      hash={hash}
      useDeeplink={useDeeplink}
    />
  ) : (
    <DocumentUpload
      commonTranslations={commonTranslations}
      field={fieldDefinition}
      step={step}
      updateHandler={updateHandler}
      hash={hash}
    />
  );
};
