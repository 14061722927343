import { v4 as uuidv4 } from 'uuid';
import * as LocalStorage from './LocalStorageService';

// Keep it in sync with Tailwind config
export const MOBILE_MAX_WIDTH = 640;

export const DeviceData = {
  isMobileScreen(): boolean {
    const isMobileScreenWidth = window.screen?.width < MOBILE_MAX_WIDTH;
    const isMobileUseragent = !!navigator.userAgent.match(/iPhone|Android/i);
    return isMobileScreenWidth && isMobileUseragent;
  },

  getDeviceId(): string {
    const savedId = LocalStorage.getItem(LocalStorage.DeviceIdKey);
    if (savedId) {
      return savedId;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const urlDeviceId = urlParams.get('device_uid');

    const id = urlDeviceId || uuidv4();
    LocalStorage.setItem(LocalStorage.DeviceIdKey, id);
    return id;
  },

  getDeviceName(): string {
    const urlParams = new URLSearchParams(window.location.search);
    const urlDeviceName = urlParams.get('device_name');
    return urlDeviceName || navigator.userAgent.slice(0, 255);
  },

  getDeviceOsVersion(): string {
    const urlParams = new URLSearchParams(window.location.search);
    const urlDeviceOsVersion = urlParams.get('os_version');
    return urlDeviceOsVersion || 'web';
  },

  getDeviceType(): string {
    const urlParams = new URLSearchParams(window.location.search);
    const urlDeviceType = urlParams.get('deviceType');
    return urlDeviceType || 'web';
  },

  isMobileBrowser(): boolean {
    const useragent = window.navigator.userAgent;
    if (
      useragent.indexOf('Android') !== -1 ||
      useragent.indexOf('iPhone') !== -1 ||
      useragent.indexOf('iPad') !== -1
    ) {
      return true;
    }
    return false;
  },

  isAndroid(): boolean {
    const useragent = window.navigator.userAgent;
    if (useragent.indexOf('Android') !== -1) {
      return true;
    }
    return false;
  },

  isIOs(): boolean {
    const useragent = window.navigator.userAgent;
    if (
      useragent.indexOf('iPhone') !== -1 ||
      useragent.indexOf('iPad') !== -1
    ) {
      return true;
    }
    return false;
  },
};
