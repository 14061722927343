import * as React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import ErrorHint from '../common/ErrorHint';
import { BaseInputProps } from './BaseInput';
import FieldDescription from '../common/FieldDescription';
import { Choice, customStyles, errorStyles, isChoice } from './SelectCommon';

export interface GroupedOptions {
  // Untested with label at this time, feel free to experiment
  label?: string;
  separator?: boolean;
  options: Choice[];
}

interface SelectGroupedProps extends BaseInputProps<string | number> {
  choices: GroupedOptions[];
  hideLabel?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isRequired: boolean;
  formatOptionLabel?: (data: Choice) => React.ReactNode;
  formatGroupLabel?: (data: GroupedOptions) => React.ReactNode;
}

export const SelectGrouped: React.FC<SelectGroupedProps> = ({
  choices,
  title,
  description,
  value,
  updateHandler,
  blurHandler,
  error,
  hideLabel,
  placeholder,
  isSearchable,
  isRequired,
  formatOptionLabel,
  formatGroupLabel,
}: SelectGroupedProps) => {
  const selectedValue =
    choices
      .flatMap((choice) => choice.options)
      .find((choise) => choise.value === value) || undefined;

  const styles = (error
    ? errorStyles
    : customStyles) as unknown as StylesConfig<GroupedOptions>;

  return (
    <div className="w-full">
      {!hideLabel && (
        <label
          htmlFor={title}
          className={`text-black font-bold text-sm text-left rtl:text-right ${
            isRequired ? "after:text-red-700 after:content-['_*']" : ''
          }`}
        >
          {title}
        </label>
      )}
      <div className="w-full my-2">
        <ReactSelect
          id={title}
          name={title}
          placeholder={placeholder || ''}
          value={selectedValue}
          options={choices}
          getOptionLabel={(item: Choice) => item.title?.toString() ?? ''}
          getOptionValue={(item: Choice) => item.value?.toString() ?? ''}
          // @ts-expect-error ReactSelect generates a complex type definition, and to share styles between both version of this component we need to be able to pass the same style object
          styles={styles}
          isSearchable={isSearchable}
          isClearable
          // blurInputOnSelect (triggers autosave prematurely)
          closeMenuOnSelect
          onChange={(option) => {
            const newVal = isChoice(option) ? option.value : null;
            updateHandler(newVal);
          }}
          onBlur={(e) => {
            blurHandler(e);
          }}
          formatOptionLabel={formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
        />
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};

SelectGrouped.defaultProps = {
  hideLabel: false,
  placeholder: '',
  isSearchable: false,
  formatOptionLabel: undefined,
  formatGroupLabel: undefined,
};
