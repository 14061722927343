const convertToAppVersion = (version: string | undefined): string => {
  if (!version) {
    return 'DR.1.0';
  }
  if (version.startsWith('DR')) {
    return version;
  }
  const [major, minor] = version.replace('v', '').split('.');
  return `DR.${major}.${minor}`;
};

export const PORTAL_VERSION = convertToAppVersion(process.env.NEW_VERSION);
export const PARTNER_LOGIN_DEEPLINK_SUPPORT = {
  minAndroidVersion: 47.0,
  minIosVersion: 47.0,
};
