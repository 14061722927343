import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration/src/service/DriverRegistrationPortalNoAuthService';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiderUserSignupField } from '../../redux/types';
import { clearFieldError } from '../../redux/notification/notificationActions';
import {
  getCountryOptions,
  getCurrentCity,
  getRiderUserSignupData,
} from '../../redux/rider_user_signup/riderUserSignupSelectors';
import { CascadeSelect } from '../input/CascadeSelect';
import { setCity } from '../../redux/rider_user_signup/riderUserSignupActions';

export const CityDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { countryLabel, cityLabel } = useSelector(getRiderUserSignupData);
  const currentCity = useSelector(getCurrentCity);
  const countryOptions = useSelector(getCountryOptions);

  return (
    <CascadeSelect
      field={{
        translations: {
          label: countryLabel,
          description: '',
          placeholder: '',
        },
        name: RiderUserSignupField.CITY,
        type: DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
          .SELECT,
        select_values: countryOptions,
        current_value: currentCity,
        is_searchable: true,
        is_required: true,
      }}
      cascadeOptions={{
        rootUpdateHandler: () => {
          dispatch(setCity(null));
          dispatch(clearFieldError(RiderUserSignupField.CITY));
        },
        childLabel: cityLabel,
      }}
      updateHandler={(city) => {
        dispatch(setCity(city));
        dispatch(clearFieldError(RiderUserSignupField.CITY));
      }}
      blurHandler={() => ({})}
    />
  );
};
