import {
  getActualRegistrationInfo,
  getLoadingStatus,
} from '../auth/authSelectors';
import { getFirstCommonError } from '../notification/notificationsSelectors';
import { IAppState, LoadingStatus } from '../types';

export const getRegistrationLoadingError = (
  state: IAppState
): string | null => {
  const actualRegistrationInfo = getActualRegistrationInfo(state);
  const loadingStatus = getLoadingStatus(state);
  const loadingError = getFirstCommonError(state);
  const defaultLoadingError = 'Failed to load registration data.';

  if (loadingStatus === LoadingStatus.FAILED) {
    if (actualRegistrationInfo && actualRegistrationInfo.statusErrorMessage) {
      return actualRegistrationInfo.statusErrorMessage;
    }
    return loadingError || defaultLoadingError;
  }

  return null;
};
