import React, { useEffect, useState } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { DocumentModal } from './DocumentModal';
import { Button } from '../../Button';
import { NotificationsArea } from '../../NotificationsArea';
import { BackArrow } from '../../common/BackArrow';
import { PicturePreview } from './PicturePreview';
import { DocumentContentType } from '../../types/DocumentContentType';

interface DocumentUploadPreviewWithExampleProps {
  isOpen: boolean;
  submitting: boolean;
  fileUrl: string;
  exampleFileUrl: string;
  documentContentType: DocumentContentType;
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  nextHandler: () => void;
  changePictureHandler: () => void;
  closeHandler: () => void;
}

export const DocumentUploadPreviewWithExample: React.FC<
  DocumentUploadPreviewWithExampleProps
> = ({
  isOpen,
  submitting,
  fileUrl,
  exampleFileUrl,
  documentContentType,
  commonTranslations,
  nextHandler,
  changePictureHandler,
  closeHandler,
}: DocumentUploadPreviewWithExampleProps) => {
  const commonPreviewTranslations = commonTranslations.document_upload.preview;
  const [fromBottom, setFromBottom] = useState('-150px');

  useEffect(() => {
    setTimeout(() => setFromBottom('0'), 2500);
  }, []);

  return (
    <DocumentModal isOpen={isOpen} onClose={closeHandler}>
      <div className="bg-gray-900 h-full" data-test="upload_preview">
        <div className="block">
          <div className="h-full overflow-auto">
            <div className="bg-gray-900">
              <button onClick={closeHandler} type="button">
                <div className="object-contain p-3">
                  <BackArrow />
                </div>
              </button>
            </div>
            <div className="w-full bg-gray-900 pb-[260px]">
              <div className="relative mb-5">
                {documentContentType !== DocumentContentType.PHOTO && (
                  <div className="absolute z-10 right-[20px] top-[-4px] text-white text-sm px-3 py-1 bg-purple-500 rounded-lg">
                    {commonTranslations.document_upload.your_document}
                  </div>
                )}
                <div className="pt-3 px-3">
                  <PicturePreview
                    fileUrl={fileUrl}
                    fullHeight={false}
                    data-test="user_document_preview"
                  />
                </div>
              </div>
              {documentContentType !== DocumentContentType.PHOTO && (
                <div className="relative">
                  <div className="absolute z-10 right-[20px] top-[-4px] text-white text-sm px-3 py-1 bg-orange-500 rounded-lg">
                    {commonTranslations.document_upload.document_upload_example}
                  </div>
                  <img
                    className="object-contain w-full p-3 pb-10"
                    src={exampleFileUrl}
                    alt="Document Example"
                    data-test="example_preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="absolute z-20 transition-all duration-[1500ms] ease-out drop-shadow-frombottom left-0 bg-white w-full rounded-t-2xl"
            style={{ bottom: fromBottom }}
          >
            <div className="block px-6 pb-6">
              <h3
                data-test="title"
                className="pt-3 px-3 text-xl text-center text-gray-900 font-bold"
              >
                {commonPreviewTranslations.readability_title}
              </h3>
              <div
                data-test="description"
                className="text-center text-sm text-gray-700 mb-1"
              >
                {commonPreviewTranslations.readability_description}
              </div>
              <Button
                id="confirm_photo"
                onClick={nextHandler}
                color={submitting ? 'bg-purple-200' : 'bg-purple-500'}
                loadingTextColor="text-purple-500"
                label={
                  submitting ? '' : commonPreviewTranslations.next_button_label
                }
                disabled={submitting}
                loading={submitting}
              />
              <Button
                id="choose_photo"
                onClick={changePictureHandler}
                color="bg-gray-200"
                loadingTextColor="text-gray-500"
                textColor={submitting ? 'text-gray-500' : 'text-gray-900'}
                label={commonPreviewTranslations.choose_photo_button_label}
                disabled={submitting}
                loading={false}
              />
            </div>
            <NotificationsArea />
          </div>
        </div>
      </div>
    </DocumentModal>
  );
};
