import * as TokenExpiredErrorExports from './errors/AuthTokenExpiredError';
import * as EntityNotFoundErrorExports from './errors/EntityNotFoundError';
import * as HttpStatusErrorExports from './errors/HttpStatusError';
import * as MissingPermissionsErrorExports from './errors/MissingPermissionsError';
import * as ServerResponseErrorExports from './errors/ServerResponseError';
import * as ValidationErrorExports from './errors/ValidationError';
import { HttpResponseBoltServer } from './HttpResponseBoltServer';
export var HttpResponseErrorBoltServer = {
    ServerResponseError: ServerResponseErrorExports.ServerResponseError,
    MissingPermissionsError: MissingPermissionsErrorExports.MissingPermissionsError,
    ValidationError: ValidationErrorExports.ValidationError,
    EntityNotFoundError: EntityNotFoundErrorExports.EntityNotFoundError,
    AuthTokenExpiredError: TokenExpiredErrorExports.AuthTokenExpiredError,
    HttpStatusError: HttpStatusErrorExports.HttpStatusError,
};
export var HttpResponseErrorTypeBoltServer;
(function (HttpResponseErrorTypeBoltServer) {
    HttpResponseErrorTypeBoltServer.PermissionMissingType = HttpResponseBoltServer.PermissionMissingType;
})(HttpResponseErrorTypeBoltServer || (HttpResponseErrorTypeBoltServer = {}));
