import { Dispatch } from 'react';
import { replace } from 'connected-react-router';
import { DriverRegistrationEventLogNoAuthService } from '@bolteu/bolt-server-api-event-log';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { getDeeplinkScheme } from '../../common/environment';
import * as LocalStorage from '../../common/LocalStorageService';
import {
  getAppVersionFromQuery,
  getSignupMarketingQueryParams,
  hasPartnerLoginDeeplinkSupport,
} from '../../common/helpers';
import { trackEvent } from '../common/commonThunk';
import { EVENTS, PAGES } from '../../common/internalEvents';
import { PORTAL_VERSION } from '../../env';
import { setIsRedirecting } from '../auth/authActions';
import { setConfirmationLoaded } from './verificationActions';
import { IAppState } from '../types';
import { getLanguage } from '../localization/localizationSelectors';
import { getCreateAccountResult } from './verificationSelectors';

export function getContinueSignupUrl(getState: () => IAppState): string {
  const createAccountResult = getCreateAccountResult(getState());
  if (!createAccountResult) {
    return `/driver/`;
  }

  const refreshToken = createAccountResult.refresh_token;
  const registrationHash = createAccountResult.registration_hash;

  let signupUrl = window.location.search;
  if (refreshToken) {
    signupUrl += `#partner=${refreshToken}`;
  } else {
    const signupUrlParams = new URLSearchParams(signupUrl);
    signupUrlParams.append('hash', registrationHash);
    signupUrl = `?${signupUrlParams.toString()}`;
  }

  return `/driver/${signupUrl}`;
}

export function redirectAfterAccountCreated(
  dispatch: Dispatch<any>,
  getState: () => IAppState
): void {
  const createAccountResult = getCreateAccountResult(getState());
  if (!createAccountResult) {
    return;
  }

  const refreshToken = createAccountResult.refresh_token;
  const registrationHash = createAccountResult.registration_hash;
  const language = getLanguage(getState());
  const appVersion = getAppVersionFromQuery();
  const signupUrl = getContinueSignupUrl(getState);

  const baseLogEvent: DriverRegistrationEventLogNoAuthService.EventLogTrackRequest =
    {
      user_id: registrationHash,
      user_type:
        DriverRegistrationEventLogNoAuthService.EventUserType
          .DRIVER_REGISTRATION,
      page: PAGES.DP_VERIFIED,
      event: EVENTS.SHARE_PARTNER_TOKEN_DRP,
      version: PORTAL_VERSION,
      ...(appVersion && { app_version: appVersion }),
      user_agent: window.navigator.userAgent,
      save_to_logger: true,
      event_timestamp_ms: new Date().getTime(),
    };

  if (refreshToken && hasPartnerLoginDeeplinkSupport()) {
    trackEvent({
      ...baseLogEvent,
      event: EVENTS.SHARE_PARTNER_TOKEN_DEEPLINK,
    });

    // this logic has been copied from the DP. We keep only Google and AWIN params as we did it on DP.
    const queryParamsForDeeplink = getSignupMarketingQueryParams();

    // sharing the auth token with app using deep link.
    const signupTokenDeeplink = `${getDeeplinkScheme()}partner-signup-token/?token=${refreshToken}&lang=${language}${queryParamsForDeeplink}`;
    dispatch(setIsRedirecting(true));
    window.location.replace(signupTokenDeeplink);

    setTimeout(() => {
      // If we reached this code - the deeplink has not worked, so we fallback to regular redirect
      trackEvent({
        ...baseLogEvent,
        event_timestamp_ms: new Date().getTime(),
      });
      dispatch(replace(signupUrl));
      dispatch(setConfirmationLoaded());
      dispatch(setIsRedirecting(false));
    }, 1000);
  } else {
    trackEvent(baseLogEvent);
    dispatch(replace(signupUrl));
    dispatch(setConfirmationLoaded());
  }
}

export function displayAppPromotionScreen(
  dispatch: Dispatch<any>,
  getState: () => IAppState
): void {
  const createAccountResult = getCreateAccountResult(getState());
  if (!createAccountResult) {
    return;
  }

  const regHashId =
    (createAccountResult.registration_hash || '').substring(0, 4) || 'hash';
  LocalStorage.setItem(LocalStorage.ContinueInAppDisplayed, regHashId);

  dispatch(replace(`/driver/appPromotion/${window.location.search}`));
}

export function shouldShowAppPromoScreen(
  appVersion: string | null,
  refreshToken: string | null | undefined,
  appPromotion:
    | DriverRegistrationPortalNoAuthService.AppPromotionMode
    | undefined
): boolean {
  return Boolean(
    !appVersion &&
      refreshToken &&
      (appPromotion ===
        DriverRegistrationPortalNoAuthService.AppPromotionMode.OPTIONAL ||
        appPromotion ===
          DriverRegistrationPortalNoAuthService.AppPromotionMode.REQUIRED)
  );
}
