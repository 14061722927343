import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsResults } from '../redux/form/formThunk';
import { isDocumentStatusPollingEnabled } from '../redux/form/formSelectors';

const POLL_INTERVAL_MS = 15000;

export function useGetDocumentsResultsPolling(): void {
  const dispatch = useDispatch();
  const isPollingEnabled = useSelector(isDocumentStatusPollingEnabled);

  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  React.useEffect(() => {
    const pollingCallback = () => {
      dispatch(getDocumentsResults());
    };

    const startPolling = () => {
      pollingCallback();
      timerRef.current = setInterval(pollingCallback, POLL_INTERVAL_MS);
    };

    const stopPolling = () => {
      clearInterval(timerRef.current as NodeJS.Timeout);
    };

    if (isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [dispatch, isPollingEnabled]);
}
