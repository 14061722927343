import React, { ReactNode } from 'react';
import Markdown from 'markdown-to-jsx';
import { useSelector } from '../../redux/store';
import { getMixpanel } from '../../redux/mixpanel/mixpanelSelectors';

interface MarkdownWrapperProps {
  children: string;
}

interface ElementProps {
  [key: string]: ReactNode | undefined;
}

const AddTargetBlankAndTrackToLinks = ({
  children,
  ...props
}: ElementProps) => {
  const mixpanel = useSelector(getMixpanel);

  const trackOnClick = React.useCallback(
    (event) => {
      if (mixpanel) {
        mixpanel.trackLinkClicked(event.currentTarget.href);
      }
    },
    [mixpanel]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a {...props} target="_blank" onClick={trackOnClick}>
      {children}
    </a>
  );
};

/**
 * This component supports both Markdown and raw HTML.
 * It sanitizes HTML, removing possible attack vectors (like onClick props and so on).
 */
export default function MarkdownWrapper({
  children,
}: MarkdownWrapperProps): React.ReactElement | null {
  return (
    <Markdown
      options={{
        overrides: {
          a: {
            component: AddTargetBlankAndTrackToLinks,
          },
        },
      }}
    >
      {children}
    </Markdown>
  );
}
