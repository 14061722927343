import { Action } from 'redux';

export enum ActionType {
  FIELD_ERRORS_ADDED = 'NOTIFICATION/FIELD_ERRORS_ADDED',
  COMMON_ERROR_ADDED = 'NOTIFICATION/COMMON_ERROR_ADDED',
  VERIFICATION_ERROR_ADDED = 'NOTIFICATION/VERIFICATION_ERROR_ADDED',
  CLEAR_FIELD_ERRORS = 'NOTIFICATION/CLEAR_FIELD_ERRORS',
  CLEAR_VERIFICATION_ERRORS = 'NOTIFICATION/CLEAR_VERIFICATION_ERRORS',
  CLEAR_ERRORS = 'NOTIFICATION/CLEAR_ERRORS',
}

export interface FieldValidationError {
  error: string;
  property: string;
}

export interface CommonError {
  error: string;
  code?: number;
}

export interface VerificationError {
  error: string;
  code?: number;
  show_otp?: boolean;
}

export interface IClearErrors {
  type: ActionType.CLEAR_ERRORS;
}

export const clearErrors = (): IClearErrors => ({
  type: ActionType.CLEAR_ERRORS,
});

export interface IFieldErrorsAdded {
  type: ActionType.FIELD_ERRORS_ADDED;
  errors: FieldValidationError[];
}

export const fieldErrorsAdded = (
  errors: FieldValidationError[]
): IFieldErrorsAdded => ({
  type: ActionType.FIELD_ERRORS_ADDED,
  errors,
});

export interface ICommonErrorAdded {
  type: ActionType.COMMON_ERROR_ADDED;
  error: CommonError;
}

export const commonErrorAdded = (error: CommonError): ICommonErrorAdded => ({
  type: ActionType.COMMON_ERROR_ADDED,
  error,
});

export interface IClearFieldError {
  type: ActionType.CLEAR_FIELD_ERRORS;
  property: string;
}

export const clearFieldError = (property: string): IClearFieldError => ({
  type: ActionType.CLEAR_FIELD_ERRORS,
  property,
});

export interface IVerificationErrorAdded {
  type: ActionType.VERIFICATION_ERROR_ADDED;
  error: VerificationError;
}

export const verificationErrorAdded = (
  error: VerificationError
): IVerificationErrorAdded => ({
  type: ActionType.VERIFICATION_ERROR_ADDED,
  error,
});

export type NotificationAction =
  | Action
  | IFieldErrorsAdded
  | ICommonErrorAdded
  | IClearFieldError
  | IClearErrors
  | IVerificationErrorAdded;
