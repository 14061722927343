import React from 'react';

import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from 'react-redux';
import declinedImage from '../../../assets/document_status/declined.svg';
import inReviewImage from '../../../assets/document_status/in_review.svg';
import uploadedImage from '../../../assets/document_status/confirmed.svg';
import { getTranslations } from '../../../redux/form/formSelectors';

export interface DocumentStatusLabelProps {
  status: DriverRegistrationPortalNoAuthService.DocumentResultStatus;
  error?: string;
}

interface LabelData {
  style: string;
  image: string;
  text: string;
}

const getLabelData = (
  status: DriverRegistrationPortalNoAuthService.DocumentResultStatus,
  translations: DriverRegistrationPortalNoAuthService.CommonTranslations
): LabelData => {
  switch (status) {
    case DriverRegistrationPortalNoAuthService.DocumentResultStatus.IN_REVIEW:
      return {
        style: 'bg-purple-100 text-purple-700',
        image: inReviewImage,
        text: translations.document_upload.status_label.in_review,
      };
    case DriverRegistrationPortalNoAuthService.DocumentResultStatus.CONFIRMED:
      return {
        style: 'bg-green-100 text-green-700',
        image: uploadedImage,
        text: translations.document_upload.status_label.uploaded,
      };
    case DriverRegistrationPortalNoAuthService.DocumentResultStatus.DECLINED:
      return {
        style: 'bg-red-100 text-red-700',
        image: declinedImage,
        text: translations.document_upload.status_label.declined,
      };
    case DriverRegistrationPortalNoAuthService.DocumentResultStatus
      .NOT_UPLOADED:
    default:
      return {
        style: '',
        image: '',
        text: '',
      };
  }
};
export const DocumentStatusLabel: React.FC<DocumentStatusLabelProps> = ({
  status,
  error,
}: DocumentStatusLabelProps) => {
  const translations = useSelector(getTranslations);
  const { style, image, text } = getLabelData(status, translations);

  return status ===
    DriverRegistrationPortalNoAuthService.DocumentResultStatus.NOT_UPLOADED ? (
    <></>
  ) : (
    <div className="flex flex-col gap-1 text-sm">
      <div
        className={`flex gap-1 px-2 w-fit h-[26px] items-center rounded ${style}`}
      >
        <img src={image} alt="" width={16} height={16} />
        {text}
      </div>
      {status ===
        DriverRegistrationPortalNoAuthService.DocumentResultStatus.DECLINED &&
      error ? (
        <div className="text-red-700">{error}</div>
      ) : null}
    </div>
  );
};
