import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import React from 'react';
import { FinalScreenField } from './FinalScreenField';
import pending from '../../assets/pending.svg';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { useStatusPolling } from '../../common/statusPolling';

interface SubmittedFinalScreenProps {
  finalScreen: DriverRegistrationPortalNoAuthService.FinalScreen;
}

export const SubmittedFinalScreen: React.FC<SubmittedFinalScreenProps> = (
  props
) => {
  useStatusPolling();
  const { finalScreen } = props;
  const mappedFields = React.useMemo(() => {
    return (
      finalScreen.fields &&
      finalScreen.fields.map(
        (field: DriverRegistrationPortalNoAuthService.Field) => {
          return (
            <div
              className="mb-6 flex flex-row content-center items-center justify-center"
              key={field.name}
            >
              <FinalScreenField fieldDefinition={field} />
            </div>
          );
        }
      )
    );
  }, [finalScreen]);

  return (
    <div>
      <div className="pb-8 px-2 text-center">
        <div className="p-2 mx-auto">
          <img
            src={pending}
            alt={finalScreen.translations.title}
            className="mx-auto mb-5"
          />
          <h1 className="text-2xl font-bold">
            <MarkdownWrapper>{finalScreen.translations.title}</MarkdownWrapper>
          </h1>
          {finalScreen.translations.subtitle && (
            <div className="text-gray-700 text-sm mt-1">
              <MarkdownWrapper>
                {finalScreen.translations.subtitle}
              </MarkdownWrapper>
            </div>
          )}
        </div>
        <div className="flex flex-col content-center justify-center desktop:w-1/2 p-2 pt-6 mx-auto">
          {mappedFields}
        </div>
      </div>
    </div>
  );
};
