import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setFormStep } from '../redux/form/formActions';
import { getPrevNonEmptyStep } from '../redux/form/formSelectors';
import { useSelector } from '../redux/store';
import { Button } from './Button';

interface Props {
  text: string;
}

export const BackButton: React.FC<Props> = ({ text }) => {
  const inputId = 'back';

  const dispatch = useDispatch();
  const prevStep = useSelector(getPrevNonEmptyStep);
  const canGoBack = prevStep !== null;

  const goBack = React.useCallback(() => {
    if (prevStep) {
      dispatch(setFormStep(prevStep));
    }
  }, [prevStep, dispatch]);

  return canGoBack ? (
    <Button
      onClick={goBack}
      color="bg-gray-200"
      loadingTextColor="text-gray-500"
      textColor="text-gray-900"
      label={text}
      disabled={false}
      loading={false}
      id={inputId}
    />
  ) : (
    <></>
  );
};
