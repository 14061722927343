import { Action } from 'redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { LanguageLoadingStatus } from '../types';

export enum ActionType {
  SET_COUNTRY = 'LOCALIZATION/SET_COUNTRY',
  SET_IS_COUNTRY_FINAL = 'LOCALIZATION/SET_IS_COUNTRY_FINAL',
  SET_LANGUAGE = 'LOCALIZATION/SET_LANGUAGE',
  SET_LANGUAGES = 'LOCALIZATION/SET_LANGUAGES',
  SET_COMMON_TRANSLATIONS = 'LOCALIZATION/SET_COMMON_TRANSLATIONS',
  SET_LOADING_STATUS = 'LOCALIZATION/SET_LOADING_STATUS',
}

export interface SetLanguage extends Action {
  type: ActionType.SET_LANGUAGE;
  language: string;
}

export interface SetLanguages extends Action {
  type: ActionType.SET_LANGUAGES;
  languages: DriverRegistrationPortalNoAuthService.FlowLanguage[];
}

export interface SetCommonTranslations extends Action {
  type: ActionType.SET_COMMON_TRANSLATIONS;
  common_translations: DriverRegistrationPortalNoAuthService.CommonTranslations;
}

export interface SetCountry {
  type: ActionType.SET_COUNTRY;
  country: string;
}

export interface SetIsCountryFinal {
  type: ActionType.SET_IS_COUNTRY_FINAL;
  is_country_final: boolean;
}

export interface SetLoadingStatus {
  type: ActionType.SET_LOADING_STATUS;
  loading_status: LanguageLoadingStatus;
}

export const setLanguage = (language: string): SetLanguage => ({
  type: ActionType.SET_LANGUAGE,
  language,
});

export const setLanguages = (
  languages: DriverRegistrationPortalNoAuthService.FlowLanguage[]
): SetLanguages => ({
  type: ActionType.SET_LANGUAGES,
  languages,
});

export const setCommonTranslations = (
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations
): SetCommonTranslations => ({
  type: ActionType.SET_COMMON_TRANSLATIONS,
  common_translations: commonTranslations,
});

export const setCountry = (country: string): SetCountry => ({
  type: ActionType.SET_COUNTRY,
  country,
});

export const setIsCountryFinal = (
  is_country_final: boolean
): SetIsCountryFinal => ({
  type: ActionType.SET_IS_COUNTRY_FINAL,
  is_country_final,
});

export const setLanguageLoadingStatus = (
  loading_status: LanguageLoadingStatus
): SetLoadingStatus => ({
  type: ActionType.SET_LOADING_STATUS,
  loading_status,
});

export type LanguageAction =
  | Action
  | SetCountry
  | SetIsCountryFinal
  | SetLanguage
  | SetLanguages
  | SetCommonTranslations
  | SetLoadingStatus;
