var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { HttpClient, } from '@bolteu/http-client';
import { HttpResponseNormalizerBoltServer } from '@bolteu/http-normalizer-bolt-server';
import { HttpRequestBoltServer, } from '@bolteu/http-request-bolt-server';
import { HttpClientExtensionBoltServer } from './HttpClientExtensionBoltServer';
var HttpClientBoltServer = /** @class */ (function (_super) {
    __extends(HttpClientBoltServer, _super);
    function HttpClientBoltServer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /* eslint-disable @typescript-eslint/naming-convention -- Necessary for API generator */
        _this.__secretStandardRequestConstraint = 'StandardRequest';
        _this.__secretAuthStrategyConstraint = 'AuthStrategy';
        _this.__secretShardingStrategyConstraint = 'ShardingStrategy';
        /* eslint-enable */
        // Normalizer is as a part of constructor - to avoid having to call `.init()` method for noAuth clients
        _this.responseNormalizer = new HttpResponseNormalizerBoltServer();
        return _this;
    }
    HttpClientBoltServer.prototype.assertInit = function () {
        if (process.env.NODE_ENV === 'development') {
            if (!this.extension) {
                throw new Error('HttpClientBoltServer.init() is required to be called for correct operation!');
            }
        }
    };
    HttpClientBoltServer.prototype.sendRequest = function (request, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.assertInit();
                return [2 /*return*/, _super.prototype.sendRequest.call(this, request, opts)];
            });
        });
    };
    HttpClientBoltServer.prototype.sendRequestRaw = function (request, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.assertInit();
                return [2 /*return*/, _super.prototype.sendRequestRaw.call(this, request, opts)];
            });
        });
    };
    HttpClientBoltServer.prototype.createRequest = function (request, opts) {
        return new HttpRequestBoltServer(this.baseUrl, request, opts);
    };
    HttpClientBoltServer.prototype.useInterceptors = function (interceptors) {
        var _a, _b;
        this.assertInit();
        var defaultInterceptors = (_b = (_a = this.extension) === null || _a === void 0 ? void 0 : _a.getInterceptors()) !== null && _b !== void 0 ? _b : [];
        var newInterceptors = __spreadArray(__spreadArray([], defaultInterceptors), interceptors);
        _super.prototype.useInterceptors.call(this, newInterceptors);
        return this;
    };
    HttpClientBoltServer.prototype.init = function (config) {
        this.extension = new HttpClientExtensionBoltServer(config);
        this.useInterceptors([]);
        return this;
    };
    return HttpClientBoltServer;
}(HttpClient));
export { HttpClientBoltServer };
