import { Action } from 'redux';
import { ExperimentParameters } from '../types';

export enum ActionType {
  EXPERIMENT_PARAMETERS_LOADED = 'EXPERIMENTS/PARAMETERS_LOADED',
}

export interface ExperimentParametersLoaded {
  type: ActionType.EXPERIMENT_PARAMETERS_LOADED;
  eventKey: string;
  parameters: ExperimentParameters;
}

export const experimentParametersLoaded = (
  eventKey: string,
  parameters: ExperimentParameters
): ExperimentParametersLoaded => ({
  type: ActionType.EXPERIMENT_PARAMETERS_LOADED,
  eventKey,
  parameters,
});

export type ExperimentParametersAction = Action | ExperimentParametersLoaded;
