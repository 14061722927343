import { useEffect, useState } from 'react';

const ONE_SECOND = 1000;

export interface Countdown {
  seconds: number;
  reset: () => void;
}

const useCountdown = (initialTime: number): Countdown => {
  const [countDown, setCountDown] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, ONE_SECOND);

    if (countDown <= 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [initialTime, countDown]);

  const reset = () => {
    setCountDown(initialTime);
  };

  return { seconds: countDown, reset };
};

export { useCountdown };
