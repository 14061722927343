var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpResponseFetchBodyParser } from './bodyParsers/HttpResponseFetchBodyParser';
import { HttpClientError } from './HttpClientError';
import { HttpResponse } from './HttpResponse';
import { throwError } from './utils/errors';
var HttpFetchHandler = /** @class */ (function () {
    function HttpFetchHandler() {
        this.fetchBodyParser = new HttpResponseFetchBodyParser();
    }
    HttpFetchHandler.prototype.handle = function (req) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1, _a, _b, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.makeFetch(req)];
                    case 1:
                        response = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _c.sent();
                        throw throwError(e_1, function (err) {
                            // According to the https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
                            // Fetch throws either a) Network error or b) Abort error
                            if (err && err.name === 'AbortError') {
                                return new HttpClientError.RequestAbortError(err.message || 'Network request was aborted', req.url.toString());
                            }
                            // In case fetch implementation does not detect AbortError correctly, let's assume it's a network error
                            return new HttpClientError.RequestNetworkError(err.message || 'Network request failed', req.url.toString());
                        });
                    case 3:
                        _c.trys.push([3, 5, , 6]);
                        _a = HttpResponse.bind;
                        _b = [void 0, response.status,
                            response.headers];
                        return [4 /*yield*/, this.fetchBodyParser.parse(response)];
                    case 4: return [2 /*return*/, new (_a.apply(HttpResponse, _b.concat([_c.sent()])))()];
                    case 5:
                        e_2 = _c.sent();
                        throw throwError(e_2, function (err) {
                            return new HttpClientError.ResponseBodyParseError(err.message || 'Request body parsing failed', req.url.toString(), response.statusText);
                        });
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    HttpFetchHandler.prototype.makeFetch = function (req) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var controller, timeoutHandle, fetchOptions, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        controller = new AbortController();
                        timeoutHandle = setTimeout(function () { return controller.abort(); }, (_a = req.opts) === null || _a === void 0 ? void 0 : _a.timeout);
                        fetchOptions = {
                            method: req.http_method,
                            headers: req.headers,
                            signal: controller.signal,
                        };
                        if (req.body) {
                            fetchOptions.body = req.body;
                        }
                        return [4 /*yield*/, fetch(req.url.toString(), fetchOptions)];
                    case 1:
                        response = _b.sent();
                        clearTimeout(timeoutHandle);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    return HttpFetchHandler;
}());
export { HttpFetchHandler };
