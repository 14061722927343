import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SignupButton } from './SignupButton';
import { CityDropdown } from './CityDropdown';
import {
  getIsDataLoading,
  getRiderUserSignupData,
} from '../../redux/rider_user_signup/riderUserSignupSelectors';
import { initLanguages } from '../../redux/localization/localizationThunk';
import { loadSignupForm } from '../../redux/rider_user_signup/riderUserThunk';
import { Loader } from '../common/Loader';
import MarkdownWrapper from '../common/MarkdownWrapper';

export const RiderUserSignup: React.FC = () => {
  const dispatch = useDispatch();
  const { termsAndConditionsText, title, subTitle } = useSelector(
    getRiderUserSignupData
  );
  const isLoading = useSelector(getIsDataLoading);

  useEffect(() => {
    const loadData = async () => {
      // Load languages should be called before loadSignupForm for proper redirection
      await dispatch(initLanguages());
      await dispatch(loadSignupForm());
    };

    loadData();
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="clearfix">
        <div className="flex flex-row justify-center my-8 h-24">
          <Loader
            data-test="app_form_loader"
            textColor="text-purple-500"
            id="rider_sign_up_loader"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="pb-2 px-2 desktop:w-1/2 mx-auto">
        <div className="flex flex-col p-6 pt-6">
          <h1 className="text-2xl font-bold">
            <MarkdownWrapper>{title ?? ''}</MarkdownWrapper>
          </h1>
          <div className="mt-1">
            <MarkdownWrapper>{subTitle ?? ''}</MarkdownWrapper>
          </div>
        </div>

        <div className="flex flex-col p-6 pt-1 space-y-2">
          <CityDropdown />
        </div>
        <div className="text-center text-gray-700 leading-4 pl-6 pr-6 text-xs font-normal">
          <MarkdownWrapper>{termsAndConditionsText ?? ''}</MarkdownWrapper>
        </div>
      </div>
      <div className="flex justify-center flex-row-reverse flex-wrap">
        <SignupButton />
      </div>
    </>
  );
};
