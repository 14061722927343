import { ChatState } from '../types';
import { ActionType } from './chatActions';
import { createReducer } from '../helper';

const initialState: ChatState = {
  loaded: false,
};

const chatLoaded = (): ChatState => ({ loaded: true });

const handlers = {
  [ActionType.CHAT_LOADED]: chatLoaded,
};

export const chatReducer = createReducer<ChatState>(initialState, handlers);
