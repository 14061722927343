import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import accepted from '../../assets/accepted.svg';
import { getTranslations } from '../../redux/form/formSelectors';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { LoadingIcon } from '../common/LoadingIcon';
import { redirectToMarketplace } from '../../redux/auth/authThunk';
import { clearErrors } from '../../redux/notification/notificationActions';

export const VehicleMarketplaceEntryScreen: React.FC = () => {
  const dispatch = useDispatch();
  const commonTranslations = useSelector(getTranslations);

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const onRedirectToVehicleMarketplaceClick = useCallback(async () => {
    dispatch(clearErrors());

    setIsRedirecting(true);
    await dispatch(redirectToMarketplace());

    setIsRedirecting(false);
  }, [dispatch]);

  return (
    <div className="flex flex-col justify-between gap-4 pt-6 px-6 text-center">
      <div className="mx-auto">
        <img
          src={accepted}
          alt={commonTranslations.vehicle_marketplace_screen.title}
          className="mx-auto mb-5"
        />
      </div>
      <div>
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>
            {commonTranslations.vehicle_marketplace_screen.title}
          </MarkdownWrapper>
        </h1>
      </div>
      <div>
        <span className="text-gray-700 text-sm">
          <MarkdownWrapper>
            {commonTranslations.vehicle_marketplace_screen.text}
          </MarkdownWrapper>
        </span>
      </div>
      <div>
        <button
          type="button"
          className="w-full tablet:w-auto m-0 mt-6 px-2 py-3 border-none rounded-full text-center font-bold bg-purple-500 text-white"
          onClick={onRedirectToVehicleMarketplaceClick}
          disabled={isRedirecting}
        >
          {isRedirecting ? (
            <LoadingIcon className="text-white animate-spin mx-auto" />
          ) : (
            commonTranslations.vehicle_marketplace_screen.select_vehicle_button
          )}
        </button>
      </div>
    </div>
  );
};
