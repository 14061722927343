import React from 'react';

import { useSelector } from 'react-redux';
import { getTranslations } from '../../../redux/form/formSelectors';

export const DocumentOptionalLabel: React.FC = () => {
  const translations = useSelector(getTranslations);

  return (
    <div className="flex flex-col gap-1 text-sm">
      <div className="flex gap-1 px-2 w-fit h-[26px] items-center rounded bg-gray-200 text-gray-700">
        {translations.document_upload.status_label.optional}
      </div>
    </div>
  );
};
