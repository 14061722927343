import { useCallback, useEffect } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export type ReCaptchaV3Props = {
  onCaptchaSubmit: (captchaToken?: string | null) => void;
};

export const ReCaptchaV3 = ({ onCaptchaSubmit }: ReCaptchaV3Props): null => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaSolved = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const captchaToken = await executeRecaptcha();
    onCaptchaSubmit(captchaToken);
  }, [executeRecaptcha, onCaptchaSubmit]);

  // Trigger recaptcha with small timeout to avoid an error script loading
  useEffect(() => {
    setTimeout(() => {
      return handleReCaptchaSolved();
    }, 200);
  }, [handleReCaptchaSolved]);

  return null;
};
