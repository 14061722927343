var HttpInterceptorHandler = /** @class */ (function () {
    function HttpInterceptorHandler(next, interceptor) {
        this.next = next;
        this.interceptor = interceptor;
    }
    HttpInterceptorHandler.prototype.handle = function (req) {
        return this.interceptor.intercept(req, this.next.handle.bind(this.next));
    };
    return HttpInterceptorHandler;
}());
var HttpHandlerWithInterceptors = /** @class */ (function () {
    function HttpHandlerWithInterceptors(httpFetch, interceptors) {
        // For explanation of how the interception flow works, see accompanying test in HttpClient.test.ts
        this.handlersChain = interceptors.reduceRight(function (next, interceptor) {
            return new HttpInterceptorHandler(next, interceptor);
        }, httpFetch);
    }
    HttpHandlerWithInterceptors.prototype.handle = function (req) {
        return this.handlersChain.handle(req);
    };
    return HttpHandlerWithInterceptors;
}());
export { HttpHandlerWithInterceptors };
/* eslint-enable */
