import * as React from 'react';

interface ButtonLinkProps {
  id: string;
  href: string;
  color: string;
  label: string;
  disabled?: boolean;
  textColor?: string;
  target?: string;
  onClick?: () => void;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  id,
  href,
  color,
  label,
  disabled,
  textColor,
  target,
  onClick,
}: ButtonLinkProps) => {
  const cursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
  const linkProps = {
    href,
    ...(target ? { target } : null),
  };
  const handleClick = async (event: React.MouseEvent) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="relative">
      <a {...linkProps} rel="noreferrer">
        <input
          id={id}
          type="button"
          disabled={disabled}
          onClick={handleClick}
          className={`w-full tablet:w-auto m-0 mt-6 px-8 py-3 border-none rounded-full text-center font-bold ${
            textColor ?? 'text-white'
          } ${color} ${cursor}`}
          value={label}
        />
      </a>
    </div>
  );
};

ButtonLink.defaultProps = {
  disabled: false,
  textColor: undefined,
  target: undefined,
  onClick: undefined,
};
