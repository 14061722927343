import React from 'react';

export const CameraIcon: React.FC = () => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 3.90712H15.5L13.8 1.35712C13.7085 1.21814 13.5837 1.1042 13.437 1.02562C13.2904 0.947046 13.1264 0.906313 12.96 0.907116H8.04C7.8736 0.906313 7.70963 0.947046 7.56295 1.02562C7.41627 1.1042 7.29152 1.21814 7.2 1.35712L5.5 3.90712H2.5C1.96957 3.90712 1.46086 4.11783 1.08579 4.4929C0.710714 4.86798 0.5 5.37668 0.5 5.90712V16.9071C0.5 17.4375 0.710714 17.9463 1.08579 18.3213C1.46086 18.6964 1.96957 18.9071 2.5 18.9071H18.5C19.0304 18.9071 19.5391 18.6964 19.9142 18.3213C20.2893 17.9463 20.5 17.4375 20.5 16.9071V5.90712C20.5 5.37668 20.2893 4.86798 19.9142 4.4929C19.5391 4.11783 19.0304 3.90712 18.5 3.90712ZM10.5 14.4071C9.80777 14.4071 9.13108 14.2018 8.5555 13.8173C7.97993 13.4327 7.53133 12.886 7.26642 12.2465C7.00151 11.607 6.9322 10.9032 7.06725 10.2243C7.2023 9.54537 7.53564 8.92173 8.02513 8.43224C8.51461 7.94276 9.13825 7.60942 9.81718 7.47437C10.4961 7.33932 11.1999 7.40863 11.8394 7.67354C12.4789 7.93844 13.0256 8.38705 13.4101 8.96262C13.7947 9.53819 14 10.2149 14 10.9071C14 11.8354 13.6313 12.7256 12.9749 13.382C12.3185 14.0384 11.4283 14.4071 10.5 14.4071Z"
      fill="white"
    />
  </svg>
);
