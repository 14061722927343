import React from 'react';

import { currentStepNumber, getSteps } from '../redux/form/formSelectors';
import { useSelector } from '../redux/store';
import { StepIndicator } from './common/StepIndicator';

export const WizardProgress: React.FC = () => {
  const currentStepAsNumber = useSelector(currentStepNumber);
  const steps = useSelector(getSteps);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepAsNumber]);

  return (
    <div className="clearfix">
      <div className="flex flex-row justify-center mt-8 mb-8">
        <StepIndicator
          stepsCount={steps.length}
          currentStep={currentStepAsNumber - 1}
        />
      </div>
    </div>
  );
};
