import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { startVerification } from '../../redux/verification/verificationThunk';
import { Verification } from './Verification';
import { RecaptchaWrapper } from './reCaptcha/RecaptchaWrapper';
import {
  getCaptchaParameters,
  getCaptchaV3Key,
  getSelectedVerificationChannel,
} from '../../redux/verification/verificationSelectors';
import { getLanguage } from '../../redux/localization/localizationSelectors';
import { getQueryParam } from '../../common/helpers';

export const VerificationWrapper: React.FC = () => {
  const dispatch = useDispatch();

  const captchaParameters = useSelector(getCaptchaParameters);
  const language = useSelector(getLanguage);
  const selectedVerificationChannel = useSelector(
    getSelectedVerificationChannel
  );
  const v3SiteKey = useSelector(getCaptchaV3Key);

  const { token } = useParams<{ token: string }>();

  const initialChannel =
    getQueryParam('channel') ??
    DriverRegistrationPortalNoAuthService.VerificationCodeChannel.SMS;

  React.useEffect(() => {
    dispatch(startVerification(token, initialChannel));
  }, [dispatch, token, initialChannel]);

  const onCaptchaSubmit = useCallback(
    (captchaToken: string) => {
      dispatch(
        startVerification(token, selectedVerificationChannel, {
          type: captchaParameters?.type,
          token: captchaToken,
        })
      );
    },
    [captchaParameters, dispatch, selectedVerificationChannel, token]
  );

  // show loading of the Verification component when native captcha is shown
  const isNativeCaptcha =
    !!captchaParameters &&
    captchaParameters.type ===
      DriverRegistrationPortalNoAuthService.CaptchaChallengeType
        .RECAPTCHA_NATIVE;
  const showVerification = !captchaParameters || isNativeCaptcha;

  const mainContent = (
    <>
      {showVerification && <Verification />}

      {!!captchaParameters && (
        <RecaptchaWrapper
          onCaptchaSubmit={onCaptchaSubmit}
          siteKey={captchaParameters.site_key}
          captchaType={captchaParameters.type}
        />
      )}
    </>
  );
  let contentWrapper = mainContent;

  if (v3SiteKey) {
    contentWrapper = (
      <GoogleReCaptchaProvider reCaptchaKey={v3SiteKey} language={language}>
        {mainContent}
      </GoogleReCaptchaProvider>
    );
  }

  return contentWrapper;
};
