import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { v4 } from 'uuid';
import { PORTAL_VERSION, PARTNER_LOGIN_DEEPLINK_SUPPORT } from '../env';
import { getCookie, setCookie } from './cookies';
import { DeviceData } from './deviceData';
import { AWIN_PARAMS, GOOGLE_PARAMS } from './signupMarketingParams';
import { getSignupLandingDomain, getDriverPortalDomain } from './environment';
import * as LocalStorage from './LocalStorageService';
import { VisitorIdKey } from './LocalStorageService';

const VISITOR_ID_PREFIX = 'visitor-driver-';
const LAST_KNOWN_HASH_COOKIE_NAME = 'taxifyDriverPortal_lastKnownHash';
const LAST_KNOWN_HASH_COOKIE_MAX_AGE = 14 * 24 * 60 * 60; // 14 days
const LEGACY_HASH_TOKEN_NAME = 'hash';

export const getHashFromUrl = (): string => {
  // Get from query params
  const urlParams = new URLSearchParams(window.location.search);
  const hashFromQueryParams = urlParams.get(LEGACY_HASH_TOKEN_NAME);
  if (hashFromQueryParams) {
    return hashFromQueryParams;
  }

  // Get from "anchor" part of URL
  const urlHash = window.location.hash;
  if (urlHash) {
    const parts = urlHash.split('=');
    if (
      parts.length === 2 &&
      parts[0] === `#${LEGACY_HASH_TOKEN_NAME}` &&
      parts[1]
    ) {
      return parts[1];
    }
  }

  return '';
};

export const getQueryParam = (key: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getLanguageFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('lang');
};

export const getCountryFromQuery = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('country');
};

export const getAppVersionFromQuery = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('app_version') || null;
};

export const getAppVersion = (): string | null => {
  return getAppVersionFromQuery() ?? getCookie('app_version') ?? null;
};

export const getPreviewFlowIdFromQuery = (): number | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('preview_flow_id') &&
    Number(urlParams.get('preview_flow_id'))
    ? Number(urlParams.get('preview_flow_id'))
    : null;
};

export const getAuthTokenFromUrl = (): string | null => {
  const urlHash = window.location.hash;
  if (urlHash) {
    const parts = urlHash.split('=');
    if (parts.length === 2 && parts[0] === '#partner' && parts[1]) {
      return parts[1];
    }
  }
  return null;
};

export const clearAuthTokensFromUrl = (): void => {
  const currentUrl =
    window.location.pathname + window.location.search + window.location.hash;

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(LEGACY_HASH_TOKEN_NAME);
  const sanitizedUrlParams = urlParams.toString();
  const sanitizedUrl =
    window.location.pathname +
    (sanitizedUrlParams ? `?${sanitizedUrlParams}` : '');

  if (currentUrl !== sanitizedUrl) {
    if ('replaceState' in window.history) {
      // Replace history state, so that user will not see auth token if click "Back" in browser
      window.history.replaceState('', document.title, sanitizedUrl);
    } else {
      // Fallback for old browsers
      window.location.hash = '';
    }
  }
};

export const getDriverLoginLink = (): string => {
  const appVersion = getAppVersion();
  const queryParams = appVersion ? `?app_version=${appVersion}` : '';
  const domain = getDriverPortalDomain();
  return `${domain}/login/${queryParams}`;
};

export const getLoginViaPhoneLink = (): string => {
  const appVersion = getAppVersion();
  let queryParams = '?selectedTabIndex=1';
  if (appVersion) {
    queryParams += `&app_version=${appVersion}`;
  }
  const domain = getDriverPortalDomain();
  return `${domain}/login/${queryParams}`;
};

export const getSignupLandingLink = (): string => {
  const appVersion = getAppVersion();
  const queryParams = appVersion ? `?app_version=${appVersion}` : '';
  const domain = getSignupLandingDomain();
  return `${domain}/driver/${queryParams}`;
};

export const getSignupLightweightLandingLink = (
  language: string,
  country: string | undefined
): string => {
  const appVersion = getAppVersion();
  const lang = country ? `${language}-${country}` : language;
  const queryParams = appVersion ? `?app_version=${appVersion}` : '';
  const domain = getSignupLandingDomain();
  return `${domain}/${lang}/driver/light/${queryParams}`;
};

export const getLegacySignupLink = (hash: string, step?: number): string => {
  const stepPart = step ? `/${step}` : '';
  const appVersion = getAppVersion();
  const queryParams = appVersion ? `?app_version=${appVersion}` : '';
  const domain = getDriverPortalDomain();
  return `${domain}/driver-signup/${hash}${stepPart}${queryParams}`;
};

const unixToDate = (timestamp: number): Date => {
  const milliseconds = timestamp * 1000;
  return new Date(milliseconds);
};

export const unixToDateString = (timestamp: number | null): string => {
  if (!timestamp) {
    return '';
  }
  const date = unixToDate(timestamp);
  return date.toISOString().substring(0, 10);
};

export const unixToLocalDateString = (timestamp: number | null): string => {
  if (!timestamp) {
    return '';
  }
  const date = unixToDate(timestamp);
  return new Intl.DateTimeFormat(navigator.language).format(date);
};

export function getWebMarketingData(): DriverRegistrationPortalNoAuthService.WebMarketingData {
  const url = window.location.origin + window.location.pathname;
  return {
    fbp: getCookie('_fbp'),
    ...(url && { url }),
    ...(window.navigator.userAgent && {
      user_agent: window.navigator.userAgent,
    }),
  };
}

export function getWebMarketingDataForCreateAccountRequest(): DriverRegistrationPortalNoAuthService.WebMarketingData {
  const urlParams = new URLSearchParams(window.location.search);

  // We check if there was 'url' param in query and use it as we want to keep original value for this step
  const url =
    urlParams.get('url') ?? window.location.origin + window.location.pathname;

  const google_client_id = urlParams.get('google_client_id') ?? undefined;

  return {
    fbp: getCookie('_fbp'),
    ...(url && { url }),
    ...(window.navigator.userAgent && {
      user_agent: window.navigator.userAgent,
    }),
    ...(google_client_id && { google_client_id }),
  };
}

function generateOrGetVisitorIdFromStorage(): string {
  let visitorId = LocalStorage.getItem(VisitorIdKey);
  if (!visitorId) {
    visitorId = `${VISITOR_ID_PREFIX}${v4()}`;
  }
  return visitorId;
}

export function getVisitorTrackingData(): DriverRegistrationPortalNoAuthService.VisitorTrackingData {
  const urlParams = new URLSearchParams(window.location.search);

  const visitorId =
    urlParams.get('visitor_id') ?? generateOrGetVisitorIdFromStorage();
  LocalStorage.setItem(VisitorIdKey, visitorId);

  return {
    ...(visitorId && { visitor_id: visitorId }),
  };
}

export function getAppMarketingData(): DriverRegistrationPortalNoAuthService.AppMarketingData {
  const urlParams = new URLSearchParams(window.location.search);

  const app_version = getAppVersion() ?? undefined;
  const appsflyer_id = urlParams.get('appsflyer_id') ?? undefined;
  const advertising_id = urlParams.get('advertising_id') ?? undefined;
  const device_os_version = urlParams.get('os_version') ?? undefined;
  const firebase_instance_id =
    urlParams.get('firebase_instance_id') ?? undefined;

  return {
    app_version,
    appsflyer_id,
    advertising_id,
    device_os_version,
    firebase_instance_id,
  };
}

enum FieldType {
  REFERRAL_CODE = 'referral_code',
}

export function getDefaultValueFromCookies(fieldName: string): string | null {
  switch (fieldName) {
    case FieldType.REFERRAL_CODE:
      return getCookie('referralCode') ?? null;
    default:
      return null;
  }
}

export function getAppTypeFromAppVersion(appVersion: string): string {
  return String(appVersion.substr(0, 2)).toUpperCase();
}

export function getVersionNumberFromAppVersion(appVersion: string): number {
  return Number(appVersion.substr(3));
}

export const getHashFromCookies = (): string => {
  return getCookie(LAST_KNOWN_HASH_COOKIE_NAME) || '';
};

export function saveHashToCookies(hash: string): void {
  setCookie(LAST_KNOWN_HASH_COOKIE_NAME, hash, {
    'max-age': LAST_KNOWN_HASH_COOKIE_MAX_AGE,
  });
}

export enum AppType {
  ANDROID = 'DA',
  IOS = 'DI',
}

export const getWebRequestParams = (
  language: string
): DriverRegistrationPortalNoAuthService.WebRequestParams => ({
  device_os_version: DeviceData.getDeviceOsVersion(),
  device_name: DeviceData.getDeviceName(),
  deviceId: DeviceData.getDeviceId(),
  device_type: DeviceData.getDeviceType(),
  version: PORTAL_VERSION,
  language,
});

export const hasPartnerLoginDeeplinkSupport = (): boolean => {
  const appVersion = getAppVersion();
  if (!appVersion) {
    return false;
  }

  let eligible = false;
  const appType = getAppTypeFromAppVersion(appVersion);
  const versionNumber = getVersionNumberFromAppVersion(appVersion);

  if (appType === 'DA') {
    if (
      !Number.isNaN(versionNumber) &&
      versionNumber >= PARTNER_LOGIN_DEEPLINK_SUPPORT.minAndroidVersion
    ) {
      eligible = true;
    }
  } else if (appType === 'DI') {
    if (
      !Number.isNaN(versionNumber) &&
      versionNumber >= PARTNER_LOGIN_DEEPLINK_SUPPORT.minIosVersion
    ) {
      eligible = true;
    }
  }

  return eligible;
};

export const getSignupMarketingQueryParams = (): string => {
  const urlParams = new URLSearchParams(window.location.search);

  const params: string[] = [];
  GOOGLE_PARAMS.concat(AWIN_PARAMS).forEach((name) => {
    const value = urlParams.get(name);
    if (value && value.length > 0) {
      params.push(`${name}=${value}`);
    }
  });
  if (params) {
    return `&${params.join('&')}`;
  }
  return '';
};

export const objectToQueryString = (
  params: Record<string, string | number | boolean | null | undefined>
): string => {
  const queryString = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    )
    .join('&');

  // Return an empty string if no valid query string was generated
  return queryString ? `?${queryString}` : '';
};
