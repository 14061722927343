var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ATTEMPS = 3;
export var willAccessTokenExpireSoon = function (decodedJwtToken, validForSeconds) {
    return !decodedJwtToken.exp || decodedJwtToken.exp * 1000 - Date.now() < validForSeconds * 1000;
};
var doAsyncOperationWithRetry = function (operation, attemps) {
    if (attemps === void 0) { attemps = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var error, i, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    error = new Error('Unexpected error when retrying operation');
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < attemps)) return [3 /*break*/, 6];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, operation()];
                case 3: return [2 /*return*/, _a.sent()];
                case 4:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error) {
                        error = err_1;
                    }
                    return [3 /*break*/, 5];
                case 5:
                    i++;
                    return [3 /*break*/, 1];
                case 6: throw error;
            }
        });
    });
};
// It is our global access token tracker, to handle case, when we send a lot of request
// during fetching access token and as a result each request issue new access token.
// So, after expiration of access token, we keep track for first request that asked for access token
// and we use same promise for all other requests. If during 5 seconds access token was not fetched, we
// re-create this global fetch promise
var globalUpdateAccessTokenPromise = {
    lastTryAt: 0,
    token: null,
};
// we use this to keep tests independent
export var resetGlobalUpdateAccessToken = function () {
    globalUpdateAccessTokenPromise = {
        lastTryAt: 0,
        token: null,
    };
};
var setAccessTokenPromise = function (operation) {
    globalUpdateAccessTokenPromise.lastTryAt = Date.now();
    globalUpdateAccessTokenPromise.token = doAsyncOperationWithRetry(operation, ATTEMPS);
};
var isPendingAccessTokenPromise = function (waitTime) {
    var token = globalUpdateAccessTokenPromise.token, lastTryAt = globalUpdateAccessTokenPromise.lastTryAt;
    return Boolean(token) && Date.now() - lastTryAt < waitTime * 1000;
};
var getAccessToken = function () { return globalUpdateAccessTokenPromise.token; };
// let reduce amount of requests for access token that we send
export var waitForNewAccessToken = function (updateAccessToken, waitTime) { return __awaiter(void 0, void 0, void 0, function () {
    var jwtToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!isPendingAccessTokenPromise(waitTime)) {
                    setAccessTokenPromise(updateAccessToken);
                }
                return [4 /*yield*/, getAccessToken()];
            case 1:
                jwtToken = _a.sent();
                if (!jwtToken) {
                    throw new Error('Token is null');
                }
                return [2 /*return*/, jwtToken];
        }
    });
}); };
