import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import {
  getFinalScreen,
  getRegistrationStatus,
  isVehicleMarketplaceEnabled,
} from '../../redux/form/formSelectors';
import { useSelector } from '../../redux/store';
import { SubmittedFinalScreen } from './SubmittedFinalScreen';
import { AcceptedFinalScreen } from './AcceptedFinalScreen';
import {
  ACCEPTED_STATUSES,
  DECLINED_STATUSES,
  SUBMITTED_STATUSES,
} from '../../const/statuses';
import { DeclinedFinalScreen } from './DeclinedFinalScreen';
import { VehicleMarketplaceEntryScreen } from './VehicleMarketplaceEntryScreen';

const DefaultFinalScreen: DriverRegistrationPortalNoAuthService.FinalScreen = {
  translations: {
    title: '',
    subtitle: '',
    label: '',
  },
};

export const FinalScreen: React.FC = () => {
  const finalScreen = useSelector(getFinalScreen) || DefaultFinalScreen;
  const status = useSelector(getRegistrationStatus);
  const showVehicleMarketplaceScreen = useSelector(isVehicleMarketplaceEnabled);
  const screen = React.useMemo(() => {
    if (ACCEPTED_STATUSES.includes(status)) {
      return <AcceptedFinalScreen />;
    }
    if (DECLINED_STATUSES.includes(status)) {
      return <DeclinedFinalScreen />;
    }
    if (SUBMITTED_STATUSES.includes(status)) {
      if (showVehicleMarketplaceScreen) {
        return <VehicleMarketplaceEntryScreen />;
      }
      return <SubmittedFinalScreen finalScreen={finalScreen} />;
    }
    return null;
  }, [finalScreen, showVehicleMarketplaceScreen, status]);
  return <>{screen}</>;
};
