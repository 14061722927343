import React from 'react';
import { LoadingIcon } from './LoadingIcon';

interface LoaderProps {
  id: string;
  textColor: string;
}

export const Loader: React.FC<LoaderProps> = ({
  id,
  textColor,
}: LoaderProps) => (
  <label
    htmlFor={id}
    className="my-9 w-6 inset-x-0 mx-auto absolute animate-spin"
  >
    <LoadingIcon className={textColor} />
  </label>
);
