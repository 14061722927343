import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { handleErrorResponse } from '../../api/ApiErrorsHandler';
import * as LocalStorage from '../../common/LocalStorageService';
import { formLoaded } from '../form/formActions';
import { getHash } from '../form/formSelectors';
import { IAppState, LanguageLoadingStatus, ThunkMiddlware } from '../types';
import {
  loadSupportedLanguages,
  redirectToFinalScreenIfNeeded,
  setupAndGetCountry,
  loadCommonTranslations,
} from '../common/commonHelpers';

import { getCountry, getLanguage, getLanguages } from './localizationSelectors';
import {
  getMockCommonTranslations,
  getMockSupportedLanguagesResponse,
} from '../../api/Mocks';
import {
  setCommonTranslations,
  setCountry,
  setLanguage,
  setLanguageLoadingStatus,
  setLanguages,
} from './localizationActions';
import { getLanguageFromUrl } from '../../common/helpers';
import { DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from '../../const/defaults';

const pickInitialLanguage = (getState: () => IAppState): string => {
  const queryLanguage = getLanguageFromUrl();
  const storedLanguage = LocalStorage.getItem(LocalStorage.LanguageKey);
  const stateLanguage = getLanguage(getState());

  let finalLanguage =
    queryLanguage || storedLanguage || stateLanguage || DEFAULT_LANGUAGE;

  const languages = getLanguages(getState());
  if (languages.length > 0) {
    const existingLang = languages.find(
      (l) => l.key.toLowerCase() === finalLanguage.toLowerCase()
    );
    if (!existingLang) {
      finalLanguage = finalLanguage.slice(0, 2);
    }
  }

  return finalLanguage;
};

export const setInitialLanguage: ActionCreator<
  ThunkAction<
    Promise<void>,
    IAppState,
    ThunkMiddlware,
    Action<typeof setLanguage>
  >
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const language = pickInitialLanguage(getState);
  dispatch(setLanguage(language));
};

export const changeLanguage: ActionCreator<
  ThunkAction<
    Promise<void>,
    IAppState,
    ThunkMiddlware,
    Action<typeof formLoaded>
  >
> =
  (language: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    const state = getState();

    LocalStorage.setItem(LocalStorage.LanguageKey, language);
    dispatch(setLanguage(language));

    const country = getCountry(state);
    loadCommonTranslations(dispatch, country, language);

    const hash = getHash(state);
    if (hash) {
      try {
        const response = await registrationApi.getFields({ hash, language });

        if (response) {
          dispatch(formLoaded(response));
          await redirectToFinalScreenIfNeeded(dispatch, getState);
        }
      } catch (e) {
        handleErrorResponse(e, dispatch);
      }
    }
  };

export const initLanguages: ActionCreator<any> =
  () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
    try {
      const country = await setupAndGetCountry(dispatch, getState);

      await loadSupportedLanguages(dispatch, country);
      const language = pickInitialLanguage(getState);
      dispatch(setInitialLanguage(language));
      loadCommonTranslations(dispatch, country, language);
    } catch (err) {
      dispatch(setLanguages(getMockSupportedLanguagesResponse().languages));
      dispatch(setLanguageLoadingStatus(LanguageLoadingStatus.READY));
      dispatch(setCountry(DEFAULT_COUNTRY));
      dispatch(setLanguage(DEFAULT_LANGUAGE));
      dispatch(setCommonTranslations(getMockCommonTranslations()));
    }
  };
