export var HttpMethod;
(function (HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["POST"] = "POST";
})(HttpMethod || (HttpMethod = {}));
export var AuthStrategy;
(function (AuthStrategy) {
    AuthStrategy["ANY"] = "ANY";
    AuthStrategy["JWT"] = "JWT";
    AuthStrategy["BASIC"] = "BASIC";
    AuthStrategy["NONE"] = "NONE";
    AuthStrategy["MULTI"] = "MULTI";
    AuthStrategy["UNKNOWN"] = "UNKNOWN";
    AuthStrategy["CUSTOM"] = "CUSTOM";
})(AuthStrategy || (AuthStrategy = {}));
export var ShardingStrategy;
(function (ShardingStrategy) {
    ShardingStrategy["ANY"] = "ANY";
    ShardingStrategy["URL"] = "URL";
    ShardingStrategy["HEADER"] = "HEADER";
    ShardingStrategy["NONE"] = "NONE";
})(ShardingStrategy || (ShardingStrategy = {}));
