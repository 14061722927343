function hasClassNameAttached(value) {
    return typeof value === 'object' && typeof value.getClassName === 'function';
}
var HttpErrorUtils = /** @class */ (function () {
    function HttpErrorUtils() {
    }
    HttpErrorUtils.isInstanceOf = function (instance, targetClass) {
        if (!instance) {
            return false;
        }
        if (!hasClassNameAttached(targetClass.prototype)) {
            return false;
        }
        if (hasClassNameAttached(instance) &&
            instance.getClassName() === targetClass.prototype.getClassName()) {
            return true;
        }
        // Detect whether instance subtype is satisfying the targetClass
        if (hasClassNameAttached(Object.getPrototypeOf(instance))) {
            return HttpErrorUtils.isInstanceOf(Object.getPrototypeOf(instance), targetClass);
        }
        return false;
    };
    return HttpErrorUtils;
}());
export { HttpErrorUtils };
