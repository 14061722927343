import * as React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../redux/store';
import icon from '../assets/check-mark.svg';
import { getMixpanel } from '../redux/mixpanel/mixpanelSelectors';
import { MixpanelUiEvents } from '../common/events';
import {
  AppType,
  getAppTypeFromAppVersion,
  getAppVersion,
  getVersionNumberFromAppVersion,
} from '../common/helpers';
import { getHasPartnerAccount } from '../redux/auth/authSelectors';
import * as LocalStorage from '../common/LocalStorageService';
import {
  getCurrentStepName,
  getHash,
  getTranslations,
} from '../redux/form/formSelectors';

export const UpdateAppBanner: React.FC = () => {
  // This component is temporary and can be removed when most of the
  // Bolt Driver app installations already have versions
  // compatible with In-App signup (DA.46+ and DI.44+)

  const mixpanel = useSelector(getMixpanel);
  const hasPartnerAccount = useSelector(getHasPartnerAccount);
  const regHash = useSelector(getHash);
  const regHashId = (regHash || '').substring(0, 4) || 'hash';
  const formStep = useSelector(getCurrentStepName);
  const commonTranslations = useSelector(getTranslations);

  const shouldRender = React.useMemo(() => {
    // Check if driver app version supports in-app signup and login-via-phone
    let isOldDriverApp = false;
    const appVersion = getAppVersion();
    if (appVersion) {
      const appType = getAppTypeFromAppVersion(appVersion);
      const versionNum = getVersionNumberFromAppVersion(appVersion);
      if (appType === AppType.ANDROID && versionNum < 46) {
        isOldDriverApp = true;
      }
      if (appType === AppType.IOS && versionNum < 44) {
        isOldDriverApp = true;
      }
    }

    const isSecondStep =
      formStep ===
      DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep.STEP2;

    return isSecondStep && hasPartnerAccount && isOldDriverApp;
  }, [hasPartnerAccount, formStep]);

  React.useEffect(() => {
    if (mixpanel && shouldRender) {
      mixpanel.track(MixpanelUiEvents.IN_APP_UPDATE_APP_DISPLAYED);
      LocalStorage.setItem(LocalStorage.ContinueInAppDisplayed, regHashId);
    }
  }, [mixpanel, shouldRender, regHashId]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="flex flex-col content-center justify-center desktop:w-1/2 p-4 desktop:p-2 pt-0 pb-8 mx-auto">
      <div className="p-4 rounded-lg border border-gray-400">
        <div className="text-left rtl:text-right mx-auto">
          <div className="flex flex-row">
            <div className="shrink pr-2 pt-0.5">
              <img
                src={icon}
                alt="Info"
                width={24}
                height={24}
                color="#2F313F"
              />
            </div>
            <div className="w-full">
              <div className="text-md font-bold">
                {commonTranslations.app_promotion.driver_app_is_outdated}
              </div>
              <div className="text-gray-700 text-sm">
                <p className="mt-2">
                  {commonTranslations.app_promotion.update_driver_app}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
