import { AutosaveStatus, AutosaveState } from '../types';
import {
  ActionType,
  SetPendingStatus,
  SetSavingStatus,
  SetFailedStatus,
  SetAutosaveEnabled,
  SetSuccessfulStatus,
} from './autosaveActions';
import { createReducer } from '../helper';

const initialState: AutosaveState = {
  status: AutosaveStatus.SUCCESSFUL,
  timeout: null,
  fieldName: null,
  fieldValue: null,
  featureEnabled: false,
};

const setPendingStatus = (
  state: AutosaveState,
  action: SetPendingStatus
): AutosaveState => ({
  ...state,
  timeout: action.timeout,
  status: AutosaveStatus.PENDING,
  fieldName: action.fieldName,
  fieldValue: action.fieldValue,
});

const setSavingStatus = (
  state: AutosaveState,
  action: SetSavingStatus // eslint-disable-line @typescript-eslint/no-unused-vars
): AutosaveState => ({
  ...state,
  timeout: null,
  status: AutosaveStatus.SAVING,
});

const setSuccessfulStatus = (
  state: AutosaveState,
  action: SetSuccessfulStatus // eslint-disable-line @typescript-eslint/no-unused-vars
): AutosaveState => ({
  ...state,
  status: AutosaveStatus.SUCCESSFUL,
  fieldName: null,
  fieldValue: null,
});

const setFailedStatus = (
  state: AutosaveState,
  action: SetFailedStatus // eslint-disable-line @typescript-eslint/no-unused-vars
): AutosaveState => ({
  ...state,
  status: AutosaveStatus.FAILED,
  fieldName: null,
  fieldValue: null,
});

const setAutosaveEnabled = (
  state: AutosaveState,
  action: SetAutosaveEnabled
) => ({
  ...state,
  featureEnabled: action.featureEnabled,
});

const handlers = {
  [ActionType.SET_PENDING_STATUS]: setPendingStatus,
  [ActionType.SET_SAVING_STATUS]: setSavingStatus,
  [ActionType.SET_SUCCESSFUL_STATUS]: setSuccessfulStatus,
  [ActionType.SET_FAILED_STATUS]: setFailedStatus,
  [ActionType.SET_FEATURE_ENABLED]: setAutosaveEnabled,
};

export const autosaveReducer = createReducer<AutosaveState>(
  initialState,
  handlers
);
