import { NotificationsState } from '../types';
import { createReducer } from '../helper';
import {
  IFieldErrorsAdded,
  ICommonErrorAdded,
  ActionType,
  IClearFieldError,
  IVerificationErrorAdded,
} from './notificationActions';

const initialState: NotificationsState = {
  commonErrors: [],
  fieldErrors: [],
  verificationErrors: [],
};

const fieldErrorsAdded = (
  state: NotificationsState,
  action: IFieldErrorsAdded
) => ({
  ...state,
  fieldErrors: [...state.fieldErrors, ...action.errors],
});

const commonErrorAdded = (
  state: NotificationsState,
  action: ICommonErrorAdded
) => ({
  ...state,
  commonErrors: [...state.commonErrors, action.error],
});

const clearFieldError = (
  state: NotificationsState,
  action: IClearFieldError
) => ({
  ...state,
  fieldErrors: state.fieldErrors.filter((x) => x.property !== action.property),
});

const clearErrors = () => ({
  ...initialState,
});

const verificationErrorAdded = (
  state: NotificationsState,
  action: IVerificationErrorAdded
) => ({
  ...state,
  verificationErrors: [...state.verificationErrors, action.error],
});

const handlers = {
  [ActionType.FIELD_ERRORS_ADDED]: fieldErrorsAdded,
  [ActionType.COMMON_ERROR_ADDED]: commonErrorAdded,
  [ActionType.CLEAR_ERRORS]: clearErrors,
  [ActionType.CLEAR_FIELD_ERRORS]: clearFieldError,
  [ActionType.VERIFICATION_ERROR_ADDED]: verificationErrorAdded,
};

export const notificationsReducer = createReducer<NotificationsState>(
  initialState,
  handlers
);
