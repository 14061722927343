import React from 'react';

export const LoadingIcon: React.FC<{ className: string }> = (props) => {
  return (
    <svg
      className={`h-6 w-6 fill-current ${props.className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.230582 14.3411C-0.232441 12.0133 0.00519868 9.60051 0.91345 7.4078C1.8217 5.21509 3.35977 3.34094 5.33316 2.02237C7.30655 0.70379 9.62663 1.48656e-06 12 1.38281e-06C13.1046 1.33453e-06 14 0.895431 14 2C14 3.10457 13.1046 4 12 4C10.4178 4 8.87104 4.46919 7.55544 5.34825C6.23985 6.2273 5.21447 7.47673 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88744 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5823 20 12C20 10.8954 20.8954 10 22 10C23.1046 10 24 10.8954 24 12C24 14.3734 23.2962 16.6935 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.8071 0.693604 16.6689 0.230582 14.3411Z"
      />
    </svg>
  );
};
