var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { HttpInterceptorBasicAuth } from '@bolteu/http-interceptor-basic-auth-bolt-server';
import { HttpInterceptorHeaderSharding } from '@bolteu/http-interceptor-header-sharding-bolt-server';
import { HttpInterceptorJwtAuth } from '@bolteu/http-interceptor-jwt-auth-bolt-server';
import { HttpInterceptorUrlSharding } from '@bolteu/http-interceptor-url-sharding-bolt-server';
import { HttpInterceptorVirtualPaths } from '@bolteu/http-interceptor-virtual-paths-bolt-server';
import { HttpInterceptorStandardRequest, } from './HttpInterceptorStandardRequest';
var HttpClientExtensionBoltServer = /** @class */ (function () {
    function HttpClientExtensionBoltServer(config) {
        if (this.hasStandardRequestStrategyStrategy(config)) {
            this.standardRequestStrategy = config.standardRequestStrategy;
        }
        if (this.hasShardingStrategy(config)) {
            this.shardingStrategy = config.shardingStrategy;
        }
        if (this.hasAuthStrategy(config)) {
            this.authStrategy = config.authStrategy;
        }
    }
    HttpClientExtensionBoltServer.prototype.getInterceptors = function () {
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], this.getVirtualPathsInterceptors()), this.getStandardRequestInterceptors()), this.getAuthInterceptors()), this.getShardingInterceptors());
    };
    HttpClientExtensionBoltServer.prototype.getStandardRequestInterceptors = function () {
        if (this.standardRequestStrategy) {
            return [
                new HttpInterceptorStandardRequest(this.standardRequestStrategy),
            ];
        }
        return [];
    };
    HttpClientExtensionBoltServer.prototype.getVirtualPathsInterceptors = function () {
        return [new HttpInterceptorVirtualPaths()];
    };
    HttpClientExtensionBoltServer.prototype.getShardingInterceptors = function () {
        var _a, _b;
        var shardingInterceptors = [];
        if ((_a = this.shardingStrategy) === null || _a === void 0 ? void 0 : _a.url) {
            shardingInterceptors.push(new HttpInterceptorUrlSharding(this.shardingStrategy.url));
        }
        if ((_b = this.shardingStrategy) === null || _b === void 0 ? void 0 : _b.header) {
            shardingInterceptors.push(new HttpInterceptorHeaderSharding(this.shardingStrategy.header));
        }
        return shardingInterceptors;
    };
    HttpClientExtensionBoltServer.prototype.hasStandardRequestStrategyStrategy = function (config) {
        return 'standardRequestStrategy' in config;
    };
    HttpClientExtensionBoltServer.prototype.hasAuthStrategy = function (config) {
        return 'authStrategy' in config;
    };
    HttpClientExtensionBoltServer.prototype.hasShardingStrategy = function (config) {
        return 'shardingStrategy' in config;
    };
    HttpClientExtensionBoltServer.prototype.isJwtAuthStrategy = function (authStrategy) {
        return !!authStrategy && 'jwt' in authStrategy;
    };
    HttpClientExtensionBoltServer.prototype.isBasicAuthStrategy = function (authStrategy) {
        return !!authStrategy && 'basic' in authStrategy;
    };
    HttpClientExtensionBoltServer.prototype.getAuthInterceptors = function () {
        var authInterceptors = [];
        if (this.isJwtAuthStrategy(this.authStrategy)) {
            authInterceptors.push(new HttpInterceptorJwtAuth(this.authStrategy.jwt));
        }
        else if (this.isBasicAuthStrategy(this.authStrategy)) {
            authInterceptors.push(new HttpInterceptorBasicAuth(this.authStrategy.basic));
        }
        return authInterceptors;
    };
    return HttpClientExtensionBoltServer;
}());
export { HttpClientExtensionBoltServer };
