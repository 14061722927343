import React from 'react';

export default ({
  error,
}: {
  error: string | undefined;
}): React.ReactElement => {
  if (error) {
    return (
      <div className="text-red-700 text-left rtl:text-right text-sm mt-1 error-hint">
        {error}
      </div>
    );
  }
  return <></>;
};
