var HttpRequestUrl = /** @class */ (function () {
    function HttpRequestUrl() {
    }
    HttpRequestUrl.buildRequestUrl = function (baseUrl, path) {
        return new URL(this.getNormalizedBaseUrl(baseUrl) + "/" + this.getNormalizedPath(path));
    };
    HttpRequestUrl.getNormalizedBaseUrl = function (baseUrl) {
        return baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
    };
    HttpRequestUrl.getNormalizedPath = function (path) {
        return path.startsWith('/') ? path.substring(1) : path;
    };
    return HttpRequestUrl;
}());
export { HttpRequestUrl };
