import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';
import { GroupedOptions, SelectGrouped } from './SelectGrouped';
import { Choice } from './SelectCommon';

interface CascadeSelectProps {
  field: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
  blurHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
}

const colorBackgroundMap = {
  white: 'bg-[#F9FAFB]',
  pearlWhite: 'bg-[#F9FAFB]',
  pearl: 'bg-[#EAE0C8]',
  beige: 'bg-[#F5F5DC]',
  black: 'bg-[#0D120F]',
  gray: 'bg-[#D3D4DC]',
  darkGray: 'bg-[#A9ABB9]',
  silver: 'bg-[#D8E0E6]',
  blue: 'bg-[#147AF3]',
  navyBlue: 'bg-[#1446A0]',
  skyBlue: 'bg-[#51D6FF]',
  lightBlue: 'bg-[#A4CDF4]',
  red: 'bg-[#E32636]',
  maroon: 'bg-[#B7245C]',
  wine: 'bg-[#A5243D]',
  wineRed: 'bg-[#A5243D]',
  green: 'bg-[#32BB78]',
  purple: 'bg-[#8963BA]',
  brown: 'bg-[#A18276]',
  gold: 'bg-[#E8BC50]',
  bronze: 'bg-[#A87900]',
  pink: 'bg-[#FF7799]',
  orange: 'bg-[#E8BC50]',
  yellow: 'bg-[#FDD630]',
};

const baseStyleForColorSwatch =
  'rounded-full h-6 w-6 block border-[#061A37]/[0.08] border-2';

const colorStyleMap: { [key: string]: string } = {
  white: `${baseStyleForColorSwatch} ${colorBackgroundMap.white}`,
  pearlWhite: `${baseStyleForColorSwatch} ${colorBackgroundMap.pearlWhite}`,
  pearl: `${baseStyleForColorSwatch} ${colorBackgroundMap.pearl}`,
  beige: `${baseStyleForColorSwatch} ${colorBackgroundMap.beige}`,
  black: `${baseStyleForColorSwatch} ${colorBackgroundMap.black}`,
  gray: `${baseStyleForColorSwatch} ${colorBackgroundMap.gray}`,
  darkGrey: `${baseStyleForColorSwatch} ${colorBackgroundMap.darkGray}`,
  silver: `${baseStyleForColorSwatch} ${colorBackgroundMap.silver}`,
  blue: `${baseStyleForColorSwatch} ${colorBackgroundMap.blue}`,
  navyBlue: `${baseStyleForColorSwatch} ${colorBackgroundMap.navyBlue}`,
  skyBlue: `${baseStyleForColorSwatch} ${colorBackgroundMap.skyBlue}`,
  lightBlue: `${baseStyleForColorSwatch} ${colorBackgroundMap.lightBlue}`,
  red: `${baseStyleForColorSwatch} ${colorBackgroundMap.red}`,
  maroon: `${baseStyleForColorSwatch} ${colorBackgroundMap.maroon}`,
  wine: `${baseStyleForColorSwatch} ${colorBackgroundMap.wine}`,
  wineRed: `${baseStyleForColorSwatch} ${colorBackgroundMap.wineRed}`,
  green: `${baseStyleForColorSwatch} ${colorBackgroundMap.green}`,
  purple: `${baseStyleForColorSwatch} ${colorBackgroundMap.purple}`,
  brown: `${baseStyleForColorSwatch} ${colorBackgroundMap.brown}`,
  gold: `${baseStyleForColorSwatch} ${colorBackgroundMap.gold}`,
  bronze: `${baseStyleForColorSwatch} ${colorBackgroundMap.bronze}`,
  pink: `${baseStyleForColorSwatch} ${colorBackgroundMap.pink}`,
  orange: `${baseStyleForColorSwatch} ${colorBackgroundMap.orange}`,
  yellow: `${baseStyleForColorSwatch} ${colorBackgroundMap.yellow}`,
};

const formatOptionLabel = (data: Choice): ReactNode => {
  return (
    <div className="flex items-center">
      <div className={colorStyleMap[data.value as string]} />
      <div className="pl-4">{data.title}</div>
    </div>
  );
};

const formatGroupLabel = (data: GroupedOptions) => {
  return (
    <div>
      {data.label && <span>{data.label}</span>}
      {data.separator && <hr className="pt-1.5 pb-2" />}
    </div>
  );
};

const sortFunction = (
  a: DriverRegistrationPortalNoAuthService.SelectValueItem,
  b: DriverRegistrationPortalNoAuthService.SelectValueItem
) => {
  return a.title.localeCompare(b.title);
};

export function VehicleColor({
  field,
  updateHandler,
  blurHandler,
}: CascadeSelectProps): React.ReactElement {
  const fieldName = field.generic_field_key ?? field.name;
  const error = useSelector(getFieldError(fieldName));

  const [choices, setChoices] = useState<GroupedOptions[]>([]);

  useEffect(() => {
    const white = field.select_values?.find((v) => v.value === 'white');
    const black = field.select_values?.find((v) => v.value === 'black');
    const sortedRemainder = field.select_values
      ?.sort((a, b) => sortFunction(a, b))
      .filter((v) => v.value !== 'white' && v.value !== 'black');
    const newChoices = [
      {
        options: [white as any, black as any],
      },
      {
        separator: true,
        options: sortedRemainder || [],
      },
    ];
    setChoices(newChoices);
  }, [field.translations, field.select_values]);

  return (
    <SelectGrouped
      title={field.translations.label}
      description={field.translations.description}
      placeholder={field.translations.placeholder}
      choices={choices}
      value={field.current_value}
      error={error}
      updateHandler={updateHandler}
      blurHandler={blurHandler}
      isSearchable={field.is_searchable}
      isRequired={field.is_required}
      formatOptionLabel={formatOptionLabel}
      formatGroupLabel={formatGroupLabel}
    />
  );
}
