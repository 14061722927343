import { getQueryParam } from './helpers';
import { deleteCookie, getCookie, setCookie } from './cookies';

export const SUPPORT_CASE_ID_COOKIE_MAX_AGE_IN_SECONDS = 10 * 60;
export const SUPPORT_CASE_ID_COOKIE_KEY = 'support_case_id';
export const SUPPORT_CASE_ID_QUERY_PARAM = 'support_case_id';

export const readSupportCaseIdFromQueryParamAndSetCookie = (): void => {
  const supportCaseId = getQueryParam(SUPPORT_CASE_ID_QUERY_PARAM);
  if (supportCaseId) {
    setCookie(SUPPORT_CASE_ID_COOKIE_KEY, supportCaseId, {
      'max-age': SUPPORT_CASE_ID_COOKIE_MAX_AGE_IN_SECONDS,
    });
  }
};

export const getSupportCaseIdFromCookie = (): number | undefined => {
  const supportCaseId = getCookie(SUPPORT_CASE_ID_COOKIE_KEY);
  if (supportCaseId) {
    return Number(supportCaseId);
  }
  return undefined;
};

export const clearSupportCaseIdCookie = (): void => {
  deleteCookie(SUPPORT_CASE_ID_COOKIE_KEY);
};
