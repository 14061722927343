import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { InfoCheckpoint } from './InfoCheckpoint';
import { DocumentModal } from './DocumentModal';
import { PicturePreview } from './PicturePreview';
import closeImage from '../../../assets/close.svg';
import backImage from '../../../assets/back.svg';
import { Button } from '../../Button';
import { NotificationsArea } from '../../NotificationsArea';

interface DocumentUploadPreviewProps {
  isOpen: boolean;
  submitting: boolean;
  fileUrl: string;
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  nextHandler: () => void;
  changePictureHandler: () => void;
  closeHandler: () => void;
}

export const DocumentUploadPreview: React.FC<DocumentUploadPreviewProps> = ({
  isOpen,
  submitting,
  fileUrl,
  commonTranslations,
  nextHandler,
  changePictureHandler,
  closeHandler,
}: DocumentUploadPreviewProps) => {
  const commonPreviewTranslations = commonTranslations.document_upload.preview;
  return (
    <DocumentModal isOpen={isOpen} onClose={closeHandler}>
      <div className="flex flex-col tablet:p-6" data-test="upload_preview">
        <button
          className="hidden tablet:inline tablet:self-end"
          type="button"
          onClick={closeHandler}
        >
          <img
            src={closeImage}
            data-test="close_button_image"
            alt="close dialog"
            className="object-contain"
          />
        </button>
        <div className="bg-black tablet:hidden">
          <button onClick={closeHandler} type="button">
            <img
              src={backImage}
              data-test="back_button_image"
              alt="close dialog"
              className="object-contain p-3"
            />
          </button>
        </div>
        <div className="block tablet:flex tablet:flex-row tablet:justify-between">
          <div className="h-[425px] w-full bg-gray-900 tablet:bg-white tablet:w-[312px] mr-3 rtl:ml-3">
            <PicturePreview fileUrl={fileUrl} fullHeight />
          </div>
          <div className="flex flex-col rounded-t-lg relative bg-white tablet:bg-transparent tablet:rounded-none tablet:static tablet:w-[312px]">
            <h3
              data-test="title"
              className="mt-3 tablet:mt-0 text-center text-xl font-bold"
            >
              {commonPreviewTranslations.readability_title}
            </h3>
            <div
              data-test="description"
              className="text-center text-gray-700 mb-2"
            >
              {commonPreviewTranslations.readability_description}
            </div>
            <div className="hidden tablet:block">
              <InfoCheckpoint
                title={commonPreviewTranslations.point_1.title}
                text={commonPreviewTranslations.point_1.description}
                textColor="text-gray-800"
                version="green"
              />
              <InfoCheckpoint
                title={commonPreviewTranslations.point_2.title}
                text={commonPreviewTranslations.point_2.description}
                textColor="text-gray-800"
                version="green"
              />
              <InfoCheckpoint
                title={commonPreviewTranslations.point_3.title}
                text={commonPreviewTranslations.point_3.description}
                textColor="text-gray-800"
                version="green"
              />
              <InfoCheckpoint
                title={commonPreviewTranslations.point_4.title}
                text={commonPreviewTranslations.point_4.description}
                textColor="text-gray-800"
                version="green"
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <NotificationsArea />
        </div>
        <div className="block px-6 pb-6 tablet:flex tablet:flex-row tablet:justify-around tablet:flex-row-reverse">
          <Button
            id="confirm_photo"
            onClick={nextHandler}
            color={submitting ? 'bg-purple-200' : 'bg-purple-500'}
            loadingTextColor="text-purple-500"
            label={
              submitting ? '' : commonPreviewTranslations.next_button_label
            }
            disabled={submitting}
            loading={submitting}
          />
          <Button
            id="choose_photo"
            onClick={changePictureHandler}
            color="bg-gray-200"
            loadingTextColor="text-gray-500"
            textColor={submitting ? 'text-gray-500' : 'text-gray-900'}
            label={commonPreviewTranslations.choose_photo_button_label}
            disabled={submitting}
            loading={false}
          />
        </div>
      </div>
    </DocumentModal>
  );
};
