import React, { ReactElement } from 'react';
import { getSignupLandingLink, getDriverLoginLink } from '../../common/helpers';
import { useSelector } from '../../redux/store';
import { getTranslations } from '../../redux/form/formSelectors';

interface LoginAndNewSignupLinksProperties {
  showLogin?: boolean;
  boldFont?: boolean;
}

export const LoginAndNewSignupLinks = ({
  showLogin,
  boldFont,
}: LoginAndNewSignupLinksProperties): ReactElement => {
  const loginLink = getDriverLoginLink();
  const signupLink = getSignupLandingLink();
  const t = useSelector(getTranslations);

  const handleOnClick = React.useCallback((url: string) => {
    window.location.replace(url);
  }, []);

  const textClassName = boldFont
    ? 'text-purple-500 font-bold'
    : 'text-purple-500';

  return (
    <div className="flex flex-col p-4 text-center mt-8 gap-4">
      {showLogin && (
        <div>
          <button
            type="button"
            onClick={() => handleOnClick(loginLink)}
            className={textClassName}
          >
            {t.common_labels.login}
          </button>
        </div>
      )}
      <div>
        <button
          type="button"
          onClick={() => handleOnClick(signupLink)}
          className={textClassName}
        >
          {t.common_labels.start_new_registration}
        </button>
      </div>
    </div>
  );
};

LoginAndNewSignupLinks.defaultProps = {
  showLogin: false,
  boldFont: true,
};
