import {
  HttpClientLibrary,
  FleetRegistrationNoAuthService,
} from '@bolteu/bolt-server-api-driver-portal';
import { BrandEnum } from '@bolteu/bolt-server-api-driver-portal/types/latest/src/StandardRequest';
import { getAppBrand, getServerHost } from '../common/environment';
import { PORTAL_VERSION } from '../env';

const documentsHttpClient =
  new HttpClientLibrary.HttpClientBoltServer<FleetRegistrationNoAuthService.HttpClientConstraints>(
    {
      baseUrl: getServerHost(),
    }
  );

documentsHttpClient.init({
  standardRequestStrategy: {
    getStandardRequest: () => {
      return {
        version: PORTAL_VERSION,
        brand: getAppBrand() as unknown as BrandEnum,
      };
    },
  },
});

const documentsApiClient = new FleetRegistrationNoAuthService.ApiClient({
  httpClient: documentsHttpClient,
});

export default documentsApiClient;
