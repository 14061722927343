import {
  ActionType,
  SetRiderUserSignupData,
  SetCityData,
} from './riderUserSignupActions';
import { createReducer } from '../helper';
import { RiderUserSignupState } from '../types';

const initialState: RiderUserSignupState = {
  formData: {} as any,
  loading: true,
  uploading: false,
  city: undefined,
};

const setFormLoading = (state: RiderUserSignupState): RiderUserSignupState => ({
  ...state,
  loading: true,
});

const setFormUploading = (
  state: RiderUserSignupState
): RiderUserSignupState => ({
  ...state,
  uploading: true,
});

const setFormUploaded = (
  state: RiderUserSignupState
): RiderUserSignupState => ({
  ...state,
  uploading: false,
});

const setFormLoaded = (
  state: RiderUserSignupState,
  action: SetRiderUserSignupData
): RiderUserSignupState => {
  return {
    ...state,
    formData: action.value,
    loading: false,
  };
};

const setCity = (
  state: RiderUserSignupState,
  action: SetCityData
): RiderUserSignupState => {
  return {
    ...state,
    city: action.city,
  };
};

const handlers = {
  [ActionType.DATA_LOADING]: setFormLoading,
  [ActionType.DATA_LOADED]: setFormLoaded,
  [ActionType.SET_CITY]: setCity,
  [ActionType.SET_FORM_UPLOADING]: setFormUploading,
  [ActionType.SET_FORM_UPLOADED]: setFormUploaded,
};

export const riderUserSignupReducer = createReducer<RiderUserSignupState>(
  initialState,
  handlers
);
