import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { ColoredIconBanner } from './ColoredIconBanner';

interface BannerProps {
  bannerData: DriverRegistrationPortalNoAuthService.Banner;
}

export const Banner: React.FC<BannerProps> = ({ bannerData }: BannerProps) => {
  switch (bannerData.type) {
    case DriverRegistrationPortalNoAuthService.BannerType.COLORED_ICON_BANNER:
      return <ColoredIconBanner data={bannerData} />;
    default:
      return null;
  }
};
