//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
import { GeneratedHttpClient, AuthStrategy, ShardingStrategy, GeneratedHttpClientConstraints, RequestOptionsConstraint } from "./HttpClient";
import { StandardRequest } from "./StandardRequest";
export interface GeneratedApiClientInstanceOptions {
    shardingStrategy?: ShardingStrategy;
    virtualPathsEnabled?: boolean;
}
export interface GeneratedApiClientConfig<THttpClientConstraints extends GeneratedHttpClientConstraints, TRequestOptions extends RequestOptionsConstraint> {
    httpClient: GeneratedHttpClient<THttpClientConstraints, TRequestOptions>;
    apiClientOptions?: GeneratedApiClientInstanceOptions;
}
export interface GeneratedApiClientDefaultConstraints extends GeneratedHttpClientConstraints {
    Auth: AuthStrategy.NONE;
    Sharding: ShardingStrategy.NONE;
    StandardRequest: StandardRequest;
}
export class GeneratedApiClient<THttpClientConstraints extends GeneratedHttpClientConstraints = GeneratedApiClientDefaultConstraints, TRequestOptions extends RequestOptionsConstraint = never> {
    protected readonly apiClientOptions?: GeneratedApiClientInstanceOptions;
    public readonly httpClient: GeneratedHttpClient<THttpClientConstraints, TRequestOptions>;
    constructor(config: GeneratedApiClientConfig<THttpClientConstraints, TRequestOptions>) {
        this.httpClient = config.httpClient;
        this.apiClientOptions = config.apiClientOptions;
    }
}
